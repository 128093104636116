import { t } from 'i18next'
import { Theme } from '../../Theme'
import Card from '../../components/common/Card'
import { Row, Col } from 'antd'
import { useEffect, useState } from 'react'
import { post } from '../../services/api/apiService'
import CustomAlert from '../../components/common/CAlert'
import DashboardChart from './Chart'

const cardConfig = [
  {
    icon: Theme.icons.icn_total_revenue,
    label: 'Total Revenue',
    count: 0,
    type: 'Total Revenue',
    class: '',
    backgroundColor: '',
    currency: 'SAR'
  },
  {
    icon: Theme.icons.icn_active_booking,
    label: 'Complete Bookings',
    count: 0,
    type: 'Complete Bookings',
    class: '',
    backgroundColor: '',
    currency: ''
  },
  {
    icon: Theme.icons.icn_total_booking,
    label: 'Total Bookings',
    count: 0,
    type: 'Total Bookings',
    class: '',
    backgroundColor: '',
    currency: 'Bookings'
  },
  {
    icon: Theme.icons.icn_cancelled_booking,
    label: 'Cancelled Bookings',
    count: 0,
    type: 'Cancelled Bookings',
    class: '',
    backgroundColor: '',
    currency: ''
  },

  {
    icon: Theme.icons.icn_jive_booking,
    label: 'Active Bookings',
    count: 0,
    type: 'Active Bookings',
    class: '',
    backgroundColor: '#C4ABDC',
    currency: ''
  },
  {
    icon: Theme.icons.icn_pending_booking,
    label: 'Pending Bookings',
    count: 0,
    type: 'Pending Bookings',
    class: '',
    backgroundColor: '#EBE7A6',
    currency: ''
  },
  {
    icon: Theme.icons.icn_confirmed_booking,
    label: 'Confirmed Bookings',
    count: 0,
    type: 'Confirmed Bookings',
    class: '',
    backgroundColor: '#FAD9A1',
    currency: ''
  },

  {
    icon: Theme.icons.icn_registered_vendor,
    label: 'Registered Vendor',
    count: 0,
    type: 'Registered Vendor',
    class: '',
    backgroundColor: '',
    currency: ''
  },
  {
    icon: Theme.icons.icn_registered_freelancer,
    label: 'Registered Freelancer',
    count: 0,
    type: 'Registered Freelancer',
    class: '',
    backgroundColor: '',
    currency: ''
  },
  {
    icon: Theme.icons.icn_registered_customer,
    label: 'Registered Customer',
    count: 0,
    type: 'Registered Customer',
    class: '',
    backgroundColor: '',
    currency: ''
  }
]

const Dashboard: React.FC = () => {
  const [cardData, setCardData] = useState(cardConfig)
  const [chartData, setChartData] = useState<{ data: any[] }>({
    data: []
  })
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  )
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const getDashboardData = () => {
    post('DashBoard/GetDashboardCard', {}).then(response => {
      if (response.succeeded) {
        const updatedCards = cardData.map(card => ({
          ...card,
          count:
            response.data.find((item: any) => item.name === card.type)?.count ??
            0
        }))
        setCardData(updatedCards)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    })
  }

  const getDashboardChart = async (year: number) => {
    post(`DashBoard/GetDashboardGraph?YearId=${year}`, {}).then(response => {
      if (response.succeeded) {
        setChartData(response)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    })
  }

  useEffect(() => {
    getDashboardData()
    getDashboardChart(selectedYear)
  }, [selectedYear])

  return (
    <>
      <div>
        <p className='page-heading'>{t('Dashboard')}</p>
        {cardData.length > 0 && (
          <>
            {/* Cards 1 to 4 */}
            <Row gutter={16}>
              {cardData.slice(0, 4).map((data, index) => (
                <Col span={6} key={index}>
                  <Card
                    count={data.count}
                    currency={data.currency}
                    icon={data.icon}
                    name={data.label}
                    backgroundColor={data.backgroundColor}
                  />
                </Col>
              ))}
            </Row>

            {/* Cards 5 to 7 with white background */}
            {cardData.length > 4 && (
              <div className='d-flex' style={{ display: 'flex' }}>
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '16px',
                    width: '75%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    maxHeight: 'max-content',
                    borderRadius: '20px'
                  }}
                >
                  <Row gutter={16}>
                    {cardData.slice(4, 7).map((data, index) => (
                      <Col span={8} key={index + 4}>
                        <Card
                          count={data.count}
                          currency={data.currency}
                          icon={data.icon}
                          name={data.label}
                          backgroundColor={data.backgroundColor}
                        />
                      </Col>
                    ))}
                  </Row>
                  <DashboardChart
                    data={chartData}
                    selectedYear={selectedYear}
                    onYearChange={setSelectedYear}
                  />
                </div>

                <div
                  style={{
                    padding: '16px',
                    width: '25%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: 'max-content'
                  }}
                >
                  {cardData.length > 7 && (
                    <div style={{ marginTop: '10px' }}>
                      <Row gutter={16} style={{ flexDirection: 'column' }}>
                        {cardData.slice(7, 10).map((data, index) => (
                          <Col
                            span={36}
                            key={index + 7}
                            style={{
                              marginBottom: '16px',
                              maxHeight: '300px'
                            }}
                          >
                            <Card
                              count={data.count}
                              currency={data.currency}
                              icon={data.icon}
                              name={data.label}
                              backgroundColor={data.backgroundColor}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default Dashboard

import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Theme } from '../../Theme'
import dayjs from 'dayjs'
import moment from 'moment'

const ViewNotification: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { type, id } = useParams()
  const { record } = location.state
  const getCurrentLocalTime = (time: string) => {
    const localTime = moment.utc(time).local().format('DD.MM.YYYY,hh:mm A')
    return localTime
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        {type == 'view' && id != 'add' && (
          <div
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name'>{t('Notification Details')}</p>
            <div className='view-container'>
              <div className='view-container-content'>
                <div>
                  <p className='label m-0'>{t('Title')}</p>
                  <p className='detail m-0'>{record.enTitle}</p>
                </div>
                <div>
                  <p className='label m-0'> {t('Date')} </p>
                  <p className='detail m-0'>
                    {getCurrentLocalTime(record.notificationDate)
                    // dayjs(record.notificationDate)?.format(
                    //   'DD.MM.YYYY, HH:mm'
                    // )
                    }
                  </p>
                </div>
              </div>

              <div>
                <div className='mt-3'>
                  <p className='label m-0'>{t('Description')}</p>
                  <p className='detail m-0'>{record.enDescription}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ViewNotification

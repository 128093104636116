import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Theme } from '../../Theme'
import Status from '../../components/common/Status'
import SuccessModal from '../../components/common/SuccessModal'
import { getAdminServiceOrderReviewById, post } from '../../services/booking'
import CustomAlert from '../../components/common/CAlert'
import { getTheFirstTwoLetterOfString } from '../../utils/functions'
import Rating from '../../components/common/Rating'
import CButton from '../../components/common/CButton'
import AlertModal from '../../components/common/AlertModal'
import permissionService from '../../services/permissions'
const ViewFeedback: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [record, setRecord] = useState<{ [key: string]: any }>({})
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [switchChecked, setSwitchChecked] = useState('')

  const { data, isLoading, error } = useQuery(['serviceById', params?.id], () =>
    getAdminServiceOrderReviewById({ Id: params?.id })
  )

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusError,
    refetch
  } = useQuery(
    ['statusServiceOrder', record?.id],
    () =>
      switchChecked &&
      post(`AdminServiceOrderReview/${switchChecked}`, { id: record?.id }),
    {
      enabled: false,
      onSuccess: data => {
        setIsSuccess(true)
        setAlert({ message: data.message, type: 'success', visible: true })
      }
    }
  )

  useEffect(() => {
    if (data?.succeeded) {
      setRecord(data?.data)
    }
  }, [data])

  const handleRatingChange = (updatedRating: any, type: any) => {
    console.log('new Ratng', updatedRating, +' Type', type)
  }

  const callRequest = (val: string) => {
    setSwitchChecked(val)
    setIsModalVisible(true)
    console.log('Value', val)
  }

  const handleModalOk = async () => {
    await refetch()
    // await getAdminServiceOrderReviewById()
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <img
              src={Theme.icons.icn_arrow_back}
              alt='icn_user_add'
              className='cursor-pointer'
              style={{ height: '20px' }}
              onClick={() => navigate(`/jive/feedback`)}
            />
            <p className='list-heading m-0 mx-2'>{'Feedback Requests'}</p>
          </div>

          {record?.status == 'Pending' && permissionService.getRoleMenuPermissions('User Feedback')?.acceptReject && (
            <div className='d-flex align-items-center w-25'>
              <CButton
                style={{
                  marginRight: '12px',
                  backgroundColor: Theme.colors.success,
                  borderColor: Theme.colors.success,
                  width: '100%'
                }}
                onClick={() => callRequest('AcceptReview')}
              >
                Accept
              </CButton>
              <CButton
                className='rejectBtn'
                style={{ colo: 'red' }}
                onClick={() => callRequest('RejectReview')}
              >
                Reject
              </CButton>
            </div>
          )}
        </div>
      </div>
      <div
        className='mt-3'
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <div className='d-flex align-items-center justify-content-between'>
          <p className='list-heading m-0 mx-2'>{'Order & Feedback Details'}</p>
        </div>

        <div
          style={{
            backgroundColor: '#F3F3F3',
            padding: '24px',
            borderRadius: '16px',
            marginTop: '20px'
          }}
        >
          <div className='d-flex align-items-center'>
            <div className='bold-name-circle'>
              <p className='m-0'>
                {getTheFirstTwoLetterOfString(record?.customerName)}
              </p>
            </div>
            <p className='user-name m-0 mx-2'>{record?.customerName}</p>
          </div>
          <div className='row mt-4'>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Vendor/Freelancer Name')}</p>
              <p className='detail-value mt-1'>{record?.vendorName}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Service Name')}</p>
              <p className='detail-value mt-1'>{record?.serviceName}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Service Category')}</p>
              <p className='detail-value mt-1'>{record?.categoryName}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Booking Date')}</p>
              <p className='detail-value mt-1'>
                {dayjs(record?.dateOfBooking)?.format('DD.MM.YYYY')}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Feedback Status')}</p>
              <p className='detail-value mt-1' style={{ maxWidth: '150px' }}>
                <Status status={record?.status} />{' '}
              </p>
            </div>
            <div className='col-lg-9'>
              <p className='detail-label m-0'>{t('Service Location')}</p>
              <p className='detail-value mt-1'>{record?.serviceLocation}</p>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: '#F3F3F3',
            padding: '24px',
            borderRadius: '16px',
            marginTop: '20px'
          }}
        >
          <p className='list-heading m-0'>{'Service Feedback'}</p>

          <div className='row mt-4'>
            <div className='col-lg-9'>
              <p className='detail-label m-0'>{t('Comment')}</p>
              <p className='detail-value mt-1  m-0'>{record?.reviewComment}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>
                <strong>Rating : {record?.star}</strong> 
              </p>
              <div className='rating-background mt-1'>
                <Rating
                  rating={record?.star}
                  onRatingChange={newRating =>
                    handleRatingChange(newRating, 'star')
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        visible={isSuccess}
        message={`You have successfully ${
          switchChecked == 'AcceptReview' ? 'Approved' : 'Rejected'
        } user feedback.`}
        onOk={() => navigate(`/jive/feedback`)}
      />

      <AlertModal
        visible={isModalVisible}
        message={`Are you sure you want to ${
          switchChecked == 'AcceptReview' ? 'Approve' : 'Reject'
        } the user feedback?`}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />

      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default ViewFeedback

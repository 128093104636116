import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
interface MenuWrapperProps {
  items: MenuProps['items'];
  selectedKey: string;
  highlightStyle: { top: number; height: number };
  onClick: (e: any) => void;
  openKeys: string[]; // Pass open keys
  setOpenKeys: (keys: string[]) => void; // Pass setOpenKeys
}

const MenuWrapper: React.FC<MenuWrapperProps> = ({
  items,
  selectedKey,
  highlightStyle,
  onClick,
  openKeys,
  setOpenKeys, // Accept setOpenKeys
}) => {
  const [highlightPosition, setHighlightPosition] = useState<{ top: number; height: number }>({
    top: 0,
    height: 0,
  });
  const menuRef = useRef<HTMLDivElement>(null);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    // If there is no new submenu opened (latestOpenKey is undefined), close all
    if (!latestOpenKey) {
      setOpenKeys([]);
    } else {
      // If the new submenu is opened, close the previous one
      setHighlightPosition({ top:0, height:0 });
      setOpenKeys([latestOpenKey]);
    }
  };

  useEffect(() => {
    if (menuRef.current) {
      const selectedItem = menuRef.current.querySelector(`.ant-menu-item-selected`);
      if (selectedItem) {
        const itemRect = selectedItem.getBoundingClientRect();
        const menuRect = menuRef.current.getBoundingClientRect();
        const top = itemRect.top - menuRect.top - 50;
        const height = itemRect.height;
        setHighlightPosition({ top, height });
      }
    }
  }, [selectedKey]);

  return (
    <div style={{ position: 'relative' }} ref={menuRef}>
      {items?.map((item: any) => {
        if (item.children) {
          const isOpen = openKeys.includes(item.key as string);
          return (
            <>
              {isOpen && (
                <div
                  key={item.key}
                  className="highlighted-bar"
                  style={{
                    top: highlightPosition.top,
                    height: highlightPosition.height,
                    display: highlightPosition.height > 0 ? 'block' : 'none',
                  }}
                />
              )}
            </>
          );
        }
      })}
      <Menu
        mode="inline"
        className='mt-4'
        selectedKeys={[selectedKey]}
        onClick={onClick}
        openKeys={openKeys} // Control openKeys here
        onOpenChange={onOpenChange}
        items={items}
      />
    </div>
  );
};

export default MenuWrapper;

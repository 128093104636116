import { t } from 'i18next'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomAlert from '../../components/common/CAlert'
import CButton from '../../components/common/CButton'
import { Theme } from '../../Theme'
import { get, post } from '../../services/api/apiService'
import { Col, Form, Row, Typography, Card } from 'antd'
import Rating from '../../components/common/Rating'
import TextArea from 'antd/es/input/TextArea'
import SuccessModal from '../../components/common/SuccessModal'

const { Title, Text } = Typography

const UserFeedbackForm: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const refParam = queryParams.get('Ref')
  const [form] = Form.useForm()
  const [star, setStar] = useState(0)
  const [isSuccess, setIsSuccess] = useState(false)

  const [alert, setAlert] = useState({
    message: '',
    type: 'success',
    visible: false
  })

  const { mutate: sendFeedback, isLoading: isFeedbackSent } = useMutation(
    (formdata: any) =>
      post('AdminServiceOrderReview/AddOrderReview', formdata),
    {
      onSuccess: response => {
        if (response.succeeded) {
          setIsSuccess(true)
        } else {
          setIsSuccess(true)
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )

  const handleRatingChange = (updatedRating: any) => {
    setStar(updatedRating)
    form.setFieldsValue({ star: updatedRating })
  }

  const handleSave = (values: any) => {
    const obj = {
      ...values,
      OrderReference: refParam
    }
    //   console.log('values', obj)
    sendFeedback(obj)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh'
      }}
    >
      <Card
        style={{
          width: '70%',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '24px'
        }}
      >
        <Title
          level={3}
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            color: Theme.colors.primary
          }}
        >
          {t('Feedback')}
        </Title>

        <Form form={form} layout='vertical' onFinish={handleSave}>
          <Row gutter={16} justify='center'>
            <Col span={24}>
              <Form.Item
                label={<Text strong>Feedback Comment</Text>}
                name='reviewComment'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your feedback'
                  }
                ]}
              >
                <TextArea
                  showCount
                  autoSize={{ minRows: 4, maxRows: 8 }}
                  maxLength={600}
                  placeholder='Enter your feedback here...'
                  style={{ height: 150, borderRadius: '8px' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify='center'>
            <Form.Item
              name='star'
              rules={[
                {
                  required: true,
                  message: 'Please select rating'
                }
              ]}
            >
              <Rating
                rating={star}
                onRatingChange={newRating => handleRatingChange(newRating)}
              />
            </Form.Item>
          </Row>

          <Row justify='center' style={{ marginTop: '20px' }}>
            <Col span={6}>
              <CButton
                loading={isFeedbackSent}
                style={{
                  width: '100%',
                  backgroundColor: Theme.colors.primary,
                  color: Theme.colors.white
                }}
                htmlType='submit'
              >
                Submit
              </CButton>
            </Col>
          </Row>
        </Form>

        <SuccessModal
          visible={isSuccess}
          message={`You have submitted feedback successfully.`}
          onOk={() => navigate(`/`)}
          cancelButton = {false}
        />

        <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
      </Card>
    </div>
  )
}

export default UserFeedbackForm

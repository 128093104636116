import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Col, Form, Row, Typography, Divider, Spin } from 'antd';
import { getAdminUserRole } from '../../services/manageUser';
import { get, post } from '../../services/api/apiService';
import { AlertState, MenuItem, Permission, createPermissionObject, hasActivePermissions } from '../../utils/permission';
import permissionService from '../../services/permissions';
import CustomAlert from '../../components/common/CAlert';
import CButton from '../../components/common/CButton';
import CustomSelect from '../../components/common/CSelect';
import PermissionsTable from './PermissionTable';

const { Title } = Typography;

const PermissionsManagement: React.FC = () => {
  const [selectedRole, setSelectedRole] = useState<string | undefined>(undefined);
  const [permissionsData, setPermissionsData] = useState<MenuItem[]>([]);
  const [alert, setAlert] = useState<AlertState>({
    message: '',
    type: 'success',
    visible: false
  });

  const { data, isLoading } = useQuery('adminUserRoles', getAdminUserRole);

  const { mutate: getAllRolePermissions, isLoading: isFetchingPermissions } = useMutation(
    (roleId: string) => get(`Permissions/GetAllRolePermissions?roleId=${roleId}`,{}),
    {
      onSuccess: (response) => {
        if (response.succeeded) {
          setPermissionsData(response.data);
        }
      },
      onError: (error) => {
        console.error('Error fetching permissions:', error);
        setAlert({
          message: 'Error fetching permissions',
          type: 'error',
          visible: true
        });
      },
    }
  );

  const handleRoleChanged = (value: string) => {
    setSelectedRole(value);
    getAllRolePermissions(value);
  };

  const onPermissionsSave = async () => {
    if (!selectedRole) return;

    const url = 'Permissions/AddUpdateRoleWisePermission';
    const updatedPermissions: Permission[] = [];

    permissionsData.forEach((menuItem) => {
      // Process parent menu
      const parentPermission = createPermissionObject(menuItem, selectedRole);
      
      // Process submenus
      const submenuPermissions = menuItem.subMenuList
        .map(subMenu => createPermissionObject(subMenu, selectedRole))
        .filter(hasActivePermissions);

      // Add parent if it has active permissions or active submenus
      if (hasActivePermissions(parentPermission) || submenuPermissions.length > 0) {
        updatedPermissions.push(parentPermission);
        updatedPermissions.push(...submenuPermissions);
      }
    });

    if (updatedPermissions.length === 0) {
      setAlert({
        message: "Please select at least one permission",
        type: 'error',
        visible: true
      });
      return;
    }

    try {
      const response = await post(url, updatedPermissions);
      if (response.succeeded) {
        setAlert({
          message: "Permissions updated successfully!",
          type: 'success',
          visible: true
        });
        getAllRolePermissions(selectedRole);
      } else {
        setAlert({
          message: response?.message || 'Error updating permissions',
          type: 'error',
          visible: true
        });
      }
    } catch (error) {
      setAlert({
        message: 'Error updating permissions',
        type: 'error',
        visible: true
      });
    }
  };

  const getRoleOptions = () => {
    if (!data) return [];
    return data.data.map((x: any) => ({
      value: x.id,
      label: x.roleName,
    }));
  };

  return (
    <div className="permissions-container" style={{ padding: '20px', backgroundColor: '#fff',borderRadius:'20px' }}>
      <p style={{ marginBottom: '10px' }} className='page-heading'>
        Permission & Management
      </p>
      <Divider />
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="RoleId" rules={[{ required: true, message: 'Please select a role' }]}>
            <CustomSelect
              label="User Role"
              options={getRoleOptions()}
              value={selectedRole}
              onChange={handleRoleChanged}
              placeholder="Choose Role"
            />
          </Form.Item>
        </Col>
      </Row>

      {isFetchingPermissions ? (
        <Spin size="large" style={{ display: 'block', textAlign: 'center', marginTop: '50px' }} />
      ) : (
        permissionsData.length > 0 && (
          <PermissionsTable
            permissionsData={permissionsData}
            setPermissionsData={setPermissionsData}
          />
        )
      )}

      {selectedRole && (
        <Row justify="end" style={{ marginTop: '20px' }}>
          <Col span={4}>
            {permissionService.getRoleMenuPermissions('Roles & Privileges')?.add && (
              <CButton 
                loading={isFetchingPermissions} 
                style={{ width: '100%' }} 
                onClick={onPermissionsSave}
              >
                Save
              </CButton>
            )}
          </Col>
        </Row>
      )}

      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  );
};

export default PermissionsManagement;

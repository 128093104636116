import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'antd'
import CButton from '../../../../components/common/CButton'
import { Theme } from '../../../../Theme'
import { useMutation } from 'react-query'
import CustomAlert from '../../../../components/common/CAlert'
import SuccessModal from '../../../../components/common/SuccessModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addTermAndCondition } from '../../../../services/lov'
import ReactQuill from 'react-quill' // Import ReactQuill
import 'react-quill/dist/quill.snow.css' // Import Quill styles

const AddTermAndCondition: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { type, id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const [description, setDescription] = useState<string>('') // State for the rich text editor
  const [descriptionAr, setDescriptionAr] = useState<string>('') // State for the rich text editor

  const record = location.state

  const { mutate, isLoading } = useMutation(addTermAndCondition, {
    onSuccess: response => {
      if (response.succeeded) {
        setIsSuccess(true)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error?.message || 'An error occurred. Please try again.',
        type: 'error',
        visible: true
      })
    }
  })

  useEffect(() => {
    console.log('location.state', record)

    if (record?.id) {
      form.setFieldsValue({
        Description: record.description
      })
      setDescription(record.description)
      setDescriptionAr(record.descriptionAr)
    }
  }, [record])

  const handleSave = (values: any) => {
    const obj = {
      ...values,
      Description: description, 
      DescriptionAr: descriptionAr, 
      ...(record && { id: record.id })
    }
    mutate(obj)
  }

  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{t('Terms & Conditions')}</p>
        </div>
        <div>
          <Form
            form={form}
            layout='vertical'
            onFinish={handleSave}
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name'>{'Terms & Conditions'}</p>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name='Description'
                  rules={[
                    { required: true, message: 'Please enter the description' }
                  ]}
                >
                  <ReactQuill
                    value={description} // Set the value for the editor
                    onChange={setDescription} // Update the state when content changes
                    placeholder='Enter Description'
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link', 'image'],
                        ['clean']
                      ]
                    }}
                    formats={[
                      'header',
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'link',
                      'image'
                    ]}
                    style={{ direction: "ltr", textAlign: "left" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <p className='user-name mt-3 dir-rtl'>{'الشروط والأحكام'}</p>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name='DescriptionAr'
                  rules={[
                    { required: true, message: "يرجى إدخال الوصف باللغة العربية" }
                  ]}
                  className='dir-rtl'
                >
                  <ReactQuill
                    value={descriptionAr} // Set the value for the editor
                    onChange={setDescriptionAr} // Update the state when content changes
                    placeholder="أدخل الوصف بالعربية"
                    className='arabic-field'
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link', 'image'],
                        ['clean']
                      ]
                    }}
                    formats={[
                      'header',
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'link',
                      'image'
                    ]}
                    style={{ direction: "rtl", textAlign: "right" }}
                    
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} style={{ marginTop: '15px' }}>
              <Col span={4}>
                <CButton
                  loading={isLoading}
                  style={{ width: '100%' }}
                  htmlType='submit'
                >
                  Update
                </CButton>
              </Col>
            </Row>
          </Form>

          <SuccessModal
            visible={isSuccess}
            message={
              record
                ? 'Terms & Conditions updated successfully'
                : 'New terms & conditions added successfully.'
            }
            onOk={() => navigate('/jive/EventPolicyManagementList')}
          />
          <CustomAlert
            message={alert.message}
            type={alert.type as 'success' | 'info' | 'warning' | 'error'}
            visible={alert.visible}
            onClose={() => setAlert({ ...alert, visible: false })}
            duration={3000}
          />
        </div>
      </div>
    </>
  )
}

export default AddTermAndCondition

import React, { useEffect, useState } from 'react'
import CustomTabs from '../../../components/common/CTabs'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import EventList from './Events/EventList'
import CategoriesList from './Categories/CategoriesList'
import TermsAndConditions from './TermsAndConditions/TermsAndConditions'
import CancellationPolicy from './CancellationPolicy/CancellationPolicy'
import CountryList from './Country/Countries'
import ExpiryTime from './ExpiryTime/ExpiryTime'

const tabs = [
  { key: 'event', label: 'Type of Event' },
  { key: 'category', label: 'Categories' },
  { key: 'country', label: 'Countries' },
  { key: 'termsAndCondition', label: 'Terms & Conditions' },
  { key: 'cancellationPolicy', label: 'Cancellation Policy' },
  { key: 'expiryTime', label: 'Expiry Time' },
]

const EventPolicyManagementList: React.FC = () => {
  const [activeKey, setActiveKey] = useState('event')
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation()
  const handleTabChange = (key: string) => {
    setActiveKey(key);
    localStorage.setItem('active',key);
    const params = new URLSearchParams(location.search);
    params.set('PageNumber', '1');
    navigate(`${location.pathname}?${params.toString()}`);
  }

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('active');
    if (storedActiveTab) {
      setActiveKey(storedActiveTab);
    }
  }, []);

  const [countdown, setCountdown] = useState(1); // Set an initial countdown (in seconds)

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 700);
      return () => clearTimeout(timer); // Cleanup on unmount or count change
    }
  }, []);

  return (
    <>
      <p className='page-heading'>{t('event_policy_management')}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />
      {activeKey === 'event' && <EventList />}
      {activeKey === 'country' && <CountryList />}
      {activeKey === 'category' && <CategoriesList />}
      {activeKey === 'termsAndCondition' && <TermsAndConditions />}
      {activeKey === 'cancellationPolicy' && <CancellationPolicy />}
      {activeKey === 'expiryTime' && <ExpiryTime />}
    </>
  )
}

export default EventPolicyManagementList

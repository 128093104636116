import React, { useState, useEffect, useRef } from "react";
import { Theme } from "../../../Theme";
import CTable from "../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DatePicker, Dropdown, MenuProps, Switch, Tag } from "antd";
import CustomAlert from "../../../components/common/CAlert";
import _ from 'lodash';
import CButton from "../../../components/common/CButton";
import { getAdminReportExportToExcel, getAdminReports } from "../../../services/report";
import dayjs from 'dayjs';
import { RangePickerProps } from "antd/es/date-picker";
import permissionService from "../../../services/permissions";


const VendorList: React.FC = () => {
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;

    const pageNumber = Number(searchParams.get('PageNumber')) || 1;
    const pageSize = Number(searchParams.get('PageSize')) || 10;
    const search = searchParams.get('Search') || "";
    const startDate = searchParams.get('StartDate') || "";
    const endDate = searchParams.get('EndDate') || "";

    const downloadFile = (type: string) => {
        if (type === 'xls') {
            refetch();
        } else {
            navigate('/jive/pdfReportPreview', { state: { data, startDate, endDate, type:'vendor' } })
        }
    }
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <p className="m-0" onClick={() => downloadFile('pdf')}>PDF</p>
            ),
        },
        {
            key: '2',
            label: (
                <p className="m-0" onClick={() => downloadFile('xls')}>XLS</p>
            ),
        }
    ];


    // Pagination handling
    const handleTableChange = (page: number, pageSize: number) => {
        setSearchParams({
            // Status: activeKey, 
            StartDate: startDate, 
            EndDate: endDate,
            PageNumber: page.toString(),
            PageSize: pageSize.toString(),
        }, { replace: true });
    };

    const handleSearchChange = _.debounce((e: any) => {
        setSearchParams({
            // Status: activeKey, 
            PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value
        }, { replace: true });
    }, 1000)

    // const handleSwitchChange = (user: any) => {
    //     setUserWillActive({ show: true, status: user?.active, id: user?.id });
    // };
    const columns: ColumnsType = [
        {
            title: t("Vendor ID"),
            dataIndex: 'vendorId',
            key: 'vendorId',
        },
        {
            title: t("Vendor Name"),
            dataIndex: 'vendorName',
            key: 'name',
        },
        {
            title: t("Phone Number"),
            dataIndex: 'phoneNumber',
            key: 'name',
        },
        // {
        //     title: t("Email"),
        //     dataIndex: 'email',
        //     key: 'email',
        // },
        // {
        //     title: t("Location"),
        //     dataIndex: 'location',
        //     key: 'location',
        // },
        {
            title: t("City"),
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: t("Registration Date"),
            dataIndex: 'registrationDate',
            key: 'registrationDate',
        },
        {
            title: t("Total Spend"),
            dataIndex: 'totalSpend',
            key: 'totalSpend',
        },
        // {
        //     title: t("Promo Codes"),
        //     dataIndex: 'promoCode',
        //     key: 'promoCode',
        // },
        // {
        //     title: t("Service Category"),
        //     dataIndex: 'serviceCategory',
        //     key: 'serviceCategory',
        // },
        {
            title: t('action'),
            render: (text, record) => (
                <div className="d-flex  align-items-center">
                    <img src={Theme.icons.icn_view_list} alt="icn_user_edit" style={{ height: '20px', cursor: 'pointer' }}
                        onClick={() => navigate('/jive/viewReport', { state: { record, type:'vendor' } })}
                    />
                </div>
            ),
            key: 'action',
        },
    ];

    const { data, error, isLoading } = useQuery(
        ['vendorReports', pageNumber, pageSize, search, startDate, endDate],
        () => getAdminReports('AdminReports/GetAdminVendorReports',{ PageNumber: pageNumber, PageSize: pageSize, Search: search, StartDate: startDate, EndDate: endDate }),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );

    const { data: statusData, isLoading: statusLoading, error: statusError, refetch } = useQuery(
        ['vendorReportExportToExcel', startDate, endDate],
        () => getAdminReportExportToExcel('AdminReports/AdminVendorReportExportToExcel',{ StartDate: startDate, EndDate: endDate }),
        {
            enabled: false,
            onSuccess: (data) => {
                // Create a Blob from the response data
                const blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // MIME type for xlsx files
                });
                // Create a link element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'vendor_report.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setAlert({ message: 'You have successfully downloaded the file', type: 'success', visible: true });
            },
        }
    );

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
    };

    const handleSelect = (ranges: any) => {
        if (ranges) {
            const StartDatePicker = dayjs(ranges && ranges[0]).format('YYYY-MM-DD');
            const EndDatePicker = dayjs(ranges && ranges[1]).format('YYYY-MM-DD');

            setSearchParams({ StartDate: StartDatePicker, EndDate: EndDatePicker, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });

        }
        setShowDateRangePicker(!showDateRangePicker);
    };


    const checkDisable = () => {
        if (startDate === "" && endDate === "") {
            return true;
        } else {
            return false;
        }
    }
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 list-heading">{t("List of Vendor Report")}</p>
                <div className="d-flex justify-content-between" style={{ position:'relative' }}>
                    <div className="calendar-div mx-2" onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                        style={{ cursor: 'pointer' }}>
                        <img src={Theme.icons.icn_white_calendar} alt="icn_user_add" style={{ height: '20px' }} />
                    </div>
                    {showDateRangePicker && (
                        <div style={{ position: 'absolute', zIndex: 1000, marginTop: '50px', right: '40px' }}>
                            <RangePicker onChange={handleSelect} disabledDate={disabledDate} style={{ height: '50px', width:'230px' }} />
                        </div>
                    )}

                    <Dropdown disabled={checkDisable()} menu={{ items }} placement="bottomLeft">


                        {   permissionService.getRoleMenuPermissions('Reports')?.export &&
                            <CButton disabled={checkDisable()} className="addUserBtn">
                            <img src={Theme.icons.icn_export} alt="icn_user_add" style={{ height: '20px' }} />
                            <p className="m-0">{t("Export")}</p>
                        </CButton>}
                    </Dropdown>
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showSizeChanger: false,
                    showQuickJumper: false,
                    current: pageNumber,
                    pageSize: pageSize,
                    total: data?.totalRecords,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <p className="detail-label">{`< Prev`}</p>;
                        }
                        if (type === 'next') {
                            return <p className="detail-label">{`Next >`}</p>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />

            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default VendorList;

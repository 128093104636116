import React, { useEffect, useState } from 'react'
import { Theme } from '../../Theme'
import CustomAlert from '../../components/common/CAlert'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import {
  approvedVendorService,
  getAdminServiceById
} from '../../services/service'
import CButton from '../../components/common/CButton'
import { formatFileSize } from '../../utils/functions'
import AlertModal from '../../components/common/AlertModal'
import CustomTabs from '../../components/common/CTabs'
import PackagesList from './PackagesList'
import permissionService from '../../services/permissions'
import Status from '../../components/common/Status'
import { count } from 'console'
const tabs = [
  { key: 'ServiceDetail', label: 'Service Detail' },
  { key: 'Packages', label: 'Packages' }
]
const ViewService: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();
  
  const { t } = useTranslation()
  const UserType= searchParams.get('UserType');

  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  console.log('params', params)

  const { data, isLoading, error, refetch } = useQuery(
    ['serviceById', params?.id],
    () => getAdminServiceById({ Id: params?.id })
  )
  const [activeKey, setActiveKey] = useState('ServiceDetail')
  const { mutate, isLoading: submitLoading } = useMutation(
    approvedVendorService,
    {
      onSuccess: response => {
        if (response.succeeded) {
          setIsModalVisible(false)
          refetch()
          setAlert({
            message: response.message,
            type: 'success',
            visible: true
          })
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )

  const {
    serviceName,
    serviceNameAr,
    categoryName,
    vendorFreelancerName,
    price,
    providerContact,
    serviceDate,
    // serviceStartTime,
    // serviceEndTime,
    serviceDurationInHour,
    serviceLocation,
    serviceLocationAr,
    features,
    featuresAr,
    description,
    descriptionAr,
    serviceImages,
    cityName,
    id,
    status
  } = data?.data || {}
  const [flag, setflag] = useState(false)
  
  const handleModalOk = async () => {
      // setflag(!flag)
      // if(flag){
      //   mutate({ ServiceId: id }) 
      // }
      setIsModalVisible(false)
      mutate({ ServiceId: id }) 
  }
  const callRequest = () => {
    setIsModalVisible(true)
  }
  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const handleTabChange = (key: string) => {
    setActiveKey(key)
  }
  const [countdown, setCountdown] = useState(1); // Set an initial countdown (in seconds)

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 700);
      return () => clearTimeout(timer); // Cleanup on unmount or count change
    }
  }, []);

  return (
    <div style={{ padding: '20px', color: Theme.colors.black }}>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center gap-2'>
          <img
            src={Theme.icons.icn_arrow_back}
            alt='icn_user_add'
            className='cursor-pointer'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          {/* <p className='list-heading m-0 mx-2'>{vendorFreelancerName}</p> */}
          <CustomTabs
            tabs={tabs}
            activeKey={activeKey}
            onTabChange={handleTabChange}
          />
        </div>
        {(status == 'Pending' && activeKey == 'ServiceDetail')  && (
          <div className='d-flex align-items-center justify-content-end '>
            <CButton
              style={{
                backgroundColor: Theme.colors.success,
                borderColor: Theme.colors.success,
                width: '100%'
              }}
              onClick={() => callRequest()}
            >
              Approve
            </CButton>
          </div>
        )}
      </div>
      {activeKey == 'ServiceDetail' ? (
        <div
          className='mt-3'
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <p className='user-name m-0'>{t('Service Details')}</p>
            {
              permissionService.getRoleMenuPermissions('Services & Packages')?.update &&
              <CButton
              className='btn-unfilled h-35'
              onClick={() =>
                navigate('/jive/createService', {
                  state: {
                    id: params.id,
                    vendorFreeLancerName: vendorFreelancerName,
                    record: data?.data,
                    UserType:UserType
                  }
                })
              }
            >
              <img
                src={Theme.icons.icn_edit}
                alt='icn_user_add'
                style={{ height: '15px' }}
              />
              Edit
            </CButton>}
          </div>
          <div
            style={{
              backgroundColor: '#F3F3F3',
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <div className='row mt-4'>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Vendor/Freelancer Name')}</p>
                <p className='detail-value'>{vendorFreelancerName}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Contact Provider')}</p>
                <p className='detail-value'>{providerContact}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Service Name')}</p>
                <p className='detail-value'>{serviceName}</p>
              </div>
              <div className='col-lg-3 '>
                <p className='detail-label m-0'>{t('اسم الخدمة')}</p>
                <p className='detail-value '>
                  {serviceNameAr ? serviceNameAr : '-'}
                </p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Category')}</p>
                <p className='detail-value'>{categoryName}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Price')}</p>
                <p className='detail-value'>{`SAR ${price}`}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Service Date')}</p>
                <p className='detail-value'>{serviceDate?.split('T')[0]}</p>
              </div>
              {/* <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Start Time')}</p>
                <p className='detail-value'>{serviceStartTime}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('End Time')}</p>
                <p className='detail-value'>{serviceEndTime}</p>
              </div> */}
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Service Duration (Hours)')}</p>
                <p className='detail-value'>{serviceDurationInHour?serviceDurationInHour:'-'}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Service Location')}</p>
                <p className='detail-value'>{serviceLocation}</p>
              </div>
              <div className='col-lg-3 '>
                <p className='detail-label m-0'>{t('موقع الخدمة')}</p>
                <p className='detail-value'>
                  {serviceLocationAr ? serviceLocationAr : '-'}
                </p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Service City')}</p>
                <p className='detail-value'>{cityName}</p>
              </div>
              <div className='col-lg-2'>
                <p className='detail-label m-0'>{t('Service Status')}</p>
                <p className='detail-value mt-1' style={{maxWidth:'150px'}}><Status status={status} /></p>
              </div>
              <div className='col-lg-8'>
                <p className='detail-label m-0'>{t('Features')}</p>
                <p className='detail-value'>{features}</p>
              </div>
              <div className='col-lg-8'>
                <p className='detail-label m-0'>{t('Descriptions')}</p>
                <p className='detail-value'>{description}</p>
              </div>
              <div className='col-lg-12 dir-rtl'>
                <p className='detail-label m-0'>{t('Features (ar)')}</p>
                <p className='detail-value'>{featuresAr ? featuresAr : '-'}</p>
              </div>
              <div className='col-lg-12 dir-rtl'>
                <p className='detail-label m-0'>{t('Descriptions (ar)')}</p>
                <p className='detail-value'>
                  {descriptionAr ? descriptionAr : '-'}
                </p>
              </div>
            </div>
          </div>

          <div
            className='mt-3'
            style={{
              backgroundColor: '#F3F3F3',
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name m-0'>{t(`Service ${UserType==='Freelancer'?'Video &':''} Images`)}</p>
            <div className='row'>
            {serviceImages
                ?.sort((a: any, b: any) => (a.fileContentType === 'video/mp4' ? -1 : 1)) // Ensure videos come first
                .map((file: any, index: number) => (
                  file.fileContentType === 'video/mp4' ? (
                    <div className='col-12' key={index}>
                      <div className='d-flex justify-content-start mt-3'>
                        <video 
                          src={process.env.REACT_APP_S3_BUCKET_URL + file.path} 
                          controls 
                          style={{ width: '200px', height: '200px', padding:'15px' }}
                          className='preview-image-view-container' 
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='col-lg-4' key={index}>
                      <div className='d-flex align-items-center p-3 preview-image-view-container mt-3'>
                        <div className='d-flex align-items-center'>
                          <img
                            src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                            alt={file.originalFileName}
                            className='category-icon mb-0'
                            style={{ width: '50px', height: '50px' }}
                          />
                          <div className='mx-2'>
                            <p
                              className='detail-label m-0'
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {file.originalFileName}
                            </p>
                            <p className='detail-label m-0'>
                              {formatFileSize(file.fileLength)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              ))}

            </div>
          </div>
        </div>
      ) : (
        <>
          <PackagesList serviceDetail={data?.data} />
        </>
      )}
      <AlertModal
        visible={isModalVisible}
        message={t(`Are you sure you want to Approve this Service?`)}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  )
}

export default ViewService

import React from 'react'
import { Theme } from '../../Theme'

interface CardProps {
  icon: string
  backgroundColor: string
  count: number
  currency: string
  name: string
}

const Card: React.FC<CardProps> = ({
  icon,
  backgroundColor,
  count,
  currency,
  name
}) => {
  return (
    <div
      className='custom-card'
      style={{ backgroundColor: backgroundColor || '#fff' }}
    >
      <div className='card-content'>
        <div
          className='card-top'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            alignItems: 'end'
          }}
        >
          <div className='count' style={{ fontWeight: 'bold' }}>
            {count}
          </div>
          {currency && (
            <div className='currency' style={{ marginLeft: '4px' }}>
              {currency}
            </div>
          )}
        </div>
        <div
          className='card-bottom'
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'start'
          }}
        >
          <div className='icon-container' style={{ marginRight: '8px' }}>
            <img
              src={`${icon}`}
              className='img-fluid'
              alt={name}
              style={{ width: '48px', height: '48px' }}
            />
          </div>
          <div className='heading' style={{ fontSize: '1rem' }}>
            {name}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card

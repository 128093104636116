import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Switch,
  Card,
  Spin,
  Modal,
  DatePicker,
} from "antd";
import { Theme } from "../../../Theme";
import CustomAlert from "../../../components/common/CAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import AlertModal from "../../../components/common/AlertModal";
import { useTranslation } from "react-i18next";
import {
  changeVendorStatus,
  createMeetingLink,
  finalApproval,
  getVendorFreelancerById,
  initialApprovedVendor,
  rejectVendor,
} from "../../../services/freelancer";
import { getAllParentCategory } from "../../../services/lov";
import dayjs from "dayjs";
import { formatFileSize } from "../../../utils/functions";
import CButton from "../../../components/common/CButton";
import CInput from "../../../components/common/CInput";
import { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import permissionService from "../../../services/permissions";

interface VendorOnboardingProps {
  user_type: "vendor" | "freelancer";
}

const weekdays = [
  { label: "Mon", value: "monday" },
  { label: "Tue", value: "tuesday" },
  { label: "Wed", value: "wednesday" },
  { label: "Thu", value: "thursday" },
  { label: "Fri", value: "friday" },
  { label: "Sat", value: "saturday" },
  { label: "Sun", value: "sunday" },
];

const ViewVendor: React.FC<VendorOnboardingProps> = ({ user_type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { id, type } = location.state;
  const [form] = Form.useForm();
  const [selectedTab, setSelectedTab] = useState(1);
  const [days, setDays] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [checked, setChecked] = useState("");
  const [previewImg, setPreviewImg] = useState("");
  const [visible, setVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, right: 0 });
  const [alert, setAlert] = useState<{
    message: string;
    type: string;
    visible: boolean;
  }>({
    message: "",
    type: "success",
    visible: false,
  });

  console.log("type==>>>", type);

  const {
    data: vendorData,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["VendorFreelancerById", id],
    () => getVendorFreelancerById({ Id: id }),
    {
      onSuccess: (data) => {
        setSwitchChecked(data?.data?.active);
      },
    }
  );

  let {
    status,
    businessName,
    personName,
    phoneNumber,
    businessAddress,
    websiteURL,
    email,
    serviceTypeId,
    profileImagePath,
    serviceTechnicalDescription,
    portfolioLink,
    socialMediaLinks,
    experience,
    eventType,
    bookingProcessDetail,
    bookingStartTime,
    bookingEndTime,
    commericalRegistrationNumber,
    vendorFreelancerCertifications,
    vendorFreelancerLicenses,
    bankName,
    accountHolderName,
    accountNumber,
    cityName,
    countryName,
    contactPersonName,
    communicationMethod,
    meetingLink,
    meetingLinkDate,
  } = vendorData?.data || {};

  const { mutate: addLink, isLoading: linkLoading } = useMutation(
    createMeetingLink,
    {
      onSuccess: (response) => {
        if (response.succeeded) {
          refetch();
          setAlert({
            message: t("Meeting link generated successfully!"),
            type: "success",
            visible: true,
          });
          setIsVisible(false);
        } else {
          setAlert({ message: response.message, type: "error", visible: true });
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || "An error occurred. Please try again.",
          type: "error",
          visible: true,
        });
      },
    }
  );

  const FinalApprovals = [
    {
      id: 1,
      label:
        user_type == "vendor"
          ? t("Vendor Information")
          : t("Freelancer Information"),
      icon: Theme.icons.icn_user_company,
    },
    {
      id: 2,
      label: t("service_offerings"),
      icon: Theme.icons.icn_services,
    },
    {
      id: 3,
      label: t("portfolio_experience"),
      icon: Theme.icons.icn_portfolio,
    },
    {
      id: 4,
      label: t("availability_booking"),
      icon: Theme.icons.icn_booking,
    },
    {
      id: 5,
      label: t("certifications_licenses"),
      icon: Theme.icons.icn_license,
    },
    {
      id: 6,
      label: t("payment_billing"),
      icon: Theme.icons.icn_payment,
    },
    {
      id: 7,
      label: t("support_communication"),
      icon: Theme.icons.icn_support,
    },
  ];

  const {
    mutate,
    isLoading: statusLoading,
    isError,
  } = useMutation(
    type === "approval"
      ? checked === "Accept"
        ? initialApprovedVendor
        : checked === "Approved"
        ? finalApproval
        : rejectVendor
      : changeVendorStatus,
    {
      onSuccess: (response) => {
        if (response.succeeded) {
          setSwitchChecked(!switchChecked);
          if (type === "approval") {
            refetch();
          }
          setAlert({
            message: "Status Updated successfully!",
            type: "success",
            visible: true,
          });
        } else {
          setAlert({ message: response.message, type: "error", visible: true });
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error
            ? error?.message
            : "An error occurred. Please try again.",
          type: "error",
          visible: true,
        });
      },
    }
  );

  const handleSwitchChange = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    const obj =
      type === "approval"
        ? { VendorId: checked === "Approved" ? id : undefined, id }
        : { id, status: !switchChecked };

    console.log("obj==>>>>", obj);
    mutate(obj);
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (vendorData?.data) {
      let selectedDays: string[] = [];

      weekdays.forEach((day) => {
        console.log("d==>", day);
        if (vendorData?.data[day.value]) {
          selectedDays.push(day.label); // Push label (Mon, Tue, etc.) to the array
        }
      });
      setDays(selectedDays);
    }
  }, [vendorData]);

  const handleTabClick = (tabId: number) => {
    if (
      status !== "InterviewInvited" &&
      status !== "InitialRequested" &&
      status !== "InitialRequestedApproved"
    )
      setSelectedTab(tabId);
  };

  const previewImage = (file: any) => {
    console.log("my file===>>>", file);
    setPreviewImg(file);
    setVisible(true);
  };

  const {
    data: categoriesData,
    error: countryError,
    isLoading: isCategoriesLoading,
  } = useQuery("categories", getAllParentCategory);
  const selectedCategories = categoriesData?.data?.filter((category: any) =>
    serviceTypeId?.includes(category.id)
  );

  const showModal = () => {
    if (buttonRef.current) {
      const { top, right, height } = buttonRef.current.getBoundingClientRect();
      setModalPosition({ top: top + height, right: window.innerWidth - right });
    }
    setIsVisible(true);
  };

  const onOk = (
    value: DatePickerProps["value"] | RangePickerProps["value"]
  ) => {
    if (value && !Array.isArray(value)) {
      const formattedDate = moment(value.toDate()).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      console.log("Selected Date:", formattedDate);
      form.setFieldsValue({ meetingLinkDate: formattedDate });
    }
  };

  const callRequest = (val: string) => {
    setChecked(val);
    setIsModalVisible(true);
    console.log("Value", val);
  };

  const onFinish = (values: any) => {
    const obj = { id, ...values };
    console.log("form values===>>>", obj);
    addLink(obj);
  };
  const [countdown, setCountdown] = useState(1); // Set an initial countdown (in seconds)

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 700);
      return () => clearTimeout(timer); // Cleanup on unmount or count change
    }
  }, []);
  return (
    <div style={{ color: Theme.colors.black }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={Theme.icons.icn_arrow_back}
            className="cursor-pointer"
            alt="icn_user_add"
            style={{ height: "20px" }}
            onClick={() => navigate(-1)}
          />
          <p className="list-heading m-0 mx-2">
            {user_type == "vendor"
              ? t("Vendor Details")
              : t("Freelancer Details")}
          </p>
        </div>
        {type === "approval" &&
        permissionService.getRoleMenuPermissions(
          user_type == "vendor"
            ? "Manage Vendor"
            :"Manage Freelancer"
            
        )?.acceptReject ? (
          status !== "InitialRequestedApproved" &&
          status !== "Approved" && (
            <>
              <div className="d-flex align-items-center ">
                {status !== "InitialRequested" && (
                  <CButton
                    style={{
                      marginRight: "12px",
                      backgroundColor: Theme.colors.success,
                      borderColor: Theme.colors.success,
                      width: "100%",
                    }}
                    onClick={() =>
                      callRequest(
                        status === "Submitted" ? "Approved" : "Accept"
                      )
                    }
                  >
                    {status === "Submitted" ? "Approve" : "Accept"}
                  </CButton>
                )}
                <CButton
                  className="rejectBtn"
                  style={{ colo: "red" }}
                  onClick={() => callRequest("Reject")}
                >
                  Reject
                </CButton>
              </div>
            </>
          )
        ) : permissionService.getRoleMenuPermissions(
            user_type == "vendor"
              ? "Manage Vendor"
              : "Manage Freelancer"
          )?.enableDisabled ? (
          <div className="d-flex align-items-center">
            {/* <CButton style={{ marginRight: '12px', backgroundColor: Theme.colors.success, borderColor: Theme.colors.success }}>Accept</CButton>
                    <CButton className='rejectBtn' style={{ colo: 'red' }}>Reject</CButton> */}
            <p className="m-0 admin" style={{ color: Theme.colors.black }}>
              {switchChecked ? "Active" : "Inactive"}
            </p>
            <Switch
              checked={switchChecked}
              onChange={handleSwitchChange}
              className="mx-2"
            />
          </div>
        ) 
        : null}
      </div>
      <Card className="mt-3">
        <p className="freelancer-heading m-0">
          {user_type == "vendor"
            ? t("Vendor Profile")
            : t("Freelancer Profile")}
        </p>
      </Card>
      <div className="row" style={{ paddingRight: "0.8rem" }}>
        <div className="col-lg-3">
          <Card className="mt-3" style={{ borderRadius: "16px" }}>
            {FinalApprovals?.map((item) => (
              <div
                key={item.id}
                className="d-flex align-items-center mt-2 w-100 approval-tab"
                style={{
                  border:
                    item?.id == selectedTab
                      ? `1px solid ${Theme.colors.tertiary}`
                      : `1px solid ${Theme.colors.gray}`,
                }}
                onClick={() => handleTabClick(item.id)}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    height: "28px",
                    width: "28px",
                    borderRadius: "8px",
                    backgroundColor:
                      item?.id === selectedTab
                        ? "#FDF0D9"
                        : Theme.colors.gray_light,
                  }}
                >
                  <img
                    src={item.icon}
                    alt={item.label}
                    style={{
                      height: "20px",
                      // Use filter to change the color of the SVG when selected
                      filter:
                        item?.id === selectedTab
                          ? "invert(74%) sepia(99%) saturate(1320%) hue-rotate(1deg) brightness(103%) contrast(101%)"
                          : "none",
                    }}
                  />
                </div>
                <p className="m-0 mx-2 admin">{item.label}</p>
              </div>
            ))}
          </Card>
        </div>
        <div
          className="col-lg-9 mt-3"
          style={{
            backgroundColor: Theme.colors.white,
            padding: "24px",
            borderRadius: "16px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="user-name m-0">
              {FinalApprovals[selectedTab - 1]?.label}
            </p>
            {type === "approval" && !meetingLink && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: "28px",
                  width: "28px",
                  borderRadius: "8px",
                  backgroundColor: "#CBF2FF",
                }}
                ref={buttonRef}
                onClick={showModal}
              >
                <img
                  src={Theme.icons.icn_link}
                  alt="icn_user_company"
                  style={{ height: "20px" }}
                  className="cursor-pointer"
                />
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spin />
            </div>
          ) : (
            <>
              {selectedTab === 1 && (
                <div
                  style={{
                    backgroundColor: "#F3F3F3",
                    padding: "24px",
                    borderRadius: "16px",
                  }}
                >
                  <img
                    src={
                      profileImagePath
                        ? process.env.REACT_APP_S3_BUCKET_URL + profileImagePath
                        : Theme.images.img_placeholder
                    }
                    alt="icn_user_company"
                    style={{
                      height: "60px",
                      width: "60px",
                      borderRadius: "50%",
                    }}
                  />
                  {/* details-and-meet-link */}
                  <div className="mt-2">
                    <div className="row">
                      <div className="col-lg-6">
                        <p className="detail-label m-0">
                          {t("bussiness_name")}
                        </p>
                        <p className="detail-value">{businessName}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="detail-label m-0">{t("person_name")}</p>
                        <p className="detail-value">{contactPersonName}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="detail-label m-0">{t("phone_number")}</p>
                        <p className="detail-value">{phoneNumber}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="detail-label m-0">
                          {t("bussiness_address")}
                        </p>
                        <p className="detail-value">{businessAddress}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="detail-label m-0">{t("web_url")}</p>
                        <p className="detail-value">{websiteURL}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="detail-label m-0">{t("email")}</p>
                        <p className="detail-value">{email}</p>
                      </div>
                    </div>
                  </div>
                  {meetingLink !== null && status == "InterviewInvited" && (
                    <div
                      className="d-flex justify-content-between align-items-center p-3"
                      style={{
                        backgroundColor: "#EBE3F3",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="p-3"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                        >
                          <img src={Theme.icons.icn_link} />
                        </div>
                        <div className="mx-2">
                          <p className="m-0 detail-label">
                            Google Meeting Schedule
                          </p>
                          <p className="m-0">
                            <strong>Link:</strong>
                            {meetingLink}
                          </p>
                          <p
                            className="m-0 detail-label"
                            style={{ color: Theme.colors.red }}
                          >{`Expire ${moment(meetingLinkDate).format(
                            "DD-MM-YYYY hh:mm A"
                          )}`}</p>
                        </div>
                      </div>
                      <CButton
                        className="cancelBtn"
                        style={{ width: "15%" }}
                        onClick={() => window.open(meetingLink, "_blank")}
                      >
                        Join
                      </CButton>
                    </div>
                  )}
                </div>
              )}

              {selectedTab === 2 && (
                <>
                  {selectedCategories?.length !== 0 && (
                    <div
                      className="row"
                      style={{
                        backgroundColor: "#F3F3F3",
                        padding: "24px",
                        borderRadius: "16px",
                      }}
                    >
                      {selectedCategories?.map((x: any, index: number) => (
                        <div
                          key={index}
                          className="col-lg-2 m-2 text-center p-2"
                          style={{
                            backgroundColor: Theme.colors.white,
                            borderRadius: "12px",
                          }}
                        >
                          <img
                            src={
                              x?.imagePath
                                ? process.env.REACT_APP_S3_BUCKET_URL +
                                  x?.imagePath
                                : Theme.icons.icn_link
                            }
                            alt="category_name"
                            className="category-icon"
                          />
                          <p className="inputfield-label m-0">{x?.name}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  <div
                    className="row mt-3"
                    style={{
                      backgroundColor: "#F3F3F3",
                      padding: "24px",
                      borderRadius: "16px",
                    }}
                  >
                    <p className="user-name m-0">
                      {t("Service Technical Requirements")}
                    </p>
                    <p className="vendor-detail-description">
                      {serviceTechnicalDescription}
                    </p>
                  </div>
                </>
              )}
              {selectedTab === 3 && (
                <div
                  style={{
                    backgroundColor: "#F3F3F3",
                    padding: "24px",
                    borderRadius: "16px",
                  }}
                >
                  <div className="w-75 row">
                    <div className="col-lg-6">
                      <p className="detail-label m-0">{t("Portfolio Link")}</p>
                      <p className="detail-value">{portfolioLink}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="detail-label m-0">
                        {t("Social Media Link")}
                      </p>
                      <p className="detail-value">{socialMediaLinks}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="detail-label m-0">{t("Experience")}</p>
                      <p className="detail-value">{experience}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="detail-label m-0">
                        {t("Event Type Served")}
                      </p>
                      <p className="detail-value">{eventType}</p>
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 4 && (
                <>
                  <div
                    className="mt-3"
                    style={{
                      backgroundColor: "#F3F3F3",
                      padding: "24px",
                      borderRadius: "16px",
                    }}
                  >
                    <p className="detail-label m-0">
                      {t("Available Week Days")}
                    </p>
                    <div className="d-flex">
                      {days?.length > 0 &&
                        days?.map((x) => (
                          <div
                            className="d-flex justify-content-center align-items-center m-2"
                            style={{
                              height: "30px",
                              width: "30px",
                              backgroundColor: Theme.colors.primary,
                              borderRadius: "50%",
                            }}
                          >
                            <p
                              className="m-0"
                              style={{
                                color: Theme.colors.white,
                                fontSize: "10px",
                                fontFamily: "sf-regular",
                              }}
                            >
                              {x}
                            </p>
                          </div>
                        ))}
                    </div>
                    <div className="row w-75">
                      <div className="col-lg-6">
                        <p className="detail-label m-0">
                          {t("Booking Start Time")}
                        </p>
                        <p className="detail-value">{bookingStartTime}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="detail-label m-0">
                          {t("Booking End Time")}
                        </p>
                        <p className="detail-value">{bookingEndTime}</p>
                      </div>
                    </div>
                    <p className="detail-labe m-0">
                      {t("Booking Process Details")}
                    </p>
                    <p className="detail-value">{bookingProcessDetail}</p>
                  </div>
                </>
              )}
             {selectedTab === 5 && (
                                    <>
                                        <div className='mt-3' style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                            <div className='row w-75'>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("Commercial Registration Number")}</p>
                                                    <p className='detail-value'>{commericalRegistrationNumber}</p>
                                                </div>
                                            </div>
                                            {/* <p className='user-name m-0'>{t("Documents")}</p> */}
                                            <div className="row">

                                            <div className="w-50">
                                            <p className='user-name m-0'>{t("Certifications")}</p>

                                                {vendorFreelancerCertifications?.map((file: any, index: number) => (
                                                    <div className="col-lg-12" key={index}>
                                                        <div className="d-flex align-items-center p-3 preview-image-view-container mt-3 cursor-pointer" onClick={() => file?.fileContentType !== "application/pdf" ? previewImage(process.env.REACT_APP_S3_BUCKET_URL + file.path) : window.open(process.env.REACT_APP_S3_BUCKET_URL + file.path, '_blank')}>

                                                            <div className="d-flex align-items-center">
                                                                {file?.fileContentType === "application/pdf" ?
                                                                    <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#EBE3F3', width: '60px', height: '60px', borderRadius: '12px',flexShrink: 0,
                                                                        flexBasis: '60px', }}>
                                                                        <img src={Theme.icons.icn_document} />
                                                                    </div>
                                                                    :
                                                                    <img
                                                                        src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                                                                        alt={file.originalFileName}
                                                                        className="category-icon mb-0"
                                                                        style={{ width: '60px', height: '60px', borderRadius: '12px' }}
                                                                    />
                                                                }
                                                                <div className="mx-2">
                                                                    <p className="detail-label m-0" style={{overflow: 'hidden',textOverflow: 'ellipsis',maxWidth :'90%'}}>{file.originalFileName}</p>
                                                                    <p className="detail-label m-0">{formatFileSize(file.fileLength)}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            {user_type=='freelancer' && (
                                                <div className="w-50">
                                            <p className='user-name m-0'>{t("Licenses")}</p>

                                                {vendorFreelancerLicenses?.map((file: any, index: number) => (
                                                    <div className="col-lg-12" key={index}>
                                                        <div className="d-flex align-items-center p-3 preview-image-view-container mt-3 cursor-pointer" onClick={() => file?.fileContentType !== "application/pdf" ? previewImage(process.env.REACT_APP_S3_BUCKET_URL + file.path) : window.open(process.env.REACT_APP_S3_BUCKET_URL + file.path, '_blank')}>

                                                            <div className="d-flex align-items-center">
                                                                {file?.fileContentType === "application/pdf" ?
                                                                    <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#EBE3F3', width: '60px', height: '60px', borderRadius: '12px',flexShrink: 0,
                                                                        flexBasis: '60px', }}>
                                                                        <img src={Theme.icons.icn_document} />
                                                                    </div>
                                                                    :
                                                                    <img
                                                                        src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                                                                        alt={file.originalFileName}
                                                                        className="category-icon mb-0"
                                                                        style={{ width: '60px', height: '60px', borderRadius: '12px' }}
                                                                    />
                                                                }
                                                                <div className="mx-2">
                                                                    <p className="detail-label m-0" style={{overflow: 'hidden',textOverflow: 'ellipsis'}}>{file.originalFileName}</p>
                                                                    <p className="detail-label m-0">{formatFileSize(file.fileLength)}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            )}
                                            </div>
                                        </div>
                                    </>
                                )}
              {selectedTab === 6 && (
                <div
                  style={{
                    backgroundColor: "#F3F3F3",
                    padding: "24px",
                    borderRadius: "16px",
                  }}
                >
                  <div className="w-75 row">
                    <div className="col-lg-6">
                      <p className="detail-label m-0">{t("Bank Name")}</p>
                      <p className="detail-value">{bankName}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="detail-label m-0">
                        {t("Account Holder Name")}
                      </p>
                      <p className="detail-value">{accountHolderName}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="detail-label m-0">{t("Account Number")}</p>
                      <p className="detail-value">{accountNumber}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="detail-label m-0">{t("City")}</p>
                      <p className="detail-value">{cityName}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="detail-label m-0">{t("Country")}</p>
                      <p className="detail-value">{countryName}</p>
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 7 && (
                <>
                  <div
                    className="mt-3"
                    style={{
                      backgroundColor: "#F3F3F3",
                      padding: "24px",
                      borderRadius: "16px",
                    }}
                  >
                    <div className="row w-75">
                      <div className="col-lg-6">
                        <p className="detail-label m-0">
                          {t("Contact Person")}
                        </p>
                        <p className="detail-value">{contactPersonName}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="detail-label m-0">
                          {t("Preferred Communication")}
                        </p>
                        <p className="detail-value">{communicationMethod}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
        <img src={previewImg} style={{ width: "100%" }} />
      </Modal>

      <Modal
        title="Google Meet Link"
        visible={isVisible}
        footer={null}
        className="w-25"
        // onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        centered
        style={{
          top: modalPosition.top,
          right: modalPosition.right,
          position: "absolute",
          width: "min-content",
        }}
        mask={false} // Disable overlay to show over the button only
      >
        <p>{t("Please enter your meeting link")}</p>
        <Form
          name="meetingLink"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="meetingLink"
            rules={[
              {
                required: true,
                message: t("Please enter meeting link"),
              },
            ]}
          >
            <CInput
              label={t("Meeting Link")}
              placeholder={t("Enter Meeting Link")}
            />
          </Form.Item>

          <Form.Item
            name="meetingLinkDate"
            rules={[
              {
                required: true,
                message: t("Please enter meeting link date"),
              },
            ]}
          >
            <label
              className="inputfield-label"
              style={{ display: "block", marginBottom: "5px" }}
            >
              {t("Expiry Date")}
            </label>
            <DatePicker
              className="inputfield w-100"
              disabledDate={(current) =>
                current && current.isBefore(dayjs().startOf("day"))
              }
              showTime
              onChange={(value, dateString) => {
                console.log("Selected Time: ", value);
                console.log("Formatted Selected Time: ", dateString);
                // form.setFieldsValue({meetingLinkDate: dateString});
              }}
              onOk={onOk}
            />
          </Form.Item>
          <CButton
            loading={linkLoading}
            disabled={false}
            className="mt-4 auth-btn"
            htmlType="submit"
          >
            {t("Create")}
          </CButton>
        </Form>
      </Modal>
      <AlertModal
        visible={isModalVisible}
        message={`Are you sure you want to ${
          type === "approval"
            ? checked
            : switchChecked
            ? "deactivate"
            : "activate"
        } this vendor/freelancer?`}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type as "success" | "info" | "warning" | "error"}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  );
};

export default ViewVendor;

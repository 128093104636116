import instance from './api/api_instance'

export const get = async (url: any, credentials: object) => {
  const response = await instance.get(url, {
    params: credentials
  })
  return response.data
}

export const post = async (url: any, credentials: object) => {
  const response = await instance.post(url, credentials)
  return response.data
}

export const getVendorsList = async (credentials: object) => {
  const response = await instance.post("AdminVendorFreelancer/GetAllApprovedVendorFreelancersDropDown", {
    params: credentials,
  });
  return response.data;
};

export const getAllAdminServiceOrder = async (credentials: object) => {
  const response = await instance.get("AdminServiceOrder/GetAll", {
    params: credentials,
  });
  return response.data;
};

export const getAdminServiceOrderById = async (credentials: object) => {
  const response = await instance.get("AdminServiceOrder/GetById", {
    params: credentials,
  });
  return response.data;
};

export const rejectAdminServiceOrder = async (credentials: object) => {
  const response = await instance.get("AdminServiceOrder/Reject", {
    params: credentials,
  });
  return response.data;
};

export const approvedAdminServiceOrder = async (credentials: object) => {
  const response = await instance.get("AdminServiceOrder/Approved", {
    params: credentials,
  });
  return response.data;
};

export const getOrderTrackingById = async (credentials: object) => {
  const response = await instance.get("AdminServiceOrderTracking/GetOrderTrackingById", {
    params: credentials,
  });
  return response.data;
};

export const getAdminSentServiceOrders = async (credentials: object) => {
  const response = await instance.get("AdminServiceOrder/GetAdminSentServiceOrders", {
    params: credentials,
  });
  return response.data;
};

export const rescheduleBooking = async (credentials: object) => {
  const response = await instance.post('AdminServiceOrder/RescheduleCustomEvent', credentials);
  return response.data;
};

export const getAdminServiceOrderReviewById = async (credentials: object) => {
  const response = await instance.get("AdminServiceOrderReview/GetById", {
    params: credentials,
  });
  return response.data;
};
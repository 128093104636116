import React, { useEffect, useState } from 'react'
import { Theme } from '../../Theme'
import { get } from '../../services/api/apiService'
import { useNavigate } from 'react-router-dom'
import CustomAlert from './CAlert'

interface CustomAlertProps {}

const Header: React.FC<CustomAlertProps> = ({}) => {
  const navigate = useNavigate()
  const [notificationCount, setNotificationCount] = useState(0)
  const [userDetails, setUserDetails] = useState<{ [key: string]: any }>({})
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const getNotificationCount = async () => {
    let notification = await get(
      'AdminNotification/GetUnReadNotificationCount',
      {}
    )
    // console.log("notification.count",notification.data.count);
    if (notification.succeeded) {
      setNotificationCount(notification.data.count)
    } else {
      setAlert({ message: notification.message, type: 'error', visible: true })
    }
  }

  const getProfile = () => {
    get('AdminAccount/GetMyProfile', {}).then(response => {
      if (response.succeeded) {
        setUserDetails(response.data)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    })
  }

  useEffect(() => {
    getNotificationCount()
    getProfile()
  }, [])

  // console.log('userDetails?.userType==+>>>', userDetails?.userType);

  return (
    <div
      className='p-3'
      style={{
        backgroundColor: Theme.colors.white,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 10
      }}
    >
      <div
        className='notification-count'
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <img
          alt=''
          src={Theme.icons.icn_notification}
          className='me-3'
          style={{ height: '36px', width: '36px', cursor: 'pointer' }}
          onClick={() => {
            setNotificationCount(0)
            navigate(`/jive/notifications`)
          }}
        />
        {notificationCount > 0 && (
          <span className='notification-badge'>{notificationCount}</span>
        )}
      </div>
      <img
        alt=''
        src={Theme.images.img_placeholder}
        style={{
          borderRadius: '50%',
          height: '44px',
          width: '44px',
          marginLeft: 10
        }}
      />
      <div>
        <p className='mx-2 m-0 detail-value'>{userDetails?.fullName}</p>
        <p className='mx-2 m-0 admin'>
          {userDetails?.userType === 'SuperAdmin'
            ? 'Super Admin'
            : userDetails?.userType}
        </p>
      </div>
      <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
    </div>
  )
}

export default Header

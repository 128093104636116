import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface CustomSelectProps {
  options: { value: string; label: string,disabled?:boolean }[];
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  disabled ? :boolean
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, label, className, placeholder, value, onChange,disabled }) => {
    
  const inputClassName = `inputfield ${className || ''}`;
  return (
    <div>
    {label && <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{label}</label>}
    <Select
      placeholder={placeholder || "Please select"}
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      className={inputClassName}
      disabled = {disabled}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </Option>
      ))}
    </Select>
    </div>
  );
};

export default CustomSelect;

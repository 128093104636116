import moment from 'moment'
import React, { useEffect, useState } from 'react'

interface TimerCountDownProps {
  heading: string
  icon?: string
  backgroundColor?: string
  time: string
}

const TimerCountDown: React.FC<TimerCountDownProps> = ({
  heading,
  icon,
  backgroundColor,
  time
}) => {
  const calculateTimeDifference = () => {
    const targetTime = moment.utc(time).local()
    const currentTime = moment()

    return Math.max(targetTime.diff(currentTime, 'seconds'), 0)
  }
  const [countdown, setCountdown] = useState<number>(calculateTimeDifference())

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(timerId)
          return 0
        }
        return prevCountdown - 1
      })
    }, 1000)

    return () => clearInterval(timerId)
  }, [time])

  if (isNaN(countdown)) {
    return <div>Invalid Date</div>
  } else if (countdown === 0) {
    return <div></div>
  }

  const hours = String(Math.floor(countdown / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((countdown % 3600) / 60)).padStart(2, '0')
  const seconds = String(countdown % 60).padStart(2, '0')

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px 20px',
        width: '210px',
        height: '100px',
        backgroundColor: backgroundColor || '#f9f9f9',
        borderRadius: '15px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
      }}
    >
      <div
        className='flex-container'
        style={{
          display: 'flex',
          alignItems: 'start',
          width: '100%'
        }}
      >
        {icon && (
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img
              src={icon}
              alt='icon'
              style={{
                width: '40px',
                height: '40px',
                padding: '5px'
              }}
            />
          </div>
        )}
        <div
          style={{
            flexGrow: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#333',
              paddingTop: '5px'
            }}
          >
            {heading}
          </div>

          <div
            style={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'start'
            }}
          >
            {/* Display Hours */}
            <div style={{ textAlign: 'center' }}>
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#ff3b3b'
                }}
              >
                {hours} :
              </div>
              <div style={{ fontSize: '10px', color: '#888' }}>H</div>
            </div>

            {/* Display Minutes */}
            <div style={{ textAlign: 'center' }}>
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#ff3b3b'
                }}
              >
                {minutes} :
              </div>
              <div style={{ fontSize: '10px', color: '#888' }}>M</div>
            </div>

            {/* Display Seconds */}
            <div style={{ textAlign: 'center' }}>
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#ff3b3b'
                }}
              >
                {seconds}
              </div>
              <div style={{ fontSize: '10px', color: '#888' }}>S</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimerCountDown

import React, { useState, useEffect, useRef } from 'react'
import SignalRService from '../../services/chat'
import { get, post, uploadDocument } from '../../services/api/apiService'
import { useQuery, useQueryClient } from 'react-query'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { Modal, Upload } from 'antd'
import moment from 'moment'
import './chat.scss'
import { Theme } from '../../Theme'
import { getTheFirstTwoLetterOfString, truncateText } from '../../utils/functions'
import permissionService from '../../services/permissions'
import CustomAlert from '../../components/common/CAlert'

interface Message {
  userId: number
  userName?: string
  message: string
  documentPath?: string
  date?: any
  messageByAdmin?: boolean
  isFile: boolean
  userType: string
  adminRoleId?: any
  adminRoleName?: string
  adminUserId?: any
  adminName?: string
  sendDateTime?: string
}

const formatMessageDate = (date: Date | string) => {
  const messageDate = new Date(date);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (messageDate.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (messageDate.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  }
  return moment(messageDate).format('MMMM D, YYYY');
};

const groupMessagesByDate = (messages: Message[]) => {
  const groups: { [key: string]: Message[] } = {};
  
  messages.forEach(message => {
    // const date = message.sendDateTime || message.date;
    const date = message.sendDateTime
  ? moment.utc(message.sendDateTime).local().toDate()
  : new Date(message.date);
    const dateKey = moment(date).format('YYYY-MM-DD');
    
    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(message);
  });
  
  return Object.entries(groups).map(([date, messages]) => ({
    date,
    messages
  }));
};

const Chat: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [selectedUserChat, setSelectedUserChat] = useState<{[key: string]: any}>({})
  const selectedUserChatRef = useRef<any>(null)
  const targetRef = useRef<HTMLDivElement>(null)
  const [messages, setMessages]: any = useState([])
  const [userDetails, setUserDetails] = useState<{[key: string]: any}>({})
  const [newMessage, setNewMessage] = useState('')
  const queryClient = useQueryClient()
  const [previewImg, setPreviewImg] = useState('')
  const [visible, setVisible] = useState(false)

  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const { data, error, isLoading } = useQuery(
    ['AdminHeader'],
    () => get('ChatMessage/AdminHeader', {}),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        console.log('Error in admin header', err)
      }
    }
  )

  const updateHeaderLocally = (newMessage: any,isFile:boolean) => {
    queryClient.setQueryData(['AdminHeader'], (oldData: any) => {
      if (!oldData) return oldData;
  
      const index = oldData.data.findIndex((user: any) => user.userId == newMessage.userId);
  
      if (index !== -1) {
        const updatedUser = {
          ...oldData.data[index],
          message: isFile?"Document":newMessage.message,
          count: oldData.data[index].count + 1,
        };
  
        oldData.data.splice(index, 1);
        oldData.data.unshift(updatedUser);
      }
  
      return {
        ...oldData,
        data: [...oldData.data],
      };
    });
  };

  const handleMessage = async (message: any, isFile: any) => {
    try {
      const obj: Message = {
        userId: selectedUserChat.userId,
        userName: selectedUserChat.userName,
        message: message,
        date: new Date(),
        messageByAdmin: true,
        isFile: isFile,
        userType: 'Admin',
        adminRoleId: userDetails.roleId,
        adminRoleName: userDetails.role,
        adminUserId: userDetails.id,
        adminName: userDetails.fullName
      }
      if (isFile) {
        obj['documentPath'] = message
      }
      setMessages((prev: any) => [...prev, obj])
      await SignalRService.sendMessage(obj)
    } catch (error) {
      console.error('Failed to upload image:', error)
    }
  }

  const onFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    let newFile = [...info.fileList]
    newFile = newFile.slice(-1)

    if (newFile[0] && !newFile[0].url && !newFile[0].preview) {
      const reader = new FileReader()
      reader.onload = async e => {
        newFile[0].preview = e.target!.result as string
      }
      reader.readAsDataURL(newFile[0].originFileObj!)
    }

    if (newFile.length > 0) {
      const file = newFile[0].originFileObj

      if (file) {
        const formData = new FormData()
        formData.append('document', file)

        try {
          const response = await uploadDocument('ChatMessage/Upload', formData)
          if (response.succeeded) {
            handleMessage(response.data, true)
          } else {
            setAlert({
              message: 'Unable to upload file, Please try any other file.',
              type: 'error',
              visible: true
            })
          }
        } catch (error) {
          setAlert({
            message: 'Unable to upload file, Please try any other file.',
            type: 'error',
            visible: true
          })
        }
      }
    }
  }

  const readChat = async (id: any) => {
    let chatCount = await post(`ChatMessage/AdminReadChat?UserId=${id}`, {})
    if (chatCount.succeeded) {
      queryClient.setQueryData(['AdminHeader'], (oldData: any) => {
        if (!oldData) return oldData

        const updatedData = oldData.data.map((user: any) =>
          user.userId === id ? { ...user, count: 0 } : user
        )

        return { ...oldData, data: updatedData }
      })
    }
  }

  const onSingleChatClick = async (item: any) => {
    readChat(item.userId)
    setSelectedUserChat(item)
    let chatMessages = await get(
      `ChatMessage/AdminGetAllChatByUserId?UserId=${item.userId}`,
      {}
    )
    setMessages(chatMessages.data.reverse())
    // scrollToBottom()
  }

  useEffect(() => {
  if (targetRef.current) {
    targetRef.current.scrollIntoView();
  }
}, [messages]);

  const scrollToBottom = () => {
    setTimeout(() => {
      targetRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }

  const initializeConnection = async () => {
    try {
      await SignalRService.buildConnection()
      await SignalRService.startConnection()
      SignalRService.handleReceiveMessage(newMessage => {
        if (newMessage.userId == selectedUserChatRef.current?.userId) {
          setMessages((prevMessages: any) => [...prevMessages, newMessage])
          scrollToBottom()
        } else {
          updateHeaderLocally(newMessage,false)
        }
      })

      SignalRService.handleReceiveFile(newMessage => {
        if (newMessage.userId == selectedUserChatRef.current?.userId) {
          setMessages((prevMessages: any) => [...prevMessages, newMessage])
          scrollToBottom()
        } else {
          updateHeaderLocally(newMessage,true)
        }
      })
    } catch (error) {
      console.error('Error during connection setup:', error)
    }
  }

  const fetchUserDetails = () => {
    get('AdminAccount/GetMyProfile', {}).then(response => {
      if (response.succeeded) {
        setUserDetails(response.data)
      } else {
        console.log('message', response)
      }
    })
  }

  useEffect(() => {
    selectedUserChatRef.current = selectedUserChat
  }, [selectedUserChat])

  useEffect(() => {
    fetchUserDetails()
    initializeConnection()
    return () => {
      SignalRService.terminateConnection()
    }
  }, [])

  const handleSendMessage = () => {
    setTimeout(() => {
      scrollToBottom()
    }, 500)

    if (newMessage.trim() === '') return
    handleMessage(newMessage, false)
    setNewMessage('')
  }

  const getCurrentLocalTime = (time: string) => {
    const localTime = moment.utc(time).local().format('hh:mm A')
    return localTime
  }

  const convertToAmPmFormat = (time: string) => {
    const formattedTime = moment(time, 'HH:mm:ss').format('hh:mm A')
    return formattedTime
  }
  const previewImage = (file: any) => {
    console.log('my file===>>>', file)
    setPreviewImg(file)
    setVisible(true)
  }

  const messageGroups = groupMessagesByDate(messages);

  return (
    <div className='chat-container'>
      <div className='chat-sidebar'>
        <h3 style={{ marginBottom: '30px' }}>
          <strong>Chat</strong>
        </h3>
        <div className='admin-header'>
          {data?.data.map((item: any, index: any) => (
            <div
              style={{
                backgroundColor: '#EFEFEF',
                padding:'10px',
                marginTop:'5px',
                marginBottom:'10px',
                cursor: 'pointer',
                border:
                  selectedUserChat.userId === item?.userId
                    ? '2px solid #3A2252'
                    : ''
              }}
              className='chat-user'
              onClick={() => onSingleChatClick(item)}
              key={item.id}
            >
              <div
                style={{ backgroundColor: '#3A2252', position: 'relative' }}
                className='user-icon'
              >
                {getTheFirstTwoLetterOfString(item?.userName)}
                {item.count > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      backgroundColor: '#FF4500',
                      color: 'white',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold'
                    }}
                    className='count-badge'
                  >
                    {item.count}
                  </div>
                )}
              </div>
              <div className='name-prev'>
                <p className='user-name'>{item.userName}</p>
                <p className='user-preview' style={{fontWeight: item?.count > 0 ? '700' : 'normal'}}>
                  {truncateText(item.message, 30)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='chat-messages'>
        <h3>Messages</h3>
        <hr />
        <div className='messages-list'>
          {messages.length > 0 ? (
            messageGroups.map((group, groupIndex) => (
              <div key={groupIndex} className="message-group">
                <div className="date-divider">
                  <span>{formatMessageDate(group.date)}</span>
                </div>
                {group.messages.map((msg: any, index: number) => (
                  <div
                    ref={index === group.messages.length - 1 ? targetRef : null}
                    key={index}
                    className={`message gap-2 ${
                      msg.messageByAdmin ? 'sent' : 'received'
                    }`}
                  >
                    <div className='chat-user'>
                      <div
                        className={`user-icon gap-2 ${
                          msg.messageByAdmin ? 'sent-message' : 'received-message'
                        }`}
                      >
                        {getTheFirstTwoLetterOfString(
                          msg.messageByAdmin ? userDetails.fullName : msg?.userName
                        )}
                      </div>
                    </div>
                    <div className='message-content'>
                      {msg.documentPath ? (
                          <img
                          onClick={()=>{previewImage(
                            process.env.REACT_APP_S3_BUCKET_URL + msg.documentPath
                          )}}
                          style={{
                            cursor:'pointer',
                            marginBottom: '13px',
                            width:'200px',
                            height:'auto'
                          }}
                          src={process.env.REACT_APP_S3_BUCKET_URL + msg.documentPath}
                          alt="Attachment"
                        />
                      ) : (
                        <p style={{marginBottom:'0px'}}>{msg.message}</p>
                      )}
                      <div className={msg.documentPath ? 'img-msg-time' : 'message-time'}>
                        {msg?.sendDateTime
                          ? getCurrentLocalTime(msg?.sendDateTime)
                          : convertToAmPmFormat(msg?.date)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p
              style={{
                height: '90%',
                alignContent: 'center',
                textAlign: 'center',
                fontSize: '25px',
                color: 'grey'
              }}
            >
              Tap to open Chat
            </p>
          )}
        </div>
        {selectedUserChat?.userId &&
          permissionService.getRoleMenuPermissions('Chats')?.add && (
            <div className='message-input'>
              <div className='attach-btn'>
                <Upload
                  accept='.jpg,.png'
                  fileList={fileList}
                  onChange={onFileChange}
                  beforeUpload={() => false}
                  showUploadList={false}
                >
                  <img
                    width={'20px'}
                    height={'20px'}
                    src={Theme.icons.icn_add_btn}
                    alt=''
                  />
                </Upload>
              </div>

              <input
                type='text'
                placeholder='Write message here...'
                value={newMessage}
                onChange={e => setNewMessage(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && handleSendMessage()}
              />
              <button className='send-btn' onClick={handleSendMessage}>
                Send
              </button>
            </div>
          )}
      </div>
      <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
        <img src={previewImg} style={{ width: '100%' }} />
      </Modal>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  )
}

export default Chat
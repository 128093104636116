import React from 'react'
import { Table, Checkbox } from 'antd'
import { MenuItem } from '../../utils/permission'

interface PermissionsTableProps {
  permissionsData: MenuItem[]
  setPermissionsData: React.Dispatch<React.SetStateAction<MenuItem[]>>
}

interface TableRecord extends MenuItem {
  key: string
  menuId: string
  subMenuId?: string
  isParent: boolean
  children?: TableRecord[]
  // Add explicit typing for the dynamic fields
  fnAdd: boolean
  fnEdit: boolean
  fnRead: boolean
  fnAcceptReject: boolean
  fnEnableDisabled: boolean
  fnExport: boolean
}

type PermissionField =
  | 'fnAdd'
  | 'fnEdit'
  | 'fnRead'
  | 'fnAcceptReject'
  | 'fnEnableDisabled'
  | 'fnExport'
type MenuPermissionField =
  | 'fnMenuAdd'
  | 'fnMenuEdit'
  | 'fnMenuRead'
  | 'fnMenuAcceptReject'
  | 'fnMenuEnableDisabled'
  | 'fnMenuExport'

const permissionsHeadings = [
  'Pages',
  'Add',
  'Edit',
  'Read',
  'Accept / Reject',
  'Enabled / Disabled',
  'Download'
]

const PermissionsTable: React.FC<PermissionsTableProps> = ({
  permissionsData,
  setPermissionsData
}) => {
  const handleCheckboxChange = (
    menuId: string,
    subMenuId: string | null,
    field: PermissionField,
    checked: boolean
  ) => {
    setPermissionsData(prevData =>
      prevData.map(menuItem => {
        if (menuItem.id === menuId) {
          if (subMenuId) {
            return {
              ...menuItem,
              subMenuList: menuItem.subMenuList.map(subItem =>
                subItem.id === subMenuId
                  ? { ...subItem, [field]: checked }
                  : subItem
              )
            }
          } else {
            return { ...menuItem, [field]: checked }
          }
        }
        return menuItem
      })
    )
  }

  const permissionMappings: Array<{
    field: PermissionField
    headingIndex: number
    value: MenuPermissionField
  }> = [
    { field: 'fnAdd', headingIndex: 1, value: 'fnMenuAdd' },
    { field: 'fnEdit', headingIndex: 2, value: 'fnMenuEdit' },
    { field: 'fnRead', headingIndex: 3, value: 'fnMenuRead' },
    { field: 'fnAcceptReject', headingIndex: 4, value: 'fnMenuAcceptReject' },
    {
      field: 'fnEnableDisabled',
      headingIndex: 5,
      value: 'fnMenuEnableDisabled'
    },
    { field: 'fnExport', headingIndex: 6, value: 'fnMenuExport' }
  ]

  const columns = [
    {
      title: permissionsHeadings[0],
      dataIndex: 'menuTitle',
      key: 'menuTitle',
      render: (text: string) => <strong>{text}</strong>
    },
    ...permissionMappings.map(({ field, headingIndex, value }) => ({
      title: permissionsHeadings[headingIndex],
      dataIndex: field,
      key: field,
      render: (checked: boolean, record: any) =>
        record.isParent && record.children ? null : (
          <>
            {record[value] && (
              <Checkbox
                className='custom-checkbox'
                checked={checked}
                onChange={e =>
                  handleCheckboxChange(
                    record.menuId,
                    record.subMenuId || null,
                    field,
                    e.target.checked
                  )
                }
              />
            )}
          </>
        )
    }))
  ]

  const getTableData = (): TableRecord[] =>
    permissionsData.map(menuItem => {
      const hasChildren =
        menuItem.subMenuList && menuItem.subMenuList.length > 0
      const parentRecord: TableRecord = {
        ...menuItem,
        key: `menu-${menuItem.id}`,
        menuId: menuItem.id,
        isParent: true,
        // Map the permission fields
        fnAdd: menuItem.fnAdd,
        fnEdit: menuItem.fnEdit,
        fnRead: menuItem.fnRead,
        fnAcceptReject: menuItem.fnAcceptReject,
        fnEnableDisabled: menuItem.fnEnableDisabled,
        fnExport: menuItem.fnExport
      }

      if (hasChildren) {
        parentRecord.children = menuItem.subMenuList.map(
          (subItem): TableRecord => ({
            ...subItem,
            key: `submenu-${subItem.id}`,
            menuId: menuItem.id,
            subMenuId: subItem.id,
            isParent: false,
            // Map the permission fields for submenu items
            fnAdd: subItem.fnAdd,
            fnEdit: subItem.fnEdit,
            fnRead: subItem.fnRead,
            fnAcceptReject: subItem.fnAcceptReject,
            fnEnableDisabled: subItem.fnEnableDisabled,
            fnExport: subItem.fnExport
          })
        )
      }

      return parentRecord
    })

  return (
    <Table
      columns={columns}
      dataSource={getTableData()}
      pagination={false}
      bordered
      expandable={{
        expandRowByClick: true,
        rowExpandable: (record: TableRecord): boolean =>
          record.isParent && Boolean(record.children?.length)
      }}
      rowClassName={(record: TableRecord) =>
        record.isParent && record.children ? 'parent-row-highlight' : ''
      }
    />
  )
}

export default PermissionsTable

import * as SignalR from '@microsoft/signalr'

interface Message {
  userId: number
  userName?: string
  message: string
  date?: string
  messageByAdmin?: boolean
  isFile: boolean
  userType: string
  adminRoleId?: any
  adminRoleName? : string
  adminUserId? : any
  adminName ?: string
  documentPath?:string
  sendDateTime?:string
}

class SignalRService {
  private static instance: SignalRService
  private connection: SignalR.HubConnection | null = null
  private token: string | null = null
  userDetails: any

  constructor () {
    // console.log('Constructor called---------------')
    if (SignalRService.instance) {
      return SignalRService.instance
    }
    this.connection = null
    this.token = null
    SignalRService.instance = this
  }

  // const [userDetails, setUserDetails] = useState<{ [key: string]: any }>({})

  public static getInstance(): SignalRService {
    // console.log("getInstance called---------------------");

    if (!SignalRService.instance) {
      SignalRService.instance = new SignalRService();
    }
    return SignalRService.instance;
  }

  private getAuthToken (): string | null {
    const accessToken = localStorage.getItem('authToken')
    if (!accessToken) {
      console.error('Token not available.')
    }
    return accessToken
  }

  private async createConnection () {
    // console.log('Called create connection --------------')

    const accessToken = this.getAuthToken();
      
    if (!accessToken) throw new Error('Token not available.')
    // console.log('accessToken', accessToken)

    return new SignalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_CHAT_HUB_URL}/livechat`, {
        transport: SignalR.HttpTransportType.LongPolling,
        accessTokenFactory: async () => `${accessToken}`
        // withCredentials:true,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      })
      .configureLogging(SignalR.LogLevel.Trace)
      .withAutomaticReconnect()
      .build()
  }

  public async buildConnection (): Promise<SignalR.HubConnection> {
    if (this.connection) return this.connection

    this.connection = await this.createConnection()
     this.registerConnectionHandlers();
    return this.connection
  }

  public async startConnection (): Promise<void> {
    // console.log('Called startConnection --------------')

    if (!this.connection){
      console.log('Connection not built.')
      return
    }

    if (this.connection.state === SignalR.HubConnectionState.Connected) {
      console.log('Connection is already established..')
      return
    }

    if (this.connection.state === SignalR.HubConnectionState.Connecting) {
      console.log(
        "Connection is in the 'connecting' state, waiting for it to be established."
      )
      return
    }

    try {
      // console.log('this.connection.state', this.connection.state)

      await this.connection.start()
      console.log(
        'Socket connection started successfully.and state is-  ---------------',
        this.connection.state
      )
      // .then(connect=>{
      //   console.log("Socket connection started successfully.and state is", connect);
      // }).catch(error =>{
      //   console.error("Error starting connection inside promise------:", error);
      // })
    } catch (error) {
      console.error('Error starting connection:', error)
      throw error
    }
  }

  public async terminateConnection (): Promise<void> {
    if (this.connection) {
      try {
        await this.connection.stop()
        console.log('Socket connection stopped successfully.')
        this.connection = null
        this.token = null
      } catch (error) {
        console.error('Error stopping SignalR connection:', error)
      }
    }
  }

  public async sendMessage (
    message: Message
  ): Promise<{ success: boolean; message?: string; error?: any }> {
    if (
      !this.connection ||
      this.connection.state !== SignalR.HubConnectionState.Connected
    ) {
      return { success: false, error: new Error('Connection not established.') }
    }

    try {
      console.log('message befor sent ------', message)

      this.connection.invoke('SendMessageToUser', message)
      // console.log("response after sent ------",response);

      return { success: true, message: 'Message sent successfully.' }
    } catch (error) {
      console.error('Error sending message:', error)
      return { success: false, error }
    }
  }

  public handleReceiveMessage (callback: (newMessage: Message) => void): void {
    if (
      !this.connection ||
      this.connection.state !== SignalR.HubConnectionState.Connected
    ) {
      throw new Error('Connection not established.')
    }

    this.connection.on(
      'AdminReceiveMessage',
      (userId,userName,userType,message,date) => {
        const newMessage: Message = {
          userId,
          messageByAdmin : false,
          message,
          date,
          isFile : false,
          userType,
          userName,
          sendDateTime:date
        }

        console.log('userId,userName,userType,message,date,g date----------', newMessage)

        callback(newMessage)
      }
    )
  }

  public handleReceiveFile (callback: (newMessage: Message) => void): void {
    if (
      !this.connection ||
      this.connection.state !== SignalR.HubConnectionState.Connected
    ) {
      throw new Error('Connection not established.')
    }

    this.connection.on(
      'AdminReceiveFile',
      (userId,userName,userType,message,date) => {
        console.log("userId,userName,userType,message,date",userId,userName,userType,message,date);
        
        const newMessage: Message = {
          userId,
          messageByAdmin : false,
          message,
          date,
          isFile : true,
          userType,
          userName,
          documentPath:message,
          sendDateTime:date
        }

        console.log('Admin recieve file----------', newMessage)

        callback(newMessage)
      }
    )
  }

  private registerConnectionHandlers (): void {
    if (!this.connection) return

    this.connection.onreconnecting(error => {
      console.warn('SignalR reconnecting:', error)
    })

    this.connection.onreconnected(connectionId => {
      console.log('SignalR reconnected:', connectionId)
    })
    this.connection?.onclose(error => {
      setTimeout(async() => await this.startConnection(), 2000);
      // console.log("On Connection Close ");

      console.error('Connection closed:', error)
    })

    this.connection.on('StateChanged', (oldState, newState) => {
      console.log(`SignalR state changed from ${oldState} to ${newState}`)
    })
  }
}

const signalRService = new SignalRService()
export default signalRService

import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  DatePicker,
  DatePickerProps,
  TimePicker,
  Checkbox,
  GetProp,
  Radio
} from 'antd'
import CInput from '../../components/common/CInput'
import { Theme } from '../../Theme'
import CustomSelect from '../../components/common/CSelect'
import Phone from '../../components/common/CPhone'
import CButton from '../../components/common/CButton'
import {
  createAdminUser,
  editAdminUser,
  getAdminUserRole,
  getCountries
} from '../../services/manageUser'
import { useMutation, useQuery } from 'react-query'
import CustomAlert from '../../components/common/CAlert'
import SuccessModal from '../../components/common/SuccessModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  getAllCancelationPolicy,
  getAllEvents,
  getAllTermsCondition,
  getCities,
  getSubCategoryList
} from '../../services/lov'
import MultiImageUploader from '../../components/common/MultiImageUploader'
import { cloneWith } from 'lodash'
import { RadioChangeEvent } from 'antd/lib'
import { adminServiceCreateUpdate } from '../../services/service'
import { RcFile } from 'antd/es/upload'
import dayjs from 'dayjs'
import moment from 'moment'

const AttendeesAccommodate = [
  { label: 'Less then 10', value: 'LessaThenTen' },
  { label: '10-20', value: 'TenToTwenty' },
  { label: '21-30', value: 'twentyOnetoThirty' },
  { label: '31-40', value: 'ThirtyOneToFourty' },
  { label: 'More than 50', value: 'MoreThen50' }
]

const PrefferdStyleForEvent = [
  { label: 'Formal', value: 'Formal' },
  { label: 'Semi-Formal', value: 'SemiFormal' },
  { label: 'Casual', value: 'Casual' },
  { label: 'Themed', value: 'Themed' }
]

const PrefferdStaffService = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Both', value: 'Both' }
]

const EventTimeOfDay = [
  { label: 'Morning', value: 'Morning' },
  { label: 'After Noon', value: 'AfterNoon' },
  { label: 'Evening', value: 'Evening' },
  { label: 'Late Night', value: 'LateNight' }
]

const EventExpectToLast = [
  { label: 'One To Two Hours', value: 'OneToTwoHours' },
  { label: 'Three To Four Hours', value: 'ThreeToFourHours' },
  { label: 'Five To Six Hours', value: 'FiveToSixHours' },
  { label: 'All Day', value: 'AllDay' }
]

const CreateService: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { TextArea } = Input
  const [isSuccess, setIsSuccess] = useState(false)
  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    undefined
  )
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [storeTime, setStoreTime] = useState({ start_time: '', end_time: '' })
  const [eventValue, setEventValue] = React.useState<string>('')
  const [serviceStaff, setServiceStaff] = React.useState<string>('')
  const [imageFile, setImageFile] = useState<File[] | null>([])
  const [termsHtml, setTermsHtml] = useState('')
  const [cancelationHtml, setCancelationHtml] = useState('')
  const onChangeEvent = (e: any) => {
    setEventValue(e.target.value)
  }

  const onChangeService = (e: any) => {
    setServiceStaff(e.target.value)
  }

  const handleFileSelect = (files: any[]) => {
    console.log('file select===>>>', files)
    setImageFile(files)
    let isNewFile = files.find(file=>!file.deleted)
    if(isNewFile){
      form.setFieldValue('images',files)
    }
    else{
      form.setFieldValue('images',null)
    }

  }

  const { id, vendorFreeLancerName, record,UserType } = location.state

  console.log(
    'id, vendorFreeLancerName, type ====>>>',
    id,
    vendorFreeLancerName,
    record
  )

  const { data, error, isLoading } = useQuery(
    'subCategoryList',
    getSubCategoryList
  )

  const {
    data: termsAndConditionData,
    error: countryError,
    isLoading: isCountryLoading
  } = useQuery('termsCondition', getAllTermsCondition)

  const {
    data: cancelationPolicyData,
    error: cancelationPolicyError,
    isLoading: cancelationPolicyLoading
  } = useQuery('cancelationPolicy', getAllCancelationPolicy)

  const {
    data: eventType,
    error: eventTypeError,
    isLoading: eventTypeLoading
  } = useQuery('EventType', getAllEvents)

  const {
    data: cities,
    error: citiesError,
    isLoading: citiesLoading
  } = useQuery('Cities', getCities)

  const cancelationPolicy = cancelationPolicyData?.data?.filter(
    (x: any) => x.type === 'Vendor'
  )

  console.log('cities===>>>>', cities)
  const onChangeCheckbox: GetProp<
    typeof Checkbox.Group,
    'onChange'
  > = checkedValues => {
    console.log('checked = ', checkedValues)
  }

  form.setFieldsValue({
    // images: record ? record?.serviceImages : imageFile,
    termandCondition: termsHtml,
    privacyPolicy: cancelationHtml,
    currencyCode: 'SAR'
  })
//   useEffect(()=>{
// // if(imageFile){
//   console.log("imageFile--------------",imageFile)

//   form.setFieldValue('images',imageFile)
//   console.log("images--------------",form.getFieldValue('images'))

// // }
//   },[imageFile])

  useEffect(() => {
    if (record !== undefined) {
      console.log('record data', record)
      const { serviceDate, ...newObj } = record
      form.setFieldsValue(newObj)
      
      form.setFieldsValue(
        {
        serviceDate: dayjs(record?.serviceDate),
        images: record ? record?.serviceImages : imageFile,
       })
    }
  }, [])

  useEffect(() => {
    console.log("termsAndConditionData?.data[0]?.description--------",termsAndConditionData?.data[0]?.description);
    
    if (termsAndConditionData != undefined) {
      setTermsHtml(termsAndConditionData?.data[0]?.description)
    }
    if (cancelationPolicy != undefined) {
      setCancelationHtml(cancelationPolicy[0]?.description)
    }
  }, [termsAndConditionData, cancelationPolicy])

  const { mutate, isLoading: submitLoading } = useMutation(
    adminServiceCreateUpdate,
    {
      onSuccess: response => {
        if (response.succeeded) {
          setIsSuccess(true)
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )

  const handleSave = (values: any) => {
    values.serviceDate = moment(new Date(values.serviceDate)).format('YYYY-MM-DD')
    console.log('vvalues==+>>>', values)
    const obj = {
      ...values,
      currencyId: 1,
      vendorFreelancerId: record ? record?.vendorFreelancerId : id
    }

    const { images, ...newObj } = obj

    const formData = new FormData()

    if (record !== undefined) {
      const finalObj = {
        ...newObj,
        id,
        serviceImages: images.filter((file: any) => !file.preview)
      }
      const binaryFile = imageFile?.filter((file: any) => file.preview)
      console.log('binary File==>>>', binaryFile, finalObj)
      binaryFile?.forEach((file: any) => {
        formData.append('ServiceImages', file?.newFile as RcFile)
      })
      formData.append('obj', JSON.stringify(finalObj))
    } else {
      images.forEach((file: any) => {
        formData.append('ServiceImages', file?.newFile as RcFile)
      })
      delete obj.images;

      formData.append('obj', JSON.stringify(obj))
    }
    mutate(formData)
  }

  const getData = (val: any) => {
    if (!val) return [] // Return an empty array if data is undefined
    return val.data.map((x: any) => ({
      value: x.id,
      label: x.name ? x.name : x.cityName
    }))
  }

  const getTimeInHour=()=>{
    let timeArray:any[]=[]
    for (let index = 0; index < 24; index++) {
      let obj={value:index+1,label:index+1}
      timeArray.push(obj)
    }
    return timeArray
  }

  const onTimeChange: (
    time: any,
    timeString: string | string[],
    type: 'start_time' | 'end_time'
  ) => void = (time, timeString, type) => {
    console.log(time, timeString)

    const selectedTime = Array.isArray(timeString)
      ? timeString.join(', ')
      : timeString
    if (type === 'start_time') {
      setStoreTime({ ...storeTime, start_time: selectedTime })
    } else {
      setStoreTime({ ...storeTime, end_time: selectedTime })
    }
    form.setFieldsValue({
      [type === 'start_time' ? 'serviceStartTime' : 'serviceEndTime']:
        selectedTime
    })
  }

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log('Selected value:', e.target.value)
  }

  function getDisabledHours () {
    const parsedTime = moment(storeTime?.start_time, 'h:mm A')
    var hours = []
    for (let i = 0; i < parsedTime.hour() + 1; i++) {
      hours.push(i)
    }
    return hours
  }

  return (
    <>
      {/* <LoadingView isVisible={isLoading}/> */}
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{vendorFreeLancerName}</p>
        </div>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSave}
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <p className='user-name'>
            {record ? 'Edit Service' : `Create New Service`}
          </p>
          {/* {isLoading || isCountryLoading ? (
                        <div className='d-flex align-items-center justify-content-center' style={{ height: '200px' }} >
                            <Spin />
                        </div>
                    ) : ( */}
          <>
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-4'>
                <Form.Item
                  name='serviceName'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the service name')
                    }
                  ]}
                >
                  <CInput
                    label={t('Service Name')}
                    placeholder={t('Enter Service Name')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceNameAr'
                  rules={[
                    {
                      required: true,
                      message: t('الرجاء إدخال اسم الخدمة')
                    }
                  ]}
                  className='dir-rtl'
                >
                  <CInput
                    label={t('اسم الخدمة')}
                    placeholder={t('أدخل اسم الخدمة')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='categoryId'
                  rules={[
                    {
                      required: true,
                      message: t('Please choose a sub category')
                    }
                  ]}
                >
                  <CustomSelect
                    label={t('Sub Category')}
                    options={getData(data)}
                    value={selectedRole}
                    onChange={value => setSelectedRole(value)}
                    placeholder={t('Choose Sub Category')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='eventTypeId'
                  rules={[
                    { required: true, message: t('Please choose a event type') }
                  ]}
                >
                  <CustomSelect
                    label={t('Event Type')}
                    options={getData(eventType)}
                    value={selectedRole}
                    onChange={value => setSelectedRole(value)}
                    placeholder={t('Select Event Type')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='price'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the price'),
                      // validator: (_, value) => {
                      //   if (value=='' || /^[0-9]*$/.test(value)) {
                      //     return Promise.resolve()
                      //   } else {
                      //     return Promise.reject(t('price_error'))
                      //   }
                      // }
                    }
                  ]}
                >
                  <CInput
                    type='number'
                    onChange={(val)=>{if(Number(val.target.value)<0){form.setFieldValue('price',0)}}}
                    label={t('Price')}
                    placeholder={t('Enter Price')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='currencyCode'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a currency')
                    }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Currency')}
                    placeholder={t('Enter Currency')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Service Date')}
                </label>
                <Form.Item
                  name='serviceDate'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a event date')
                    }
                  ]}
                >
                  <DatePicker
                    className='inputfield w-100'
                    disabledDate={current =>
                      current && current.isBefore(dayjs().startOf('day'))
                    }
                  />
                </Form.Item>
              </div>
              {/* <div className='col-lg-4'>
                <Form.Item
                  name='serviceStartTime'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the start time')
                    }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Service Start Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    defaultValue={
                      record?.serviceStartTime
                        ? dayjs(record?.serviceStartTime, 'h:mm A')
                        : null
                    }
                    use12Hours
                    format='h:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'start_time')
                    }
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='serviceEndTime'
                  rules={[
                    { required: true, message: t('Please enter the end time') }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Service End Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    disabledTime={() => ({
                      disabledHours: getDisabledHours
                    })}
                    defaultValue={
                      record?.serviceEndTime
                        ? dayjs(record?.serviceEndTime, 'h:mm A')
                        : null
                    }
                    use12Hours
                    format='h:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'end_time')
                    }
                  />
                </Form.Item>
              </div> */}

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceDurationInHour'
                  rules={[
                    { required: true, message: t('Please Select Service Duration') }
                  ]}
                >
                  <CustomSelect
                    label={t('Service Duration (Hours)')}
                    options={getTimeInHour()}
                    value={selectedRole}
                    onChange={value => setSelectedRole(value)}
                    placeholder={t('Select Service Duration')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='providerContact'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the provider contact')
                    }
                  ]}
                >
                  <CInput
                    label={t('Provider Contact')}
                    placeholder={t('Enter Provider Contact')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceLocation'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a service location')
                    }
                  ]}
                >
                  <CInput
                    label={t('Service Location')}
                    placeholder={t('Enter Location')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='serviceLocationAr'
                  rules={[
                    {
                      required: true,
                      message: t('الرجاء إدخال موقع الخدمة')
                    }
                  ]}
                  className='dir-rtl'
                >
                  <CInput
                    label={t('موقع الخدمة')}
                    placeholder={t('أدخل الموقع')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='cityId'
                  rules={[
                    { required: true, message: t('Please choose a city') }
                  ]}
                >
                  <CustomSelect
                    label={t('Service City')}
                    options={getData(cities)}
                    // value={selectedRole}
                    // onChange={(value) => setSelectedRole(value)}
                    placeholder={t('Select Service City')}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Terms & Conditions')}
                </label>
                <Form.Item
                  name='termandCondition'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the terms and conditions')
                    }
                  ]}
                >
                  <div
                    className='terms-html-content'
                    style={{ height: 200, overflowY: 'auto' }}
                    dangerouslySetInnerHTML={{
                      __html: termsHtml ? termsHtml : ''
                    }}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Cancelation Policy')}
                </label>
                <Form.Item
                  name='privacyPolicy'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the cancelation policy')
                    }
                  ]}
                >
                  <div
                    className='terms-html-content'
                    style={{ height: 200, overflowY: 'auto' }}
                    dangerouslySetInnerHTML={{
                      __html: cancelationHtml ? cancelationHtml : ''
                    }}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Feature')}
                </label>
                <Form.Item
                  name='features'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the features')
                    }
                  ]}
                >
                  <TextArea
                    showCount
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={600}
                    placeholder={t('Enter Feature')}
                    style={{ height: 200 }}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label dir-rtl'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('ميزة')}
                </label>
                <Form.Item
                  name='featuresAr'
                  rules={[
                    {
                      required: true,
                      message: t('الرجاء إدخال الميزات')
                    }
                  ]}
                  className='dir-rtl'
                >
                  <TextArea
                    showCount
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={600}
                    placeholder={t('أدخل الميزة')}
                    style={{ height: 200 }}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Description')}
                </label>
                <Form.Item
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the description')
                    }
                  ]}
                >
                  <TextArea
                    showCount
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={600}
                    placeholder='Write some description'
                    style={{ height: 200 }}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-6'>
                <label
                  className='inputfield-label dir-rtl'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('وصف')}
                </label>
                <Form.Item
                  name='descriptionAr'
                  rules={[
                    {
                      required: true,
                      message: t('الرجاء إدخال الوصف')
                    }
                  ]}
                  className='dir-rtl'
                >
                  <TextArea
                    showCount
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={600}
                    placeholder='اكتب بعض الوصف'
                    style={{ height: 200 }}
                  />
                </Form.Item>
              </div>
              <div>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t(`Service ${UserType==='Freelancer'?'Video &':''} Images`)}
                </label>
                <Form.Item
                  name='images'
                  rules={[
                    { required: true, message: t(`Please upload the ${UserType==='Freelancer'?'video /':''} images.`) }
                  ]}
                >
                  <MultiImageUploader
                    type={UserType==='Freelancer'?'video/images':'images'}
                    setAlert={setAlert}
                    storeImages={record !== undefined && record?.serviceImages}
                    onFileSelected={handleFileSelect}
                    draggerWidth='40%'
                    widthClass='col-lg-5'
                  />
                </Form.Item>
              </div>

              <p className='inputfield-label mt-3'>
                {t('Additional Information Required')}
              </p>
              <div>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('How many attendees can you accommodate?')}
                </label>
                <Form.Item
                  name='attendeesAccommodate'
                  rules={[
                    { required: true, message: t('Please select the option') }
                  ]}
                >
                  {/* <Checkbox.Group options={AttendeesAccommodate} defaultValue={['Pear']} onChange={onChangeCheckbox} /> */}
                  <Radio.Group
                    options={AttendeesAccommodate}
                    onChange={onChangeRadio}
                  />
                </Form.Item>
              </div>
              <div>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Which style(s) of events can your services accommodate?')}
                </label>
                <Form.Item
                  name='prefferdStyleForEvent'
                  rules={[
                    { required: true, message: t('Please select the option') }
                  ]}
                >
                  <Radio.Group onChange={onChangeEvent} value={eventValue}>
                    {PrefferdStyleForEvent.map(item => (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
              <div>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('What is the gender of your service provider?')}
                </label>
                <Form.Item
                  name='prefferdStaffService'
                  rules={[
                    { required: true, message: t('Please select the option') }
                  ]}
                >
                  <Radio.Group onChange={onChangeService} value={eventValue}>
                    {PrefferdStaffService.map(item => (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
              <div>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('At what time of day can your service be provided?')}
                </label>
                <Form.Item
                  name='eventTimeOfDay'
                  rules={[
                    { required: true, message: t('Please select the option') }
                  ]}
                >
                  <Radio.Group onChange={onChangeService} value={eventValue}>
                    {EventTimeOfDay.map(item => (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
              <div>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('What is the expected duration of your service?')}
                </label>
                <Form.Item
                  name='eventExpectToLast'
                  rules={[
                    { required: true, message: t('Please select the option') }
                  ]}
                >
                  <Radio.Group onChange={onChangeService} value={eventValue}>
                    {EventExpectToLast.map(item => (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            <Row gutter={8} style={{ marginTop: '15px' }}>
              <Col span={4}>
                <CButton
                  className='cancelBtn'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </CButton>
              </Col>
              <Col span={4}>
                <CButton
                  loading={submitLoading}
                  style={{ width: '100%' }}
                  htmlType='submit'
                >
                  Save
                </CButton>
              </Col>
            </Row>
          </>
        </Form>
        <SuccessModal
          visible={isSuccess}
          message={`Service has been ${
            record ? 'Updated' : 'Created'
          } Successfully`}
          onOk={() =>
            navigate(-1)
          }
          // record ? `/jive/viewService/${id}` : `/jive/servicesList/${id}`
        />
        <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
      </div>
    </>
  )
}

export default CreateService

import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Theme } from '../../Theme'
import CButton from '../../components/common/CButton'
import AlertModal from '../../components/common/AlertModal'
import CustomAlert from '../../components/common/CAlert'
import {
  approvedVendorPackage,
  getAdminPackageById
} from '../../services/service'
import Status from '../../components/common/Status'
import permissionService from '../../services/permissions'

const ViewPackage: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  console.log('params', params)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [packageDetails, setPackageDetails] = useState<{ [key: string]: any }>(
    {}
  )
  const [serviceDetails, setServiceDetails] = useState<{ [key: string]: any }>(
    {}
  )

  const { data, isLoading, error, refetch } = useQuery(
    ['packageById', params?.id],
    () => getAdminPackageById({ Id: params?.id })
  )

  useEffect(() => {
    if (data) {
      console.log('Complete details', data.data)
      let dict = {
        categoryName: data.data.categoryName,
        vendorName: data.data.vendorName,
        serviceName: data.data.serviceName,
        id: data.data.serviceId,
        vendorFreelancerId: data.data.vendorId
      }

      setServiceDetails(dict)
      setPackageDetails(data?.data)
    }
  }, [data])

  const { mutate, isLoading: submitLoading } = useMutation(
    approvedVendorPackage,
    {
      onSuccess: response => {
        if (response.succeeded) {
          setIsModalVisible(false)
          refetch()
          setAlert({
            message: response.message,
            type: 'success',
            visible: true
          })
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )
  const handleModalOk = async () => {
    mutate({ PackageId: packageDetails?.id })
  }
  const callRequest = () => {
    setIsModalVisible(true)
  }
  const handleModalCancel = () => {
    setIsModalVisible(false)
  }
  const truncateContent = (file_name: any, maxLength: any) => {
    if (!file_name) {
      return 'no file name'
    } else if (file_name.length <= maxLength) {
      return file_name
    }
    return `${file_name.slice(0, maxLength)}...`
  }
  const [countdown, setCountdown] = useState(1); // Set an initial countdown (in seconds)
  
    useEffect(() => {
      if (countdown > 0) {
        const timer = setTimeout(() => setCountdown(countdown - 1), 700);
        return () => clearTimeout(timer); // Cleanup on unmount or count change
      }
    }, []);
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <img
              src={Theme.icons.icn_arrow_back}
              alt='icn_user_add'
              className='cursor-pointer'
              style={{ height: '20px' }}
              onClick={() => navigate(-1)}
            />
            <p className='list-heading m-0 mx-2'>{'Package Details'}</p>
          </div>
          {(packageDetails?.status == 'Pending' &&  permissionService.getRoleMenuPermissions('Services & Packages')?.acceptReject) && (
            <div className='d-flex align-items-center justify-content-end '>
              <CButton
                style={{
                  backgroundColor: Theme.colors.success,
                  borderColor: Theme.colors.success,
                  width: '100%'
                }}
                onClick={() => callRequest()}
              >
                Approve
              </CButton>
            </div>
          )}
        </div>

        <div
          className='mt-3'
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <p className='user-name m-0'>{t('Package Details')}</p>
           {  permissionService.getRoleMenuPermissions('Services & Packages')?.update &&
            <CButton
              className='btn-unfilled h-35'
              onClick={() =>
                navigate('/jive/createUpdatePackage', {
                  state: {
                    serviceDetails: serviceDetails,
                    record: packageDetails,
                    formType: 'update'
                  }
                })
              }
            >
              <img
                src={Theme.icons.icn_edit}
                alt='icn_user_add'
                style={{ height: '15px' }}
              />
              Edit
            </CButton>}
          </div>
          <div
            style={{
              backgroundColor: '#F3F3F3',
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <div className='row mt-4'>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Service Name')}</p>
                <p className='detail-value'>{packageDetails?.serviceName}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Package Name')}</p>
                <p className='detail-value'>{packageDetails?.packageName}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('اسم الحزمة')}</p>
                <p className='detail-value'>{packageDetails?.packageNameAr?packageDetails?.packageNameAr:'-'}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Vendor/Freelancer Name')}</p>
                <p className='detail-value'>{packageDetails?.vendorName}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Contact Provider')}</p>
                <p className='detail-value'>
                  {packageDetails?.providerContact}
                </p>
              </div>

              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Category')}</p>
                <p className='detail-value'>{packageDetails?.categoryName}</p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Package Price')}</p>
                <p className='detail-value'>{`SAR ${packageDetails?.packagePrice}`}</p>
              </div>
              {/* <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Start Time')}</p>
                <p className='detail-value'>
                  {packageDetails?.packageStartTime}
                </p>
              </div> */}
              {/* <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('End Time')}</p>
                <p className='detail-value'>{packageDetails?.packageEndTime}</p>
              </div> */}
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Package Duration (Hours)')}</p>
                <p className='detail-value'>{packageDetails?.packageDurationInHour ? packageDetails?.packageDurationInHour: '-'}</p>
              </div>
              <div className='col-lg-6'>
                <p className='detail-label m-0'>{t('Package Location')}</p>
                <p className='detail-value'>
                  {packageDetails?.serviceLocation}
                </p>
              </div>
              <div className='col-lg-2'>
                <p className='detail-label m-0'>{t('Package Status')}</p>
                <p className='detail-value mt-1'>
                  {<Status status={packageDetails?.status} />}
                </p>
              </div>
              <div className='col-lg-8'>
                <p className='detail-label m-0'>{t('Features')}</p>
                <p className='detail-value'>{packageDetails?.feature}</p>
              </div>
              <div className='col-lg-8'>
                <p className='detail-label m-0'>{t('Descriptions')}</p>
                <p className='detail-value'>{packageDetails?.description}</p>
              </div>
              <div className='col-lg-12 dir-rtl'>
                <p className='detail-label m-0'>{t('Features (ar)')}</p>
                <p className='detail-value'>{packageDetails?.featureAr ? packageDetails?.featureAr : '-'}</p>
              </div>
              <div className='col-lg-12 dir-rtl'>
                <p className='detail-label m-0'>{t('Descriptions (ar)')}</p>
                <p className='detail-value'>
                  {packageDetails?.descriptionAr ? packageDetails?.descriptionAr : '-'}
                </p>
              </div>
            </div>
          </div>

          <div
            className='mt-3'
            style={{
              backgroundColor: '#F3F3F3',
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name m-0'>{t('Package Images')}</p>
            <div className='row'>
              {packageDetails?.packageImages?.map(
                (file: any, index: number) => (
                  <div className='col-lg-4' key={index}>
                    <div className='d-flex align-items-center p-3 preview-image-view-container mt-3'>
                      <div className='d-flex align-items-center'>
                        <img
                          src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                          alt={file.originalFileName}
                          className='category-icon mb-0'
                          style={{ width: '50px', height: '50px' }}
                        />
                        <div className='mx-2'>
                          <p
                            className='detail-label m-0'
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {truncateContent(file.originalFileName, 20)}
                          </p>
                          <p className='detail-label m-0'>
                            {(file.fileLength / 1024).toFixed(2)} KB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <AlertModal
        visible={isModalVisible}
        message={t(`Are you sure you want to Approve this Package?`)}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default ViewPackage

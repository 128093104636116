import { useState } from 'react'
import CustomAlert from './CAlert'
import CButton from './CButton'

interface LinkProps {
  icon?: string
  link?: string
  isbutton?: boolean
  btnText?: string
  heading: string
  subText?: string
}

const Link: React.FC<LinkProps> = ({
  icon,
  link,
  isbutton = false,
  btnText,
  heading,
  subText
}) => {
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const handleCopyLink = () => {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setAlert({
            message: 'Link copied to clipboard!',
            type: 'success',
            visible: true
          })
        })
        .catch(err => {
          console.error('Failed to copy link:', err)
          setAlert({
            message: 'Failed to copy link.',
            type: 'success',
            visible: true
          })
        })
    }
  }
  return (
    <>
      <div
        className='d-flex justify-content-between align-items-center mt-3 p-3'
        style={{ backgroundColor: '#EBE3F3', borderRadius: '8px' }}
      >
        <div className='d-flex align-items-center'>
          {/* Icon Section */}
          {icon && (
            <div
              className='p-3'
              style={{
                backgroundColor: 'white',
                borderRadius: '8px'
              }}
            >
              <img src={icon} alt='Icon' />
            </div>
          )}

          {/* Text Section */}
          <div className='mx-2'>
            <p className='m-0 detail-label'>{heading}</p>
            {link && (
              <p className='m-0'>
                <strong>Link:</strong> {link}
              </p>
            )}
            {subText && (
              <p
                className='m-0 detail-label'
                style={{ color: 'red' }} // Adjust color as needed
              >
                {subText}
              </p>
            )}
          </div>
        </div>

        {/* Button Section */}
        {isbutton && btnText && (
          <CButton
            className='cancelBtn h-35'
            style={{ width: '70px' }}
            onClick={handleCopyLink}
          >
            {btnText}
          </CButton>
        )}
      </div>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default Link

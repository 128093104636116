import React from 'react';

interface RatingProps {
  rating: number;
  onRatingChange: (newRating: number) => void;
}

const Rating: React.FC<RatingProps> = ({ rating, onRatingChange }) => {
  const totalStars = 5;

  const handleRating = (star: number) => {
    onRatingChange(star);
  };

  const starStyle = {
    cursor: 'pointer',
    fontSize: '26px',
    transition: 'color 0.3s',
    color: '#d3d3d3',
    lineHeight: '0.7',  // Ensures stars don't add extra height
    display: 'inline-block',  // Prevents unwanted block-level spacing
    paddingBottom: '2px',
  };

  const filledStarStyle = {
    ...starStyle,
    color: '#F29E0F',
  };

  return (
    <div className='m-0' style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
      {Array.from({ length: totalStars }, (_, i) => (
        <div
          key={i}
          style={i < rating ? filledStarStyle : starStyle}
          onClick={() => handleRating(i + 1)}
        >
          ★
        </div>
      ))}
    </div>
  );
};

export default Rating;

import { Col, Form, Input, Row, TimePicker } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { createAdminPackage, getAdminPackageById } from '../../services/service'
import {
  getAllCancelationPolicy,
  getAllTermsCondition
} from '../../services/lov'
import { Theme } from '../../Theme'
import CInput from '../../components/common/CInput'
import CButton from '../../components/common/CButton'
import dayjs from 'dayjs'
import MultiImageUploader from '../../components/common/MultiImageUploader'
import SuccessModal from '../../components/common/SuccessModal'
import CustomAlert from '../../components/common/CAlert'
import { RcFile } from 'antd/es/upload'
import moment from 'moment'
import CustomSelect from '../../components/common/CSelect'

const CreateUpdatePackage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const params = useParams()
  const { TextArea } = Input
  const [isSuccess, setIsSuccess] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [imageFile, setImageFile] = useState<File[] | null>([])
  const [storeTime, setStoreTime] = useState({start_time:'', end_time:''});

  const [packageDetails, setPackageDetails] = useState<{ [key: string]: any }>(
    {}
  )
 const [selectedRole, setSelectedRole] = useState<string | undefined>(
    undefined
  )
  const [termsHtml, setTermsHtml] = useState('')
  const [cancelationHtml, setCancelationHtml] = useState('')
  const [service, setService] = useState<{ [key: string]: any }>({})
  //   const { data, isLoading, error, refetch } = useQuery(
  //     ['packageById', params?.id],
  //     () => getAdminPackageById({ Id: params?.id })
  //   )
  const handleFileSelect = (files: any[]) => {
    console.log('file select===>>>', files)
    setImageFile(files)
    let isNewFile = files.find(file=>!file.deleted)
    if(isNewFile){
      form.setFieldValue('images',files)
    }
    else{
      form.setFieldValue('images',null)
    }
  }

  const getTimeInHour=()=>{
    let timeArray:any[]=[]
    for (let index = 0; index < 24; index++) {
      let obj={value:index+1,label:index+1}
      timeArray.push(obj)
    }
    return timeArray
  }

  const {
    data: termsAndConditionData,
    error: countryError,
    isLoading: isCountryLoading
  } = useQuery('termsCondition', getAllTermsCondition)

  const {
    data: cancelationPolicyData,
    error: cancelationPolicyError,
    isLoading: cancelationPolicyLoading
  } = useQuery('cancelationPolicy', getAllCancelationPolicy)

  const cancelationPolicy = cancelationPolicyData?.data?.filter(
    (x: any) => x.type === 'Vendor'
  )

  useEffect(() => {
    if (termsAndConditionData != undefined) {
      setTermsHtml(termsAndConditionData?.data[0]?.description)
    }
    if (cancelationPolicy != undefined) {
      setCancelationHtml(cancelationPolicy[0]?.description)
    }
  }, [termsAndConditionData, cancelationPolicy])

  const onTimeChange: (time: any, timeString: string | string[], type: 'start_time' | 'end_time') => void = (time, timeString, type) => {
    console.log(time, timeString);

    const selectedTime = Array.isArray(timeString) ? timeString.join(', ') : timeString;
    if(type === 'start_time'){
        setStoreTime({...storeTime, start_time: selectedTime});
    }else{
        setStoreTime({...storeTime, end_time: selectedTime});
    }
    form.setFieldsValue({
        [type === 'start_time' ? 'packageStartTime' : 'packageEndTime']: selectedTime,
    });
  };
  // form.setFieldsValue({
  //   images: record ? record?.packageImages : imageFile
    //     termandCondition: termsAndConditionData?.data[0]?.description,
    //     privacyPolicy: cancelationPolicy && cancelationPolicy[0]?.description,
    //     currencyCode: 'SAR'
  // })

  var { record, formType, serviceDetails } = location.state
  form.setFieldsValue({
    currencyCode: 'SAR',
    termandCondition: termsHtml,
    privacyPolicy: cancelationHtml,
    // images: record ? record?.packageImages : imageFile,
  })

   useEffect(() => {

    if (location.state) {
      console.log('location.state', location.state)
      let sd = location.state.serviceDetails
      let dict = {
        categoryName: sd.categoryName,
        vendorName: sd.vendorFreelancerName,
        serviceName: sd.serviceName,
        serviceLocation: sd.serviceLocation
      }
      form.setFieldsValue({
        ...dict
      })
      if (record != undefined) {
        form.setFieldsValue({ ...record, currencyCode: 'SAR' })
      }
      // setService(dict);
    }

    // const { record } = location.state

      if (record !== undefined) {        
        form.setFieldsValue(
          {
          images: record ? record?.packageImages : imageFile,
         })
      }
    }, [])

  const { mutate, isLoading: submitLoading } = useMutation(createAdminPackage, {
    onSuccess: response => {
      if (response.succeeded) {
        setIsSuccess(true)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error?.message || 'An error occurred. Please try again.',
        type: 'error',
        visible: true
      })
    }
  })

  const handleSave = (values: any) => {
    const obj = {
      ...values,
      serviceId: serviceDetails.id,
      vendorId: serviceDetails.vendorFreelancerId
      //   ...service
    }
    console.log("values----------**-----------",obj);
    
    const { images, ...newObj } = obj

    const formData = new FormData()

    if (record !== undefined) {
      const finalObj = {
        ...newObj,
        id: record.id,
        packageImages: images.filter((file: any) => !file.preview)
      }
      const binaryFile = imageFile?.filter((file: any) => file.preview)
      console.log('finalObj::==>>>', finalObj)
      binaryFile?.forEach((file: any) => {
        formData.append('packageImages', file?.newFile as RcFile)
      })
      formData.append('obj', JSON.stringify(finalObj))
    } else {
      images.forEach((file: any) => {
        formData.append('packageImages', file?.newFile as RcFile)
      })
      delete obj.images;
      formData.append('obj', JSON.stringify(obj))
    }
    mutate(formData)
  }

  function getDisabledHours() {
    const parsedTime = moment(storeTime?.start_time, 'h:mm A');
    var hours = [];
    for (let i = 0; i < parsedTime.hour()+1; i++) {
      hours.push(i);
    }
    return hours;
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2 user-name'>
            {formType == 'create' ? 'Create New Package' : 'Update Package'}
          </p>
        </div>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSave}
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <p className='user-name'>{`Package Information`}</p>
          <>
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-4'>
                <Form.Item
                  name='packageName'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the package name')
                    }
                  ]}
                >
                  <CInput
                    label={t('Package Name')}
                    placeholder={t('Enter Package Name')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='packageNameAr'
                  rules={[
                    {
                      required: true,
                      message: t('الرجاء إدخال اسم الحزمة')
                    }
                  ]}
                  className='dir-rtl'
                >
                  <CInput
                    label={t('اسم الحزمة')}
                    placeholder={t('أدخل اسم الحزمة')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='vendorName'
                  rules={[
                    { required: true, message: t('Please choose a vendor') }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Vendor/Freelancer Name')}
                    placeholder={t('Enter Vendor Name')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceName'
                  rules={[
                    { required: true, message: t('Please choose a service') }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Service Name')}
                    placeholder={t('Enter Service Name')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='categoryName'
                  rules={[
                    { required: true, message: t('Please choose a category') }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Category Name')}
                    placeholder={t('Enter Category Name')}
                    value={serviceDetails.categoryName}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='packagePrice'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the package price')
                    }
                  ]}
                >
                  <CInput
                    label={t('Package Price')}
                    placeholder={t('Enter Package Price')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='currencyCode'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a currency')
                    }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Currency')}
                    placeholder={t('Enter Currency')}
                  />
                </Form.Item>
              </div>

              {/* <div className='col-lg-4'>
                <Form.Item
                  name='packageStartTime'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the start time')
                    }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Package Start Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    defaultValue={
                      record?.packageStartTime
                        ? dayjs(record?.packageStartTime, 'h:mm A')
                        : null
                    }
                    use12Hours
                    format='h:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'start_time')
                    }
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='packageEndTime'
                  rules={[
                    { required: true, message: t('Please enter the end time') }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Package End Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    disabledTime={() => ({
                      disabledHours: getDisabledHours,
                    })} 
                    defaultValue={
                      record?.packageEndTime
                        ? dayjs(record?.packageEndTime, 'h:mm A')
                        : null
                    }
                    use12Hours
                    format='h:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'end_time')
                    }
                  />
                </Form.Item>
              </div> */}
              <div className='col-lg-4'>
                <Form.Item
                  name='packageDurationInHour'
                  rules={[
                    { required: true, message: t('Please Select Package Duration') }
                  ]}
                >
                  <CustomSelect
                    label={t('Package Duration (Hours)')}
                    options={getTimeInHour()}
                    // value={selectedRole}
                    // onChange={value => {console.log("Value Selected::",value);setSelectedRole(value)}}
                    value={form.getFieldValue('packageDurationInHour')}
                    onChange={value => form.setFieldValue('packageDurationInHour',value)}
                    placeholder={t('Select Package Duration')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='providerContact'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the provider contact')
                    }
                  ]}
                >
                  <CInput
                    label={t('Provider Contact')}
                    placeholder={t('Enter Provider Contact')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceLocation'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a package location')
                    }
                  ]}
                >
                  <CInput
                    disabled
                    label={t('Package Location')}
                    placeholder={t('Enter Location')}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='row' style={{ width: '90%' }}>
            <div className='col-lg-6'>
                              <label
                                className='inputfield-label'
                                style={{ display: 'block', marginBottom: '5px' }}
                              >
                                {t('Terms & Conditions')}
                              </label>
                              <Form.Item
                                name='termandCondition'
                                rules={[
                                  {
                                    required: true,
                                    message: t('Please enter the terms and conditions')
                                  }
                                ]}
                              >
                                <div
                                  className='terms-html-content'
                                  style={{ height: 200, overflowY: 'auto' }}
                                  dangerouslySetInnerHTML={{
                                    __html: termsHtml ? termsHtml : ''
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                              <label
                                className='inputfield-label'
                                style={{ display: 'block', marginBottom: '5px' }}
                              >
                                {t('Cancelation Policy')}
                              </label>
                              <Form.Item
                                name='privacyPolicy'
                                rules={[
                                  {
                                    required: true,
                                    message: t('Please enter the cancelation policy')
                                  }
                                ]}
                              >
                                <div
                                  className='terms-html-content'
                                  style={{ height: 200, overflowY: 'auto' }}
                                  dangerouslySetInnerHTML={{
                                    __html: cancelationHtml ? cancelationHtml : ''
                                  }}
                                />
                              </Form.Item>
                            </div>
                          <div className='col-lg-6'>
                              <label
                                className='inputfield-label'
                                style={{ display: 'block', marginBottom: '5px' }}
                              >
                                {t('Feature')}
                              </label>
                              <Form.Item
                                name='feature'
                                rules={[
                                  {
                                    required: true,
                                    message: t('Please enter the features')
                                  }
                                ]}
                              >
                                <TextArea
                                  showCount
                                  autoSize={{ minRows: 4, maxRows: 8 }}
                                  maxLength={600}
                                  placeholder={t('Enter Feature')}
                                  style={{ height: 200 }}
                                />
                              </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                              <label
                                className='inputfield-label dir-rtl'
                                style={{ display: 'block', marginBottom: '5px' }}
                              >
                                {t('ميزة')}
                              </label>
                              <Form.Item
                                name='featureAr'
                                rules={[
                                  {
                                    required: true,
                                    message: t('الرجاء إدخال الميزات')
                                  }
                                ]}
                                className='dir-rtl'
                              >
                                <TextArea
                                  showCount
                                  autoSize={{ minRows: 4, maxRows: 8 }}
                                  maxLength={600}
                                  placeholder={t('أدخل الميزة')}
                                  style={{ height: 200 }}
                                />
                              </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                              <label
                                className='inputfield-label'
                                style={{ display: 'block', marginBottom: '5px' }}
                              >
                                {t('Description')}
                              </label>
                              <Form.Item
                                name='description'
                                rules={[
                                  {
                                    required: true,
                                    message: t('Please enter the description')
                                  }
                                ]}
                              >
                                <TextArea
                                  showCount
                                  autoSize={{ minRows: 4, maxRows: 8 }}
                                  maxLength={600}
                                  placeholder='Write some description'
                                  style={{ height: 200 }}
                                />
                              </Form.Item>
                            </div>
              
                            <div className='col-lg-6'>
                              <label
                                className='inputfield-label dir-rtl'
                                style={{ display: 'block', marginBottom: '5px' }}
                              >
                                {t('وصف')}
                              </label>
                              <Form.Item
                                name='descriptionAr'
                                rules={[
                                  {
                                    required: true,
                                    message: t('الرجاء إدخال الوصف')
                                  }
                                ]}
                                className='dir-rtl'
                              >
                                <TextArea
                                  showCount
                                  autoSize={{ minRows: 4, maxRows: 8 }}
                                  maxLength={600}
                                  placeholder='اكتب بعض الوصف'
                                  style={{ height: 200 }}
                                />
                              </Form.Item>
                            </div>
            </div>
            
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Package Images')}
                </label>
                <Form.Item
                  name='images'
                  rules={[
                    { required: true, message: t('Please upload the images') }
                  ]}
                >
                  <MultiImageUploader
                    type='images'
                    setAlert={setAlert}
                    draggerWidth='100%'
                    storeImages={
                      record !== undefined && formType == 'create'
                        ? []
                        : record?.packageImages
                    }
                    onFileSelected={handleFileSelect}
                    widthClass='col-lg-12'
                  />
                </Form.Item>
              </div>
            </div>
            <Row gutter={8} style={{ marginTop: '15px' }}>
              <Col span={4}>
                <CButton className='cancelBtn' onClick={() => navigate(-1)}>
                  Cancel
                </CButton>
              </Col>
              <Col span={4}>
                <CButton
                  loading={submitLoading}
                  style={{ width: '100%' }}
                  htmlType='submit'
                >
                  Save
                </CButton>
              </Col>
            </Row>
          </>
        </Form>
        <SuccessModal
          visible={isSuccess}
          message={`${
            record && formType != 'create'
              ? 'Package has been updated successfully'
              : 'Package has been created successfully'
          }
          
          `}
          onOk={() => navigate(-1)}
        />
        <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
      </div>
    </>
  )
}

export default CreateUpdatePackage

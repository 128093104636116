import instance from "./api/api_instance";

export const adminServiceCreateUpdate = async (credentials: FormData) => {
    const response = await instance.post(
      "AdminService/CreateUpdate",
      credentials,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };
  
  export const getAllServices = async (credentials: object) => {
    const response = await instance.get("AdminService/GetAllServices", {
      params: credentials,
    });
    return response.data;
  };
  
  export const activeDeactiveService = async (credentials: object) => {
    const response = await instance.post('AdminService/ActiveDeactiveService', credentials);
    return response.data;
  };

  export const getAdminServiceById = async (credentials: object) => {
    const response = await instance.get("AdminService/GetById", {
      params: credentials,
    });
    return response.data;
  };

  export const approvedVendorService = async (credentials: object) => {
    const response = await instance.get("AdminService/Approved", {
      params: credentials,
    });
    return response.data;
  };
  
  export const activeDeactivePackage = async (credentials: object) => {
    const response = await instance.post('AdminPackage/ActiveDeactivePackage', credentials);
    return response.data;
  };

  export const getAllPackage = async (credentials: object) => {
    const response = await instance.get("AdminPackage/GetAllPackage", {
      params: credentials,
    });
    return response.data;
  }

  export const getAdminPackageById = async (credentials: object) => {
    const response = await instance.get("AdminPackage/GetById", {
      params: credentials,
    });
    return response.data;
  };

  export const approvedVendorPackage = async (credentials: object) => {
    const response = await instance.get("AdminPackage/Approved", {
      params: credentials,
    });
    return response.data;
  };

  export const createAdminPackage = async (credentials: FormData) => {
    const response = await instance.post(
      "AdminPackage/CreateUpdate",
      credentials,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };

  export const getPromoCodeById = async (credentials: object) => {
    const response = await instance.get("AdminPromoCode/GetById", {
      params: credentials,
    });
    return response.data;
  };

  export const updateStatusPromoCode = async (credentials: object) => {
    const response = await instance.post("AdminPromoCode/ActiveDeactivePromoCode", credentials);
    return response.data;
  };
export interface Permission {
    menuId: string;
    roleId: string;
    claimType: string;
    claimValue: string;
    fnAdd: boolean;
    fnRead: boolean;
    fnEdit: boolean;
    fnDelete: boolean;
    fnPrint: boolean;
    fnExport: boolean;
    fnEnableDisabled: boolean;
    fnAcceptReject: boolean;
    parentId: string | null;
    id: number;
  }
  
  export interface MenuItem {
    id: string;
    menuTitle: string;
    subMenuList: MenuItem[];
    fnAdd: boolean;
    fnRead: boolean;
    fnEdit: boolean;
    fnDelete: boolean;
    fnPrint: boolean;
    fnExport: boolean;
    fnEnableDisabled: boolean;
    fnAcceptReject: boolean;
    parentId: string | null;
  }
  
  export interface AlertState {
    message: string;
    type: 'success' | 'error' | 'info' | 'warning';
    visible: boolean;
  }
  
  export const createPermissionObject = (item: MenuItem, roleId: string): Permission => ({
    menuId: item.id,
    roleId,
    claimType: "permission",
    claimValue: item.menuTitle,
    fnAdd: item.fnAdd,
    fnRead: item.fnRead,
    fnEdit: item.fnEdit,
    fnDelete: item.fnDelete,
    fnPrint: item.fnPrint,
    fnExport: item.fnExport,
    fnEnableDisabled: item.fnEnableDisabled,
    fnAcceptReject: item.fnAcceptReject,
    parentId: item.parentId,
    id: 0
  });
  
  export const hasActivePermissions = (permission: Permission): boolean => {
    return permission.fnAdd || 
           permission.fnRead || 
           permission.fnEdit || 
           permission.fnDelete || 
           permission.fnPrint || 
           permission.fnExport || 
           permission.fnEnableDisabled || 
           permission.fnAcceptReject;
  };
  
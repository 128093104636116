// src/components/common/CTable.tsx

import React, { ReactNode } from 'react';
import { Table, TableProps } from 'antd';

interface CTableProps<T> extends Omit<TableProps<T>, 'title' | 'footer'> {
  title?: ReactNode;
  footer?: ReactNode;
}

const CTable = <T extends object>({ title, footer, ...props }: CTableProps<T>) => {
  // Convert title and footer to functions returning ReactNode
  const renderTitle = () => (title ? <div>{title}</div> : null);
  const renderFooter = () => (footer ? <div>{footer}</div> : null);

  return (
    <div style={{ marginTop: '16px' }}>
      <Table
        // title={renderTitle}
        // footer={renderFooter}
        {...props}
      scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default CTable;

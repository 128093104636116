import React, { useEffect, useState } from 'react'
import CustomTabs from '../../components/common/CTabs'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import OrderList from './Order/OrderList'
import CustomerList from './Customer/CustomerList'
import VendorList from './Vendor/VendorList'
import FreelancerList from './Freelancer/FreelancerList'
import EventList from './Event/EventList'
import FinancialList from './Financial/FinancialList'
import BookingList from './Booking/BookingList'
import { useLocation, useNavigate } from 'react-router-dom'

const tabs = [
  { key: 'order', label: 'Order Report' },
  { key: 'customer', label: 'Customer Report' },
  { key: 'vendor', label: 'Vendor Report' },
  { key: 'freelancer', label: 'Freelancer Report' },
  { key: 'event', label: 'Event Report' },
  { key: 'financial', label: 'Financial Report' },
  { key: 'booking', label: 'Booking Report' }
]
const ReportList: React.FC = () => {
  const [activeKey, setActiveKey] = useState('order')
  const { t } = useTranslation()
  const location = useLocation();
  const navigate = useNavigate();
  
  const handleTabChange = (key: string) => {
    setActiveKey(key)
    localStorage.setItem('active', key)
    const params = new URLSearchParams(location.search);
  
    // Update the PageNumber parameter
    params.set('PageNumber', '1');
  
    // Navigate to the new URL with the updated query parameters
    navigate(`${location.pathname}?${params.toString()}`);
  }

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('active')
    if (storedActiveTab) {
      setActiveKey(storedActiveTab)
    }
  }, [])

  const componentMapping: { [key: string]: React.FC<{ status: string }> } = {
    order: OrderList,
    customer: CustomerList,
    vendor: VendorList,
    freelancer: FreelancerList,
    event: EventList,
    financial: FinancialList,
    booking: BookingList
  }

  const Component = componentMapping[activeKey]

  return (
    <>
      <p className='page-heading'>{t('Reports')}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />
      {Component && <Component status={activeKey} />}
    </>
  )
}

export default ReportList

import React, { useEffect, useRef } from 'react'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

interface ChartData {
  id: number
  name: string
  amount: number
}

interface DashboardChartProps {
  data: { data: ChartData[] }
  selectedYear: number
  onYearChange: (year: number) => void
}

const formatAmount = (amount: number) => {
  if (amount >= 1000) {
    return (
      new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 1
      }).format(amount / 1000) + 'K'
    )
  } else {
    return amount.toString()
  }
}

const getMonthName = (monthId: number) => {
  const months: { [key: number]: string } = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  }
  return months[monthId] || ''
}

const DashboardChart: React.FC<DashboardChartProps> = ({
  data,
  selectedYear,
  onYearChange
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstanceRef = useRef<Chart | null>(null)

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d')
      if (ctx) {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy()
        }

        const monthsInYear = Array.from({ length: 12 }, (_, i) => i + 1)
        const labels = monthsInYear.map(monthId => getMonthName(monthId))

        const amounts = monthsInYear.map(monthId => {
          const monthData = data.data.find(item => item.id === monthId)
          return monthData ? monthData.amount : 0
        })

        const chartInstance = new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Receivable',
                data: amounts,
                borderColor: '#9D74C5',
                fill: false,
                tension: 0.1,
                pointRadius: 5,
                pointBackgroundColor: '#9D74C5'
              }
            ]
          },
          options: {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Month Income',
                font: {
                  size: 18,
                  weight: 'bold',
                  family: 'sf-semibold'
                },
                padding: {
                  top: 20,
                  bottom: 20
                },
                align: 'start'
              },
              legend: {
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'circle',
                  font: {
                    size: 14
                  },
                  boxWidth: 10
                },
                align: 'center'
              },
              tooltip: {
                callbacks: {
                  label: context => {
                    const value = Number(context.raw)
                    return formatAmount(value)
                  }
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  display: true,
                  color: 'rgba(0, 0, 0, 0.1)',
                  lineWidth: 1
                },
                ticks: {
                  callback: value => formatAmount(Number(value))
                }
              },
              x: {
                grid: {
                  display: true,
                  color: 'rgba(0, 0, 0, 0.1)',
                  lineWidth: 1
                }
              }
            }
          }
        })

        chartInstanceRef.current = chartInstance
      }
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [data, selectedYear])

  return (
    <div style={{ position: 'relative' }}>
      <select
        value={selectedYear}
        onChange={e => onYearChange(Number(e.target.value))}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          padding: '5px 10px',
          fontSize: '14px',
          backgroundColor: '#f4f4f4',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }}
      >
        {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(
          year => (
            <option key={year} value={year}>
              {year}
            </option>
          )
        )}
      </select>

      <canvas ref={chartRef} width='400' height='200'></canvas>
    </div>
  )
}

export default DashboardChart

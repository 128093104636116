import React, { useState, useEffect } from 'react'
import { Upload, Button } from 'antd'
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'

interface ImageUploaderProps {
  label: string
  preview?: string
  onFileSelected: (file: File | null) => void
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  label,
  preview,
  onFileSelected
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(preview)

  const baseUrl = process.env.REACT_APP_S3_BUCKET_URL

  useEffect(() => {
    if (preview) {
      setPreviewUrl(`${baseUrl}${preview}`)
    }
  }, [preview, baseUrl])

  const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
    let newFileList = [...info.fileList]

    newFileList = newFileList.slice(-1)

    if (newFileList[0] && !newFileList[0].url && !newFileList[0].preview) {
      const reader = new FileReader()
      reader.onload = e => {
        newFileList[0].preview = e.target!.result as string
        onFileSelected(newFileList[0].originFileObj || null)
        setPreviewUrl(e.target!.result as string)
      }
      reader.readAsDataURL(newFileList[0].originFileObj!)
    }

    setFileList(newFileList)
  }

  const onRemoveFile = () => {
    setFileList([])
    onFileSelected(null)
    setPreviewUrl(undefined)
  }

  return (
    <div className='upload-container'>
      {previewUrl || (fileList.length > 0 && fileList[0]) ? (
        <div className='image-preview-container'>
          <div className='image-preview-name'>
            <img
              src={previewUrl || fileList[0].preview || fileList[0].url}
              alt='preview'
              style={{ width: '70px', height: '70px' }}
            />
            {fileList[0]?.name}
          </div>
          <CloseCircleOutlined
            onClick={onRemoveFile}
            className='image-preview-icon'
          />
        </div>
      ) : (
        <>
          <p className='upload-info'>
            {label}
            <br />
            File Formats: PNG, JPG
            <br />
            File Size 5MB max
          </p>
          <Upload
            accept='.jpg,.png'
            fileList={fileList}
            onChange={onFileChange}
            beforeUpload={() => false}
            showUploadList={false}
          >
            <Button className='upload-button'>Upload</Button>
          </Upload>
        </>
      )}
    </div>
  )
}

export default ImageUploader

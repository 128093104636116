import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import AlertModal from '../../components/common/AlertModal'
import { Theme } from '../../Theme'
import Status from '../../components/common/Status'
import CustomSelect from '../../components/common/CSelect'
import { get } from '../../services/api/apiService'
import SuccessModal from '../../components/common/SuccessModal'
import { getOrderTrackingById } from '../../services/booking'
import CustomAlert from '../../components/common/CAlert'
import RescheduleModal from '../../components/common/RescheduleModal'
const initialOrderStatus = [
  { value: 'OrderPrepartion', label: 'Order Prepartion', disabled: true },
  { value: 'TeamEnroute', label: 'Team Enroute', disabled: true },
  { value: 'EventExecuted', label: 'Event Executed', disabled: true },
  { value: 'EventCompleted', label: 'Event Completed', disabled: true }
]
const ViewAndUpdateOrder: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<
    string | undefined
  >(undefined)
  const [isVisible, setIsVisible] = useState(false)
  const [orderStatus, setOrderStatus] = useState(initialOrderStatus)
  const [record, setRecord] = useState<{ [key: string]: any }>({})
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const { data, isLoading, error, refetch } = useQuery(
    ['serviceById', params?.id],
    () => getOrderTrackingById({ Id: params?.id })
  )
  const updateOrderStatus = (recordStatus: any) => {
    return initialOrderStatus.map((status, index) => {
      if (recordStatus === 'Paid') {
        return { ...status, disabled: status.value !== 'OrderPrepartion' }
      }
      if (status.value == recordStatus) {
        return { ...status, disabled: true }
      } else if (
        index > 0 &&
        initialOrderStatus[index - 1].value == recordStatus
      ) {
        return { ...status, disabled: false }
      }
      return { ...status, disabled: true }
    })
  }
  const showCustomAlert = async (
    type: 'success' | 'error',
    message: string
  ) => {
    await refetch()
    setAlert({ message: message, type: type, visible: true })
  }
  const refetchData = () => {
    console.log('rescheduled event')
  }
  const showModal = (show: boolean) => {
    setIsVisible(show)
    // setSelectedServiceId(serviceId)
  }
  useEffect(() => {
    if (data?.succeeded) {
      setRecord(data?.data)
    }
  }, [data])
  //   useEffect(() => {
  //     if (location.state) {
  //       console.log('location.state', location.state)
  //       setRecord(location.state.record)
  //     }
  //   }, [location.state])
  // Update `orderStatus` when `record.status` changes
  useEffect(() => {
    if (record && record.status) {
      const updatedOrderStatus = updateOrderStatus(record.status)
      setOrderStatus(updatedOrderStatus)
    }
  }, [record])
  const onChangeOrderStatus = async (type: any) => {
    setCurrentSelectedStatus(type)
    setIsModalVisible(true)
  }
  const handleModalOk = async () => {
    setIsModalVisible(false)
    let url = `/AdminServiceOrderTracking/${
      currentSelectedStatus == 'EventCompleted'
        ? 'EventCompletion'
        : currentSelectedStatus
    }?OrderId=${record.id}`
    let response = await get(url, {})
    if (response.succeeded) {
      //   setIsSuccess(true)
      setAlert({ message: response.message, type: 'success', visible: true })
      await refetch()
    } else {
      setAlert({ message: response.message, type: 'error', visible: true })
    }
    console.log('selected url', url)
  }
  const handleModalCancel = () => {
    setCurrentSelectedStatus(undefined)
    setIsModalVisible(false)
  }
  const getStatusLabel = (status: any) => {
    let statusLabel = orderStatus.find(x => x.value == status)
    return statusLabel?.label
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <img
              src={Theme.icons.icn_arrow_back}
              alt='icn_user_add'
              className='cursor-pointer'
              style={{ height: '20px' }}
              onClick={() => navigate(`/jive/orderTracking`)}
            />
            <p className='list-heading m-0 mx-2'>{'Order Details'}</p>
          </div>
        </div>
      </div>
      <div
        className='mt-3'
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <div className='d-flex align-items-center justify-content-between'>
          <p className='list-heading m-0 mx-2'>{'Order Tracking Details'}</p>
          {record?.status != 'EventCompleted' && (
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center justify-content-center'
                // ref={buttonRef}
                onClick={() => showModal(true)}
              >
                <p
                  className='m-0 cursor-pointer'
                  style={{
                    background: '#D0C64F',
                    padding: '10px',
                    borderRadius: '10px'
                  }}
                >
                  {t('Reschedule')}
                </p>
              </div>
              <div>
                <CustomSelect
                  className='custom-select custom-dropdown'
                  options={orderStatus}
                  placeholder={t('Select Order Status')}
                  onChange={value => onChangeOrderStatus(value)}
                  value={currentSelectedStatus}
                />
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            backgroundColor: '#F3F3F3',
            padding: '24px',
            borderRadius: '16px',
            marginTop: '20px'
          }}
        >
          <div className='row mt-4'>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Order ID')}</p>
              <p className='detail-value mt-1'>{record?.id}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Customer Name')}</p>
              <p className='detail-value mt-1'>{record?.customerName}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Order Status')}</p>
              <p className='detail-value mt-1'>
                <Status status={record?.status} />{' '}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Booking Date')}</p>
              <p className='detail-value mt-1'>
                {dayjs(record?.dateOfBooking)?.format('DD.MM.YYYY')}
              </p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Service')}</p>
              <p className='detail-value mt-1'>
                {record?.serviceName
                  ? record?.serviceName
                  : record?.eventTypeName}
              </p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Payment Status')}</p>
              <p className='detail-value mt-1'>{<Status status={'Paid'} />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Date')}</p>
              <p className='detail-value mt-1'>
                {dayjs(record?.serviceRequestDate)?.format('DD.MM.YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        visible={isModalVisible}
        message={t(
          `Are you sure you want to change status from ${
            record?.status ? getStatusLabel(record?.status) : 'Paid'
          } to  ${getStatusLabel(currentSelectedStatus)}?`
        )}
        isCustomBtnText={true}
        customButtonLabels={{ txtCancel: 'No', txtOk: 'Yes' }}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <SuccessModal
        visible={isSuccess}
        message={'Order status updated successfully'}
        onOk={() => navigate(`/jive/orderTracking`)}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
      <RescheduleModal
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        customData={{
          orderId: params.id
        }}
        refetch={refetchData}
        showCustomAlert={showCustomAlert}
      />
    </>
  )
}
export default ViewAndUpdateOrder
import React, { useState } from 'react'
import CInput from '../../../../components/common/CInput'
import { Theme } from '../../../../Theme'
import CTable from '../../../../components/common/CTable'
import { ColumnsType } from 'antd/lib/table'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import CButton from '../../../../components/common/CButton'
import { getAllCities } from '../../../../services/lov'
import EmptyList from '../../../../components/common/EmptyList'
import CustomAlert from '../../../../components/common/CAlert'
import permissionService from '../../../../services/permissions'

interface CitiesProps {
  countryId: any
  heading: string
  onCountUpdate?: (count: number) => void // Callback for count update
}

const CitiesList: React.FC<CitiesProps> = ({
  countryId,
  heading,
  onCountUpdate
}) => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''

  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      {
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const columns: ColumnsType = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('country_name'),
      dataIndex: 'countryName',
      key: 'countryName'
    },
    {
      title: t('City Name'),
      dataIndex: 'cityName',
      key: 'cityName'
    },
    {
      title: t('اسم المدينة'),
      dataIndex: 'cityNameAr',
      key: 'cityNameAr'
    },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          { permissionService.getRoleMenuPermissions('Manage List of values')?.update &&
            <img
            src={Theme.icons.icn_edit_list}
            alt='icn_user_edit'
            style={{ height: '20px', cursor: 'pointer' }}
            onClick={() =>
              navigate(`/jive/country/${countryId}/cities/view/${record.id}`, { state: { record } })
            }
          />}
        </div>
      ),
      key: 'action'
    }
  ]

  const { data,  error, isLoading } = useQuery(
    ['Cities', countryId, pageNumber, pageSize, search],
    () =>
      getAllCities({
        countryId: countryId,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  const isEmpty = !data?.data?.length && !data?.totalRecords

  // Call the parent with the count if provided
  if (onCountUpdate) {
    onCountUpdate(data?.totalRecords)
  }

  return (
    <>
      {isEmpty && search == '' && permissionService.getRoleMenuPermissions('Manage List of values')?.add ? (
        <EmptyList
          heading={t('No Cities Created yet!')}
          description={t('There are no cities available.')}
          buttonLabel={t('Add New City')}
          icon={
            <img
              alt=''
              src={Theme.icons.icn_user_add}
              style={{ height: '20px' }}
              className='icon-white'
            />
          }
          onButtonClick={() => navigate(`/jive/country/${countryId}/cities/add/new`)}
        />
      ) : (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='m-0 list-heading'>{t(heading)}</p>
            <div className='d-flex justify-content-between'>
              <CInput
                prefix={
                  <img
                    alt=''
                    src={Theme.icons.icn_search}
                    style={{ height: '16px' }}
                  />
                }
                className='searchInput'
                placeholder={t('Search')}
                onChange={handleSearchChange}
              />
              {
                permissionService.getRoleMenuPermissions('Manage List of values')?.add &&
                <CButton
                className='addUserBtn'
                onClick={() => navigate(`/jive/country/${countryId}/cities/add/new`)}
              >
                <img
                  src={Theme.icons.icn_add_btn}
                  alt='icn_user_add'
                  style={{ height: '20px' }}
                />
                <p className='m-0'>{t('Add City')}</p>
              </CButton>}
            </div>
          </div>
          <CTable
            footer='End of Table'
            columns={columns}
            dataSource={data?.data}
            pagination={{
              showPrevNextJumpers: true,
              current: pageNumber,
              pageSize: pageSize,
              total: data?.totalRecords,
              onChange: handleTableChange,
              showTotal: (total: number, range: [number, number]) => (
                <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
              ),
              itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                  return <p className='detail-label'>{`< Prev`}</p>
                }
                if (type === 'next') {
                  return <p className='detail-label'>{`Next >`}</p>
                }
                return originalElement
              }
            }}
            loading={isLoading}
          />
        </>
      )}
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default CitiesList

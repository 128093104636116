import { get } from './api/apiService'

type Permission = {
  read: boolean
  add: boolean
  update: boolean
  export : boolean
  enableDisabled : boolean
  acceptReject: boolean
}

class PermissionService {
  userDetails: any
  userMenus: any[]=[]
  private static instance: PermissionService

  private constructor () {
    // this.getProfile()
  }

  public static getInstance (): PermissionService {
    if (!PermissionService.instance) {
      PermissionService.instance = new PermissionService()
    }
    return PermissionService.instance
  }

  async getProfile () {
    let response = await get('AdminAccount/GetMyProfile', {})

    if (response.succeeded) {
      this.userDetails = response?.data
      this.userMenus = this.userDetails?.userMenus
    }
    return response.data
  }

  getRoleMenuPermissions (moduleName: string): Permission {
    const defaultPermissions: Permission = {
      read: false,
      add: false,
      update: false,
      export:false,
      enableDisabled:false,
      acceptReject:false
    }

    if (this.userMenus?.length === 0) {
      return defaultPermissions
    }

    let menuFound = null

    for (const element of this.userMenus) {
      if (element.menuTitle === moduleName) {
        menuFound = element
        break
      }

      if (element.subMenuList && element.subMenuList.length > 0) {
        for (const submenu of element.subMenuList) {
          if (submenu.menuTitle === moduleName) {
            menuFound = submenu
            break
          }
        }
      }

      if (menuFound) break
    }

    if (menuFound) {
      return this.getPermission(menuFound)
    } else {
      return defaultPermissions
    }

    // const menu = this.userMenus?.find(
    //   (item: any) => item.menuTitle === moduleName
    // )

    // if (menu && !menu.parentId) {
    //   return this.getPermission(menu)
    // } else {
    //   const subMenu = menu.subMenuList?.find(
    //     (sm: any) => sm.menuTitle === moduleName
    //   )

    //   if (subMenu) {
    //     return this.getPermission(menu)
    //   }
    // }
  }

  getPermission (menu: any) {
    return {
      read: menu.fnRead,
      add: menu.fnAdd,
      update: menu.fnEdit,
      export: menu.fnExport,
      enableDisabled: menu.fnEnableDisabled,
      acceptReject: menu.fnAcceptReject,
    }
  }
}

const permissionService = PermissionService.getInstance()
export default permissionService

import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Upload, UploadProps } from 'antd';
import CButton from '../../../../../components/common/CButton';
import CustomAlert from '../../../../../components/common/CAlert';
import CInput from '../../../../../components/common/CInput';
import { RcFile, UploadFile } from 'antd/es/upload';
import { SaveCertificationLincensesInfo } from '../../../../../services/freelancer';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import MultiImageUploader from '../../../../../components/common/MultiImageUploader';
import { useTranslation } from 'react-i18next';

const getBase64 = (file: File, callback: (url: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader.onload = () => callback(reader.result);
    reader.readAsDataURL(file);
};

interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab: number;
    maxVisitedTab: number;
    type:string
}

const CertificationsLicenses: React.FC<VendorInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab,type }: any) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { Dragger } = Upload;
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [lincensesFiles, setLicenseFiles] = useState<any[]>([])

    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    useEffect(() => {
        console.log("Linces",state?.vendorFreelancerCertifications)
        if (state.commericalRegistrationNumber !== null) {
            form.setFieldsValue({
                CommericalRegistrationNumber: state.commericalRegistrationNumber,
            });
        }
        if (state.vendorFreelancerCertifications.length !== 0) {
            form.setFieldsValue({
                certificates: state?.vendorFreelancerCertifications,
            });
        }
        if (state.vendorFreelancerLicenses.length !== 0) {
            form.setFieldsValue({
                lincenses: state?.vendorFreelancerLicenses,
            });
        }
    }, [])

    const { mutate, isLoading } = useMutation(SaveCertificationLincensesInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if (exit) {
                    navigate(`/jive/${type == 'vendor' ? 'vendorList' : 'freelancerList'}`);
                } else {
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleFileSelect = (files: File[]) => {
        console.log('file select===>>>', files);
        // let certificates = []
        // // form.getFieldValue('certificates')

        // files.forEach((element:any) => {
        //     if(element.preview){
        //         certificates.push(element)
        //     }
        // });

        setUploadedFiles(files);
        form.setFieldsValue({
            certificates: files
          })
        
        console.log("certificates-----",form.getFieldValue('certificates'))

        console.log("lincenses------",form.getFieldValue('lincenses'))

    };

    const handleLicenseSelect = (files: File[]) => {
        console.log('file select===>>>', files)
        setLicenseFiles(files)
        form.setFieldsValue({
            lincenses: files
          })
      }


    const handleSave = async (values: any) => {
        {
        const obj = {
            CommericalRegistrationNumber: values.CommericalRegistrationNumber,
            id: state.id,
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab,
            // ...values
            vendorFreelancerCertifications:uploadedFiles.length>0?uploadedFiles.filter(file => !file.preview):state?.vendorFreelancerCertifications,
            ...(type === 'freelancer' && {
              vendorFreelancerLicenses: lincensesFiles.length>0?lincensesFiles.filter(file => !file.preview):state?.vendorFreelancerLicenses
            })

        }
        const formData = new FormData();
        const binaryFile = uploadedFiles.filter(file => file.preview);
        binaryFile.forEach((file) => {
            formData.append('certificates', file?.newFile as RcFile);
        });
        if (type == 'freelancer') {
            const binaryLicense = lincensesFiles.filter(file => file.preview)
            binaryLicense.forEach(file => {
              formData.append('lincenses', file?.newFile as RcFile)
            })
          }
          console.log("Object Forms -----",obj);
          
        formData.append('obj', JSON.stringify(obj));
        mutate(formData);
        }
    };

    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };


    return (
        <>
            <p className="user-name">Certifications & Licenses</p>
            <Form form={form} layout="vertical" onFinish={handleSave} initialValues={{ remember: true }}>
                <div className="row flex-column">
                    <div className="col-lg-4">
                        <Form.Item
                            name="CommericalRegistrationNumber"
                            rules={[{ required: true, message: 'Please enter the registration number' }]}
                        >
                            <CInput label="Commercial Registration Number" placeholder="Enter Registration Number" />
                        </Form.Item>
                    </div>
                    </div>
                    
                    <div className='d-flex gap-2'>
                        <div style={{width:'100%'}}>
                        <Form.Item
                        name="certificates"
                        rules={[{ required: true, message: 'Please select the certificates' }]}
                    >
                        <label className="inputfield-label mt-2">Commercial & License Documents</label>
                        <MultiImageUploader
                            type='documents'
                            setAlert={setAlert}
                            storeImages={state?.vendorFreelancerCertifications.length !== 0 && state?.vendorFreelancerCertifications}
                            onFileSelected={handleFileSelect}
                            draggerWidth={'100%'}
                            widthClass='col-lg-12'
                        />
                    </Form.Item>
                    </div>
                        <div style={{width:'100%'}}>
                        {type == 'freelancer' && (
                            <Form.Item
                            name='lincenses'
                            rules={[
                                { required: true, message: 'Please select the licenses' }
                            ]}
                            >
                            <label className='inputfield-label mt-2'>
                                {t('Freelancing Permit Document')}
                            </label>
                            <MultiImageUploader
                                type='documents'
                                setAlert={setAlert}
                                storeImages={state?.vendorFreelancerLicenses.length !== 0 && state?.vendorFreelancerLicenses}
                                onFileSelected={handleLicenseSelect}
                                draggerWidth={'100%'}
                                widthClass='col-lg-12'
                            />
                            </Form.Item>
                        )}
                        </div>
                    </div>

                    
                       
                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton className='cancelBtn' loading={isLoading && exit} onClick={handleSaveAndExit}>
                            {t("Save & Exit")}
                            </CButton>
                        </Col>
                        <Col span={4}>
                            <CButton loading={isLoading && !exit} style={{ width: '100%' }} htmlType="submit">
                                {t("Next")}
                            </CButton>
                        </Col>
                    </Row>
                {/* </div> */}
            </Form>

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default CertificationsLicenses;

import React, { useEffect, useState } from "react";
import CustomTabs from "../../../components/common/CTabs";
import CInput from "../../../components/common/CInput";
import { Theme } from "../../../Theme";
import CTable from "../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Switch, Tag } from "antd";
import AlertModal from "../../../components/common/AlertModal";
import CustomAlert from "../../../components/common/CAlert";
import { changeVendorStatus, getAllFreelancer, getAllVendors } from "../../../services/freelancer";
import _ from 'lodash';
import dayjs from 'dayjs';
import CButton from "../../../components/common/CButton";
import { postWithParams } from "../../../services/api/apiService";
import Status from "../../../components/common/Status";
import permissionService from "../../../services/permissions";

const tabs = [
    { key: 'All', label: 'All Request' },
    { key: 'Draft', label: 'Draft Request' },
    { key: 'Active', label: 'Active Request' },
    { key: 'InActive', label: 'InActive Request' },
];

interface VendorOnboardingListProps {
    type: 'vendor' | 'freelancer';
  }

const VendorOnboardingList: React.FC<VendorOnboardingListProps> = ({type}) => {
    const [userWillActive, setUserWillActive] = useState<{ show: boolean; status: boolean; id: number | null }>({
        show: false,
        status: false,
        id: null,
    });
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

      // Get activeKey, pageNumber, and pageSize from URL search params
      const activeKey = searchParams.get('Status') || 'All';
      const pageNumber = Number(searchParams.get('PageNumber')) || 1;
      const pageSize = Number(searchParams.get('PageSize')) || 10;
      const search = searchParams.get('Search') || "";
  
      // Update search params when the tab changes
      const handleTabChange = (key: string) => {
          setSearchParams({ Status: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
      };
  
      // Pagination handling
      const handleTableChange = (page: number, pageSize: number) => {
          setSearchParams({ Status: activeKey, PageNumber: page.toString(), PageSize: pageSize.toString(),Search:search }, { replace: true });
      };
  
      const handleSearchChange =  _.debounce((e:any) => {
          setSearchParams({ Status: activeKey, PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
      },1000)


    const handleSwitchChange = (user: any) => {
        setUserWillActive({ show: true, status: user?.active, id: user?.id });
    };
    const [countdown, setCountdown] = useState(1); // Set an initial countdown (in seconds)

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 700);
      return () => clearTimeout(timer); // Cleanup on unmount or count change
    }
  }, []);

    const columns: ColumnsType = [
        {
            title: t("ID"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: type === 'vendor' ? t('Vendor Name') : t('Freelancer Name'),
            dataIndex: 'businessName',
            key: 'name',
        },
        {
            title: t("email_address"),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t("date_of_joining"),
            dataIndex: 'dateOfJoining',
            key: 'joiningDate',
            render: (text, record) => (
                <p>{text ? dayjs(text)?.format('DD.MM.YYYY') : '-'}</p>
            )
        },
        {
            title: t("status"),
            dataIndex: 'status',
            key: 'roleName',
            render: (text, record) => (
                <Status status={record.status} />
            )
        },
        {
            title: t('action'),
            key: 'action',
            render: (text, record) => {
                return (
                <div className="d-flex  align-items-center">
                    <img
                        src={Theme.icons.icn_view_list}
                        alt="icn_user_add"
                        style={{ height: '20px' }}
                        className='cursor-pointer'
                        onClick={() => navigate(`/jive/${type == 'vendor' ? 'viewVendor' :'viewFreelancer'}`, { state: {  id: record?.id } })}
                    />
                    
                {   permissionService.getRoleMenuPermissions(type === 'vendor'?'Vendor Onboarding':'Freelancer Onboarding')?.update &&
                    <img src={Theme.icons.icn_edit_list} alt="icn_user_edit" className="mx-2 cursor-pointer" style={{ height: '20px' }} onClick={() => navigate(`${type == 'vendor' ? '/jive/vendorOnboarding': '/jive/freelancerOnboarding'}`, {state:{record}})} />}

                    {
                        record?.status != 'Draft' && permissionService.getRoleMenuPermissions(type === 'vendor'?'Vendor Onboarding':'Freelancer Onboarding')?.enableDisabled && (
                        <Switch
                        checked={record?.active}
                        onChange={() => handleSwitchChange(record)}
                        className='mx-2'
                    />
                    )}
                </div>
            )
        }
        },
    ];


    const { data, error, isLoading, refetch } = useQuery(
        ['dataList', activeKey, pageNumber, pageSize, search],
        () => postWithParams(type == 'vendor' ? 'AdminVendorFreelancer/GetAllVendors':'AdminVendorFreelancer/GetAllFreelancers',{ Status: activeKey, PageNumber: pageNumber, PageSize: pageSize, Search: search}),
        {
            // keepPreviousData: true,
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );

    const getList = async ()=>{
        await refetch()
    }

    useEffect(()=>{
        if(type){
            getList()
        }
    },[type])

    const { mutate, isLoading: statusLoading } = useMutation(changeVendorStatus, {
        onMutate: async (newStatus: any) => {

            await queryClient.cancelQueries(['dataList', activeKey]);
            const previousData = queryClient.getQueryData(['dataList', activeKey]);
            return { previousData };
        },
        onError: (err: any, newStatus, context) => {
            queryClient.setQueryData(['dataList', activeKey], context?.previousData);
            setAlert({ message: err?.message || t("Failed to update status"), type: 'error', visible: true });
        },
        onSettled: () => {
            queryClient.invalidateQueries(['dataList', activeKey]);
        },
        onSuccess: () => {
            setAlert({ message: t("Status Updated successfully!"), type: 'success', visible: true });
        },
    });

    const handleModalOk = () => {
        if (userWillActive.id !== null) {
            const newStatus = !userWillActive.status;
            mutate({ id: userWillActive.id, status: newStatus });
        }
        setUserWillActive({ show: false, status: false, id: null });
    };

    const handleModalCancel = () => {
        setUserWillActive({ show: false, status: false, id: null });
    };

    return (
        <>
            <p className="page-heading">{type == 'vendor' ? t("Vendor Onboarding") : 'Freelancer Onboarding'}</p>
            <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} />

            <div className="d-flex justify-content-between align-items-center" >
                <p className="m-0 list-heading">{`List of Onboarding ${type == 'vendor' ? 'Vendors':'Freelancers'}`}</p>
                <div className="d-flex justify-content-between">
                    <CInput
                        prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />
                    {/* <div className="calendar-div" style={{ backgroundColor: '#D0C64F' }}>
                        <img src={Theme.icons.icn_sorting} alt="icn_user_add" style={{ height: '20px' }} />
                    </div> */}
                          {
                            permissionService.getRoleMenuPermissions(type === 'vendor'?'Vendor Onboarding':'Freelancer Onboarding')?.add &&
                            <CButton className="addUserBtn" onClick={() => navigate(`/jive/${type == 'vendor' ? 'vendorOnboarding' :'freelancerOnboarding'}`)}>
        <img src={Theme.icons.icn_add_btn} alt="icn_user_add" style={{ height: '20px' }}className='cursor-pointer' />
            <p className="m-0">{type === 'vendor' ? t('Add Vendor') : t('Add Freelancer')}</p>
        </CButton>}
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showSizeChanger:false,
                    showQuickJumper:false,
                    current: pageNumber,
                    pageSize: pageSize,
                    total: data?.totalRecords,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <p className="detail-label">{`< Prev`}</p>;
                        }
                        if (type === 'next') {
                            return <p className="detail-label">{`Next >`}</p>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />

            <AlertModal
                visible={userWillActive?.show}
                message={t(`Are you sure you want to ${userWillActive?.status ? 'deactivate' : 'activate'} this ${type}?`)}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default VendorOnboardingList;

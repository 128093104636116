import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CButton from '../../../../components/common/CButton'
import { Theme } from '../../../../Theme'
import { getAllCancelationPolicy } from '../../../../services/lov'
import CustomTabs from '../../../../components/common/CTabs'
import permissionService from '../../../../services/permissions'
const tabs = [
  { key: 'en', label: 'English' },
  { key: 'ar', label: 'العربية' }
]
const CancellationPolicy: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [cancellationPolicy, setCancellationPolicy] = useState<any[]>([])
  const [viewedPolicyId, setViewedPolicyId] = useState<number | null>(null)
  const redirectUrl = `/jive/cancellation-policy`
  const [activeKey, setActiveKey] = useState('en')

  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const { data, error, isLoading } = useQuery(
    'TermAndCondition',
    () =>
      getAllCancelationPolicy({
        Search: ''
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      console.log('data.data[0]', data.data)
      setCancellationPolicy(data.data)
    }
  }, [data])
  const handleTabChange = (key: string) => {
    setActiveKey(key)
  }
  return (
    <>
      {cancellationPolicy?.map((policy: any) => (
        <div
          className='mt-3'
          style={{
            backgroundColor: Theme.colors.white,
            padding: '18px',
            borderRadius: '16px'
          }}
          key={policy.id}
        >
          <div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
            {policy.id && (
              <>
                <p className='m-0 list-heading'>
                  {policy.id == 1
                    ? t('Jive Vendor & Freelancer Cancellation Policy')
                    : t('Cancellation Policy For Customers')}
                </p>
                {!policy.id && (
                  <CButton
                    className='addUserBtn mb-2'
                    onClick={() => navigate(`${redirectUrl}/add/new`)}
                  >
                    <img
                      src={Theme.icons.icn_add_btn}
                      alt='icn_add_btn'
                      style={{ height: '20px' }}
                    />
                    <p className='m-0'>{t('Add Policy')}</p>
                  </CButton>
                )}

                {policy.id && (
                  <div className='d-flex gap-2'>
                    { 
                      permissionService.getRoleMenuPermissions('Manage List of values')?.update &&
                      <img
                      onClick={() =>
                        navigate(`${redirectUrl}/edit/${policy.id}`, {
                          state: policy
                        })
                      }
                      src={Theme.icons.icn_edit}
                      alt='icn_edit'
                      style={{ height: '20px' }}
                      className='cursor-pointer'
                    />}

                    <img
                      onClick={() =>
                        setViewedPolicyId(
                          viewedPolicyId === policy.id ? null : policy.id
                        )
                      }
                      src={Theme.icons.icn_view_list}
                      alt='icn_edit'
                      style={{ height: '20px' }}
                      className='cursor-pointer'
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {viewedPolicyId == policy.id && policy.type == 'Mobile' && (
            <>
              <CustomTabs
                tabs={tabs}
                activeKey={activeKey}
                onTabChange={handleTabChange}
              />
              {activeKey == 'en' && (
                <div
                  className='view-container mt-3'
                  dangerouslySetInnerHTML={{ __html: policy?.description }}
                />
              )}

              {activeKey == 'ar' && (
                <div
                  className='view-container mt-3 dir-rtl'
                  dangerouslySetInnerHTML={{
                    __html: policy?.descriptionAr
                      ? policy?.descriptionAr
                      : `<p><strong>مطلوب سياسة الإلغاء للعملاء</strong></p>`
                  }}
                />
              )}
            </>
          )}
          {viewedPolicyId === policy.id && policy.type == 'Vendor' && (
            <>
              <CustomTabs
                tabs={tabs}
                activeKey={activeKey}
                onTabChange={handleTabChange}
              />
              {activeKey == 'en' && (
                <div
                  className='view-container mt-3'
                  dangerouslySetInnerHTML={{ __html: policy?.description }}
                />
              )}

              {activeKey == 'ar' && (
                <div
                  className='view-container mt-3 dir-rtl'
                  dangerouslySetInnerHTML={{
                    __html: policy?.descriptionAr
                      ? policy?.descriptionAr
                      : `<p><strong>مطلوب سياسة إلغاء البائع والمستقل</strong></p>`
                  }}
                />
              )}
            </>
          )}
        </div>
      ))}
    </>
  )
}

export default CancellationPolicy

import { t } from 'i18next'
import React from 'react'
import { Theme } from '../../Theme'
import CButton from './CButton'

interface EmptyListProps {
  heading: string
  description: string
  buttonLabel: string
  icon?: React.ReactNode
  onButtonClick: () => void
}

const EmptyList: React.FC<EmptyListProps> = ({
  heading,
  description,
  buttonLabel,
  icon,
  onButtonClick
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px'
        }}
      >
        {icon && <div style={{ marginBottom: '16px' }}><img alt="" src={Theme.icons.icn_empty_list}  /></div>}
        <h5>{heading}</h5>
        <p>{description}</p>
        <CButton onClick={onButtonClick}>
          {icon}
          <p className='m-0'>{t(buttonLabel)}</p>
        </CButton>
      </div>
    </>
  )
}
export default EmptyList

import React, { useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Theme } from '../../Theme'
import CustomAlert from '../../components/common/CAlert'
import CInput from '../../components/common/CInput'
import CTable from '../../components/common/CTable'
import { get } from '../../services/api/apiService'
import Status from '../../components/common/Status'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'
import { DatePicker, Dropdown, MenuProps } from 'antd'
import moment from 'moment'
import { getAdminReportExportToExcel } from '../../services/report'
import CButton from '../../components/common/CButton'

const AuditLogs: React.FC = () => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { RangePicker } = DatePicker

  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const startDate = searchParams.get('StartDate') || ''
  const endDate = searchParams.get('EndDate') || ''

  // Pagination handling
  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      { PageNumber: page.toString(), PageSize: pageSize.toString(),StartDate:startDate,EndDate:endDate },
      { replace: true }
    )
  }

  // Handle search input with debounce
  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusError,
    refetch
  } = useQuery(
    ['orderReportExportToExcel', startDate, endDate],
    () =>
      getAdminReportExportToExcel(
        'AuditTrail/UserAuditLogReportExportToExcel',
        { StartDate: startDate, EndDate: endDate }
      ),
    {
      enabled: false,
      onSuccess: data => {
        // Create a Blob from the response data
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // MIME type for xlsx files
        })
        // Create a link element
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'user_audit_logs.xlsx'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setAlert({
          message: 'You have successfully downloaded the file',
          type: 'success',
          visible: true
        })
      }
    }
  )

  const downloadFile = (type: string) => {
    if (type === 'xls') {
      refetch()
    }
  }
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <p className='m-0' onClick={() => downloadFile('xls')}>
          XLS
        </p>
      )
    }
  ]

  const checkDisable = () => {
    if (startDate === '' && endDate === '') {
      return true
    } else {
      return false
    }
  }
  const getCurrentLocalTime = (time: string) => {
    const localTime = moment.utc(time).local().format('hh:mm A')
    return localTime
  }

  // Define columns for Payment Logs
  const columns: ColumnsType = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Action Type'),
      dataIndex: 'actionType',
      key: 'actionType'
    },
    {
      title: t('Action Details'),
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: t('User Type'),
      dataIndex: 'roleName',
      key: 'roleName'
    },
    {
      title: t('Date'),
      key: 'date',
      render: (text, record) => (
        <p>{dayjs(record.date)?.format('DD-MM-YYYY')}</p>
      )
    },
    {
      title: t('Time'),
      key: 'Time',
      render: (text, record) => <p>{getCurrentLocalTime(record.date)}</p>
    }
  ]

  // Fetch Payment Logs data
  const { data, error, isLoading } = useQuery(
    ['AuditLogs', pageNumber, pageSize, search, startDate, endDate],
    () =>
      get('AuditTrail/GetAll', {
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search,
        StartDate: startDate,
        EndDate: endDate
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day')
  }

  const handleSelect = (ranges: any) => {
    if (ranges) {
      const StartDatePicker = dayjs(ranges && ranges[0]).format('YYYY-MM-DD')
      const EndDatePicker = dayjs(ranges && ranges[1]).format('YYYY-MM-DD')

      setSearchParams(
        {
          StartDate: StartDatePicker,
          EndDate: EndDatePicker,
          PageNumber: '1',
          PageSize: pageSize.toString()
        },
        { replace: true }
      )
    }
    setShowDateRangePicker(!showDateRangePicker)
  }
  return (
    <>
      <p className='page-heading'>{t('Audit Logs')}</p>

      <div className='d-flex justify-content-between align-items-center'>
        <p className='m-0 list-heading'>{t('List of Audit Logs')}</p>
        <div
          className='d-flex justify-content-between'
          style={{ position: 'relative' }}
        >
          <div
            className='calendar-div mx-2'
            onClick={() => setShowDateRangePicker(!showDateRangePicker)}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={Theme.icons.icn_white_calendar}
              alt='icn_user_add'
              style={{ height: '20px' }}
            />
          </div>
          {showDateRangePicker && (
            <div
              style={{
                position: 'absolute',
                zIndex: 1000,
                marginTop: '50px',
                right: '40px'
              }}
            >
              <RangePicker
                onChange={handleSelect}
                disabledDate={disabledDate}
                style={{ height: '50px', width: '230px' }}
              />
            </div>
          )}

          <Dropdown
            disabled={checkDisable()}
            menu={{ items }}
            placement='bottomLeft'
          >
            <CButton disabled={checkDisable()} className='addUserBtn'>
              <img
                src={Theme.icons.icn_export}
                alt='icn_user_add'
                style={{ height: '20px' }}
              />
              <p className='m-0'>{t('Export')}</p>
            </CButton>
          </Dropdown>
        </div>
      </div>
      <CTable
        footer='End of Table'
        columns={columns}
        dataSource={data?.data}
        pagination={{
          showSizeChanger: false,
          showQuickJumper: false,
          current: pageNumber,
          pageSize: pageSize,
          total: data?.totalRecords,
          onChange: handleTableChange,
          showTotal: (total: number, range: [number, number]) => (
            <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
          ),
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <p className='detail-label'>{`< Prev`}</p>
            }
            if (type === 'next') {
              return <p className='detail-label'>{`Next >`}</p>
            }
            return originalElement
          }
        }}
        loading={isLoading}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default AuditLogs

import React, { ReactNode, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../components/common/CButton";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";



const PdfPreview: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const contentRef = useRef<HTMLDivElement>(null);
    // const handlePrint = useReactToPrint({ contentRef });
    const handlePrint = useReactToPrint({
        contentRef,
    //     pageStyle: `
    //       @page {
    //         // size: A4;
    //       }
    //             div {
    //     padding: 10mm; /* Padding for print */
    //     color: blue;
    //   }
    //     `
      });

    const { data, endDate, startDate, type } = location.state; 
    return (
        <>
        <div style={{backgroundColor: Theme.colors.white, padding:'20px', borderRadius:'12px'}}>
            <div className="d-flex justify-content-between align-items-center">
            <div className='d-flex align-items-center'>
                    <img src={Theme.icons.icn_arrow_back} className='cursor-pointer' alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className="m-0 mx-2 list-heading">{t(`List of ${type === 'order' ? "Order" : "Customer"} Report`)}</p>
                </div>

                <CButton className="addUserBtn" onClick={() => handlePrint()}>
                    <img src={Theme.icons.icn_export} alt="icn_user_add" style={{ height: '20px' }} />
                    <p className="m-0">{t("Export")}</p>
                </CButton>
            </div>
            
            <div ref={contentRef}>
            <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .top-logo {\n            width: 156px;\n        }\n\n        .top-logo img {\n            max-width: 100%;\n            max-height: 100%;\n        }\n\n        .equal {\n            width: 33.33%;\n        }\n\n        .label {\n            color: #555;\n            font-size: 10px;\n        }\n\n        .details {\n            font-size: 12px;\n            font-weight: 600;\n        }\n\n        tbody.bottom-tbody tr:first-child td {\n            border-top: 1px solid #c7c6c6;\n        }\n    "
    }}
  />
  <table
    style={{
      width: "100%",
      maxWidth: 800,
      margin: "0 auto",
      backgroundColor: Theme.colors.white,
      borderSpacing:'0 10px',
      borderCollapse:'separate',
      padding:'10px'
    }}
  >
    {/* Header */}
    <tbody>
      <tr>
        <td
          style={{ padding: 10, textAlign: "left" }}
          className="top-logo full-width equal"
        >
          <img
            src={Theme.icons.icn_main_logo}
            alt="Logo"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </td>
        <td
          style={{ textAlign: "center", padding: "10px 20px" }}
          className="full-width equal"
        >
          <span className="label">Reports Date</span> <br />
          <span className="details">{`${startDate} - ${endDate}`} </span>
        </td>
        <td
          style={{
            padding: 10,
            textAlign: "right",
            fontSize: 28,
            fontWeight: 600,
            color: "black"
          }}
          className="full-width equal"
        >
          Reports
        </td>
      </tr>
    {data?.data?.map((item:any,index:number) => {
        return(
            <tr>
            <td
              colSpan={3}
              style={{
                padding: "10px 15px",
                textAlign: "left",
                backgroundColor: "#F3F3F3" ,
                borderRadius: 20,
              }}
            >
              <table style={{ width: "100%" }}>
                {/* First tbody for the first set of rows (4 columns) */}
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left", padding: "0px 5px" }}>
                      <span className="label">{type === 'order' ? t("Booking ID") : t("Customer ID")}</span> <br />
                      <span className="details">{type === 'order'? item?.bookingId : item?.customerId }</span>
                    </td>
                    <td
                      style={{ textAlign: "left", padding: "0px 5px" }}
                      colSpan={2}
                    >
                      <span className="label">{t("Customer Name")}</span> <br />
                      <span className="details">{item?.customerName}</span>
                    </td>
                    <td
                      style={{ textAlign: "left", padding: "0px 5px" }}
                      colSpan={2}
                    >
                      <span className="label">{type === 'order' ? t("Vendor Name") : t("Phone Number")}</span> <br />
                      <span className="details">{type === 'order' ? item?.vendorName : item?.phoneNumber}</span>
                    </td>
                    <td style={{ textAlign: "left", padding: "0px 5px" }}>
                      <span className="label">{type === 'order'  ? t("Service Name") : t("Email")}</span> <br />
                      <span className="details">{type === 'order' ? item?.serviceName : item?.email}</span>
                    </td>
                  </tr>
                </tbody>
                {/* Second tbody for the second set of rows (6 columns) */}
                <tbody className="bottom-tbody">
                  <tr>
                    <td style={{ textAlign: "left", padding: "0px 5px" }}>
                      <span className="label">{ type === 'order' ?  t("Total Amount") : t("Total Spent")}</span> <br />
                      <span className="details">{type === 'order' ? item?.totalAmount : item?.totalSpend}</span>
                    </td>
                    <td style={{ textAlign: "left", padding: "0px 5px" }}>
                      <span className="label">{ type === 'order' ? t("Transaction ID") : t("City")}</span> <br />
                      <span className="details">{ type === 'order' ? item?.transactionId : item?.city}</span>
                    </td>
                    <td style={{ textAlign: "left", padding: "0px 5px" }}>
                      <span className="label">{type === 'order' ? t("Booking Date") : t("Registration Date")} </span> <br />
                      <span className="details">{type === 'order' ? item?.bookingDate :  item?.registrationDate  }</span>
                    </td>
                    {type === 'order' && (
                    <>
                     <td style={{ textAlign: "left", padding: "0px 5px" }}>
                       <span className="label">{t("Booking Status")}</span> <br />
                       <span className="details">{item?.bookingStatus}</span>
                     </td>
                     <td style={{ textAlign: "left", padding: "0px 5px" }}>
                       <span className="label">{t("Payment Status")}</span> <br />
                       <span className="details">{item?.paymentStatus}</span>
                     </td>
                     </>
                 )}
                    <td style={{ textAlign: "left", padding: "0px 5px" }} colSpan={3}>
                       <span className="label">{type === 'order' ? t("Promo Code") : t("Address")} </span> <br />
                       <span className="details">{type === 'order'  ? item?.promoCode : item?.location}</span>
                     </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        )
    })}
  
      {/* Footer  */}
      <tr style={{ width: "100%" }}>
        <td colSpan={2} style={{ textAlign: "left", padding: "10px 20px" }}>
          <span style={{ fontSize: 12 }}>
            All Rights Reserved{" "}
            <span style={{ fontSize: 14, fontWeight: 600 }}>Jive</span>
          </span>
        </td>
        <td style={{ textAlign: "right", padding: "10px 20px" }}>
          <span style={{ fontSize: 12 }}>
            Page Number{" "}
            <span style={{ fontSize: 14, fontWeight: 600 }}>01</span>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
            </div>
        </>
    );
};

export default PdfPreview;

import instance from './api_instance'

export const get = async (url: any, credentials: object) => {
  const response = await instance.get(url, {
    params: credentials
  })
  return response.data
}

export const post = async (url: any, credentials: object) => {
  const response = await instance.post(url, credentials)
  return response.data
}

export const uploadDocument = async (url: any, credentials: object) => {
  const response = await instance.post(url, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export const postWithParams = async (url: any, credentials: object) => {
  const response = await instance.post(
    url,
    {},
    {
      params: credentials
    }
  )
  return response.data
}

export const reportConfig = {
  order: {
    fields: [
      { key: 'bookingId', label: 'Booking ID' },
        { key: 'customerName', label: 'Customer Name' },
      // { key: 'phoneNumber', label: 'Phone Number' },
      { key: 'serviceName', label: 'Service Name' },
      { key: 'transactionId', label: 'Transaction ID' },
      { key: 'bookingDate', label: 'Booking Date' },
      { key: 'promoCode', label: 'Promo Code' },
      { key: 'amountDiscounted', label: 'Discounted Amount' },
      { key: 'totalAmount', label: 'Total Amount' },
      { key: 'bookingStatus', label: 'Booking Status' },
      { key: 'paymentStatus', label: 'Payment Status' }
    ],
    nameField: 'vendorName'
  },
  customer: {
    fields: [
      { key: 'customerId', label: 'Customer ID' },
      { key: 'phoneNumber', label: 'Phone Number' },
      { key: 'email', label: 'Email' },
      { key: 'city', label: 'City' },
      { key: 'location', label: 'Location' },
      { key: 'registrationDate', label: 'Registration Date' },
      { key: 'totalSpend', label: 'Total Spent' }
    ],
    nameField: 'customerName'
  },
  financial: {
    fields: [
      { key: 'billId', label: 'Bill ID' },
      //   { key: 'serviceName', label: 'Service Name' },
      { key: 'serviceCategory', label: 'Service Category' },
      { key: 'paymentStatus', label: 'Payment Status' },
      { key: 'city', label: 'City' },
      { key: 'paymentMethod', label: 'Payment Method' },
      { key: 'paymentDate', label: 'Payment Date' },
      { key: 'amountReceived', label: 'Amount Received' },
      { key: 'promoCode', label: 'Promo Code' },
      // { key: 'percentageAmountJive', label: 'Percentage Amount [Jive]' },
      // {
      //   key: 'percentageAmountVendorFreelancer',
      //   label: 'Percentage Amount Vendor/Freelancer'
      // }
    ],
    nameField: 'serviceName'
  },
  booking: {
    fields: [
      { key: 'bookingId', label: 'Booking ID' },
      //   { key: 'customerName', label: 'Customer Name' },
      { key: 'vendorName', label: 'Vendor Name' },
      { key: 'city', label: 'City' },
      { key: 'category', label: 'Category' },
      { key: 'bookingDate', label: 'Date of Booking' },
      { key: 'paymentStatus', label: 'Payment Status' },
      { key: 'status', label: 'Status' }
    ],
    nameField: 'customerName'
  },
  vendor: {
    fields: [
      { key: 'vendorId', label: 'Vendor ID' },
      //   { key: 'vendorName', label: 'Vendor Name' },
      { key: 'phoneNumber', label: 'Phone Number' },
      { key: 'email', label: 'Email' },
      { key: 'location', label: 'Location' },
      { key: 'city', label: 'City' },
      { key: 'registrationDate', label: 'Registration Date' },
      { key: 'totalSpend', label: 'Total Spend' },
      { key: 'promoCode', label: 'Promo Codes' },
      { key: 'serviceCategory', label: 'Service Category' }
    ],
    nameField: 'vendorName'
  },
  freelancer: {
    fields: [
      { key: 'vendorId', label: 'Freelancer ID' },
      //   { key: 'freelancerName', label: 'Freelancer Name' },
      { key: 'phoneNumber', label: 'Phone Number' },
      { key: 'email', label: 'Email' },
      { key: 'location', label: 'Location' },
      { key: 'city', label: 'City' },
      { key: 'registrationDate', label: 'Registration Date' },
      { key: 'totalSpend', label: 'Total Spend' },
      { key: 'promoCode', label: 'Promo Codes' },
      { key: 'serviceCategory', label: 'Service Category' }
    ],
    nameField: 'vendorName'
  },
  event: {
    fields: [
      { key: 'eventId', label: 'Event ID' },
      //   { key: 'eventName', label: 'Event Name' },
      { key: 'organizerName', label: 'Organizer Name' },
      { key: 'eventLocation', label: 'Event Location' },
      { key: 'eventDateAndTime', label: 'Event Date and Time' },
      { key: 'duration', label: 'Duration' },
      { key: 'eventBudget', label: 'Event Budget' },
      { key: 'eventType', label: 'Event Type' },
      { key: 'vendorName', label: 'Event Vendors' },
      { key: 'eventStatus', label: 'Event Status' }
    ],
    nameField: 'eventName'
  }
} as const

export type ReportConfig = typeof reportConfig
export type ReportType = keyof ReportConfig

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "antd";
import { Theme } from "../../Theme";
import CButton from "../../components/common/CButton";
import SuccessModal from "../../components/common/SuccessModal";
import { useMutation, useQuery } from "react-query";
import { forgot, otpVerification, resendTwoFactorCode } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { useTranslation } from "react-i18next";
import OtpInput from 'react-otp-input';


interface ResponseType {
  message: string;
}
const Otp: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { emailOrPhoneNumber, otpCode, type } = location.state;
  const [otp, setOtp] = useState(otpCode);
  const [isSuccess, setIsSuccess] = useState(false);
  const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false,
  });

  const [timeLeft, setTimeLeft] = useState(120);
  const [timerExpired, setTimerExpired] = useState(false);

  useEffect(() => {
    // Exit early if timer expired
    if (timeLeft <= 0) {
      setTimerExpired(true);
      return;
    }

    // Set up interval to decrease timeLeft by 1 second
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clear interval when the component unmounts or when timeLeft reaches 0
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Function to format time as mm:ss
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const { mutate, isLoading } = useMutation(otpVerification, {
    onSuccess: (response) => {
      if (response.succeeded) {
        setIsSuccess(true);
      } else {
        setAlert({ message: response.message, type: 'error', visible: true });
      }
    },
    onError: (error: any) => {
      setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
    },
  });

  const { data: statusData, isLoading: statusLoading, error: statusError, refetch } = useQuery(
    ['resendTwoFactorCode', emailOrPhoneNumber, type],
    () => resendTwoFactorCode({ emailOrPhoneNumber, type }),
    {
      enabled: false,
      onSuccess: (data) => {
        setTimeLeft(120);
        setTimerExpired(false);
        setOtp(data.message.split('.')[3]);
      },
    }
  );

  const reSendOtp = () => {
    refetch();
  }
  const onFinish = (values: { email: string }) => {
    mutate(location?.state);
  };

  return (
    <>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
      <div className="main-div">
        <div className="strech-man-div">
          <p className="event-planner">{t("event_planner")}</p>
        </div>
        <div className="auth-form-div d-flex align-items-center justify-content-center" style={{ margin: "20px" }}>
          <div className="auth-form-inner" style={{ textAlign: "left", margin: "20px", width: '43%' }}>
            <img src={Theme.icons.icn_main_logo} alt="logo" className="auth-logo" />
            <p className='login-heading mt-3 mb-0'>{t("verification_code")}</p>
            <p className="login-des">{t('verification_desc') + type}</p>
            <SuccessModal
              visible={isSuccess}
              message={"OTP verified successfully"}
              onOk={() => navigate('/reset', { state: { emailOrPhoneNumber, otpCode, type } })}
            />
            <Form
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                containerStyle={{ justifyContent: 'space-between' }}
                inputStyle={{ borderRadius: '12px', height: '47px', width: '37px', border: '1px solid #A6A4A4', fontSize: '14px', fontFamily: 'sf-semibold' }}
                renderInput={(props) => <input {...props} />}
              />
              {!timerExpired ? (
                <p style={{ textAlign: 'center' }} className="mt-4 m-0 detail-label">Time remaining: {formatTime(timeLeft)}</p>
              ) : (
                <p style={{ textAlign: 'center' }} className="mt-4 m-0 detail-label cursor-pointer" onClick={() => reSendOtp()}>{t("Resend Code")}</p>
              )}

              <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t("Verify")}</CButton>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form } from "antd";
import CInput from "../../components/common/CInput";
import { Theme } from "../../Theme";
import CButton from "../../components/common/CButton";
import PasswordInput from "../../components/common/PasswordInput";
import { useMutation } from "react-query";
import { login } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const switchLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
};

const Login: React.FC = () => {
    const { t } = useTranslation();
    const [type, setType] = useState('');
    const navigate = useNavigate();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    console.log('type===>>>', type);
    const validateEmail = (value: string) => {
        setType('Email');
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    }
    const validatePhoneNumber = (value: string) => {
        setType('PhoneNumber');
        return /^\+?\d{1,3}\s?\d{1,4}\s?\d{1,4}\s?\d{1,4}$/.test(value);
    }

    const { mutate, isLoading, isError, error } = useMutation(login, {
        onSuccess: (response) => {
            if (response.data !== null && response.data.isAuthSuccessful && response.succeeded) {
                setAlert({ message: 'Login successful!', type: 'success', visible: true });
                localStorage.setItem('authToken', response.data.token);

                navigate('/jive/')
            } else {
                setAlert({ message: response.message || 'Login failed', type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.message : 'An error occurred during login. Please try again.', type: 'error', visible: true });
        },
    });

    useEffect(() => {
        try {

            const authUser = localStorage.getItem("authToken");
            if (authUser) {
                navigate(`/jive/manageInternalUser`);
            }
        } catch (error) {
            console.error("Error parsing local storage data", error);
        }
        document.body.classList.add("auth-body-bg");
        return () => {
            document.body.classList.remove("auth-body-bg");
        };
    }, [navigate]);

    const onFinish = (values: { email: string; password: string }) => {
        let obj = {
            ...values, type
        }
        mutate(obj);
    }


    return (
        <>
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
            <div className="main-div">
                <div className="strech-man-div">
                    <p className="event-planner">{t("event_planner")}</p>
                </div>
                <div
                    className="auth-form-div d-flex align-items-center justify-content-center"
                    style={{ margin: "20px" }}
                >
                    <div
                        className="auth-form-inner"
                        style={{
                            textAlign: "left",
                            margin: "20px",
                            width: '43%'
                        }}
                    >
                        <img src={Theme.icons.icn_main_logo} alt="logo" className="auth-logo" />
                        <p className='login-heading mt-3 mb-0'>{t('admin_login')}</p>
                        <p className="login-des">{t('welcome_desc')}</p>
                        <Form
                            name="login"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="emailPhoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: t('email_validation'),
                                        validator: (_, value) => {
                                            if (validateEmail(value) || validatePhoneNumber(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(t('email_error'));
                                            }
                                        },
                                    },
                                ]}
                            >
                                <CInput
                                    label={t('email_address')}
                                    placeholder={t('email_placeholder')}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: t('password_error'),
                                    },
                                    { min: 6, message: t('password_validation') },
                                ]}
                            >
                                <PasswordInput
                                    label={t('password')}
                                    placeholder={t('password_placeholder')}
                                />
                            </Form.Item>
                            <p
                                className="forgot-pas-desc"
                                onClick={() => navigate(`/forgot`)}
                            >
                                {t("forgot_?")}
                            </p>
                            <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t("Login")}</CButton>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;

import { Input, Form, DatePickerProps, DatePicker, TimePicker, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { get, getVendorsList, post } from "../../../services/booking";
import { Theme } from "../../../Theme";
import CInput from "../../../components/common/CInput";
import CustomSelect from "../../../components/common/CSelect";
import CButton from "../../../components/common/CButton";
import SuccessModal from "../../../components/common/SuccessModal";
import CustomAlert from "../../../components/common/CAlert";
import dayjs from 'dayjs';
import { getSubCategoryList } from "../../../services/lov";
import { RangePickerProps } from "antd/es/date-picker";
import CategoriesList from "../../Settings/EventPolicyMangement/Categories/CategoriesList";
import moment from "moment";


const AddBooking: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [isSuccess, setIsSuccess] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [vendorServiceList, setVendorServiceList] = useState<any[]>([]);
    const [servicePackageList, setServicePackageList] = useState<any[]>([]);

    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
    const [selectedVendor, setSelectedVendor] = useState<{ [key: string]: any }>({});
    const [selectedvendorService, setSelectedvendorService] = useState<{ [key: string]: any }>({})
    const [selectedServicePackage, setSelectedServicePackage] = useState<{ [key: string]: any }>({})
    const [storeTime, setStoreTime] = useState({start_time:'', end_time:''});
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const { type, id, requestId } = useParams()
    const { data, error, isLoading } = useQuery('subCategoryList', getSubCategoryList);

    console.log('type, id, requestId ====>>>', type, id, requestId);

    console.log('Please choose a service.=====>>>', selectedvendorService);
    const { data: VendorList, error: VendorListError, isLoading: VenodLoading } = useQuery('vendorList', getVendorsList);

    const {
        mutate: fetchCustomEventDetails,
        isLoading: isFetchingCustomEventDetails
    } = useMutation(
        (id: any) => get('AdminService/GetServiceByVendorId', { vendorId: id }),
        {
            onSuccess: response => {
                if (response.succeeded) {
                    setVendorServiceList(response.data);
                } else {
                    setAlert({ message: response.message, type: 'error', visible: true })
                }
            },
            onError: (error: any) => {
                setAlert({
                    message: error?.message || 'An error occurred. Please try again.',
                    type: 'error',
                    visible: true
                })
            }
        }
    )

    const {
        mutate: fetchServicePackages,
        isLoading: isFetchServicePackages
    } = useMutation(
        (id: any) => get('AdminPackage/GetAllPackage', { serviceId: id }),
        {
            onSuccess: response => {
                if (response.succeeded) {
                    setServicePackageList(response.data);
                } else {
                    setAlert({ message: response.message, type: 'error', visible: true })
                }
            },
            onError: (error: any) => {
                setAlert({
                    message: error?.message || 'An error occurred. Please try again.',
                    type: 'error',
                    visible: true
                })
            }
        }
    )

    const onChangeVendor = async (vendorId: any) => {
        form.setFieldsValue({
            serviceId: undefined
        })
        fetchCustomEventDetails(vendorId);
        let vn = VendorList.data.find((vendor: { phoneNumber: any, email: any, id: any }) => (vendor.id == vendorId))
        setSelectedVendor(vn);
        form.setFieldsValue({
            phoneNumber: vn.phoneNumber,
            email: vn.email,
        })
    };

    const onChangeVendorService = (serviceId: any) => {
        form.setFieldsValue({
            packageId: undefined
        })
        fetchServicePackages(serviceId)
        let sv = vendorServiceList.find(service => (service.id == serviceId))
        setSelectedvendorService(sv);
        console.log('sv===>>>', sv);
        form.setFieldsValue({
            description: sv.description,
            categoryName : sv?.categoryName
        })
    }

    const onChangeServicePackage = (packageId :any)=>{
        let pk = servicePackageList.find(pkg => (pkg.id == packageId))        
        setSelectedServicePackage(pk);
    }

    const getData = (val: any, type?:any) => {
        if (!val) return []; // Return an empty array if data is undefined
        // if(type == 'package')
        return val.data.map((x: any) => ({
            value: x.id,
            label: x.name ? x.name : x.contactPersonName ? x.contactPersonName : type == 'package' ? x.packageName : x.serviceName
        }));
    };
    
    const handleSave = async (data: any) => {
        const { serviceRequestDate, ...newObj } = data;
        setSubmitLoading(true)
        const obj = {
            ...newObj,
            id: 0,
            customEventId: requestId,
            eventTypeId: record?.eventTypeId,
            noOfAttendees: record?.noOfAttendees,
            servicePrice: selectedServicePackage?.packagePrice ? selectedServicePackage.packagePrice : selectedvendorService?.price,
            serviceRequestDate: serviceRequestDate.format('YYYY-MM-DD')
        };
        setSubmitLoading(false)


        let response = await post('AdminEventOrder/AddUpdate', obj)
        if (response.succeeded) {
            setIsSuccess(true);
        } else {
            setAlert({ message: response.message, type: 'error', visible: true });
        }
    }
    const { record } = location.state;
    let serviceOrder = record.serviceOrders.find((service :any) => (service.id == id))

    console.log("record---------------------", record);

useEffect(() => {
    if (record) {
        form.setFieldsValue({
            customEventName: record?.customEventName,
            customEventId: record?.id,
            eventType: record?.eventType,
            eventTypeId: record?.eventTypeId,
            userAddress: record?.userAddress,
            noteAndSpecification: record?.noteAndSpecification,
            serviceRequestDate: dayjs(record?.eventDate),
            // orderStartTime: record?.eventStartTime,
            // orderEndTime: record?.eventEndTime,
        });
        if(id != 'new'){
            // let serviceOrder = record.serviceOrders.find((service :any) => (service.id == id))
            console.log("serviceOrder---------",serviceOrder);
            
        form.setFieldsValue({
            vendorId : serviceOrder.vendorName,
            serviceId : serviceOrder.serviceName,
            description: serviceOrder?.description,
            phoneNumber : serviceOrder?.vendorPhoneNumber,
            categoryName : serviceOrder?.categoryName,
            orderStartTime:serviceOrder?.serviceRequestStartTime,
            orderEndTime:serviceOrder?.serviceRequestEndTime,
            email:serviceOrder?.vendorEmail,
            packageId:serviceOrder?.packageName

        })
        }
    }
},[])
    console.log("form---------------------", form);


    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        // if (date) {
        //     const formattedDate = dayjs(date).format('YYYY-MM-DD[T]HH:mm:ss');
        //     form.setFieldsValue({ serviceRequestDate: formattedDate })
        // }
    }

    const onTimeChange: (time: any, timeString: string | string[], type: 'start_time' | 'end_time') => void = (time, timeString, type) => {
        console.log(time, timeString);
        const selectedTime = Array.isArray(timeString) ? timeString.join(', ') : timeString;
        if(type === 'start_time'){
            setStoreTime({...storeTime, start_time: selectedTime});
        }else{
            setStoreTime({...storeTime, end_time: selectedTime});
        }
        form.setFieldsValue({
            [type === 'start_time' ? 'orderStartTime' : 'orderEndTime']: selectedTime,
        });

    };
    function getDisabledHours() {
        const parsedTime = moment(storeTime?.start_time, 'h:mm A');
        var hours = [];
        for (let i = 0; i < parsedTime.hour()+1; i++) {
          hours.push(i);
        }
        return hours;
      }
    return (
        <>
            <div style={{ padding: '20px', color: Theme.colors.black }}>
                <div className='d-flex align-items-center mb-4'>
                    <img src={Theme.icons.icn_arrow_back}
                        className='cursor-pointer'
                        alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className='list-heading m-0 mx-2'>{'Custom Event Booking'}</p>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                    style={{ backgroundColor: Theme.colors.white, padding: '24px', borderRadius: '16px' }}
                >
                    <p className='user-name'>{'Booking Details'}</p>
                    {/* {isLoading || isCountryLoading ? (
                        <div className='d-flex align-items-center justify-content-center' style={{ height: '200px' }} >
                            <Spin />
                        </div>
                    ) : ( */}
                    <>
                        <div className='row' style={{ width: '90%' }}>
                            <div className='col-lg-4'>
                                <Form.Item
                                    name="customEventName"
                                    rules={[{ required: true, message: t("Please enter the event name") }]}
                                >
                                    <CInput disabled={true} label={t("Event Name")} placeholder={t("Enter Event Name")} />
                                </Form.Item>
                            </div>

                            <div className='col-lg-4'>
                                <Form.Item
                                    name="eventType"
                                >
                                    <CInput disabled={true} label={t("Event Type")} placeholder={t("Enter Event Type")} />
                                </Form.Item>
                            </div>


                            <div className='col-lg-4'>
                                <Form.Item
                                    name="userAddress"
                                >
                                    <CInput disabled={true} label={t("Location")} placeholder={t("Enter Location")} />
                                </Form.Item>
                            </div>

                            <div className='col-lg-4'>
                                <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Event Date")}</label>
                                <Form.Item
                                    name="serviceRequestDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Please enter a event date"),
                                        },
                                    ]}
                                >
                                    <DatePicker disabled={id != 'new' ? true : false} 
  disabledDate={(current) => current && current.isBefore(dayjs().startOf('day'))} className='inputfield w-100' onChange={onChange} />
                                </Form.Item>

                            </div>
                            <div className='col-lg-4'>
                                <Form.Item
                                    name="orderStartTime"
                                    rules={[{ required: true, message: t("Please enter the start time") }]}
                                >

                                    <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Booking Start Time")}</label>
                                    <TimePicker className='inputfield w-100' disabled={id != 'new' ? true : false}
                                    defaultValue={serviceOrder?.serviceRequestStartTime ? dayjs(serviceOrder?.serviceRequestStartTime, 'h:mm A'): null}
                                        use12Hours format="h:mm A" onChange={(time, timeString) => onTimeChange(time, timeString, 'start_time')} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Item
                                    name="orderEndTime"
                                    rules={[{ required: true, message: t("Please enter the end time") }]}
                                >

                                    <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Booking End Time")}</label>
                                    <TimePicker className='inputfield w-100' disabled={id != 'new' ? true : false}
                                    defaultValue={serviceOrder?.serviceRequestEndTime ? dayjs(serviceOrder?.serviceRequestEndTime, 'h:mm A'): null}
                                    disabledTime={() => ({
                                        disabledHours: getDisabledHours,
                                      })} 
                                        use12Hours format="h:mm A"
                                        onChange={(time, timeString) => onTimeChange(time, timeString, 'end_time')} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Item
                                    name="vendorId"
                                    rules={[{ required: true, message: t("Please choose a vendor") }]}
                                >
                                    <CustomSelect
                                        label={t("Vendor Name")}
                                        options={getData(VendorList)}
                                        value={selectedVendor.id}
                                        onChange={(value) => onChangeVendor(value)}
                                        placeholder={t("Choose Vendor")}
                                        disabled={id != 'new' ? true : false}
                                    />
                                </Form.Item>
                            </div>

                            <div className='col-lg-4'>
                                <Form.Item
                                    name="phoneNumber"
                                >
                                    <CInput disabled={true} label={t("Phone Number")} placeholder={t("Enter Phone Number")} />
                                </Form.Item>
                            </div>

                            <div className='col-lg-4'>
                                <Form.Item
                                    name="email"
                                >
                                    <CInput disabled={true} label={t("Vendor Email")} placeholder={t("Enter Email")} />
                                </Form.Item>
                            </div>

                            <div className='col-lg-4'>
                                <Form.Item
                                    name="serviceId"
                                    rules={[{ required: true, message: t("Please choose a service") }]}
                                >
                                    <CustomSelect
                                        label={t("Service")}
                                        options={vendorServiceList.length > 0 ? getData({ data: vendorServiceList }) : []}
                                        value={selectedvendorService.id}
                                        onChange={(value) => onChangeVendorService(value)}
                                        placeholder={t("Choose Service")}
                                        disabled={id != 'new' ? true : false}
                                    />
                                </Form.Item>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Item
                                    name="packageId"
                                >
                                    <CustomSelect
                                        label={t("Package")}
                                        options={servicePackageList.length > 0 ? getData({ data: servicePackageList },'package') : []}
                                        value={selectedServicePackage.id}
                                        onChange={(value) => onChangeServicePackage(value)}
                                        placeholder={t("Choose Package")}
                                        disabled={id != 'new' ? true : false}
                                    />
                                </Form.Item>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Item
                                    name="categoryName"
                                    rules={[{ required: true, message: t("Please choose a category") }]}
                                >
                                    {/* <CustomSelect
                                        label={t("Category")}
                                        options={getData(data)}
                                        value={selectedCategory}
                                        onChange={(value) => setSelectedCategory(value)}
                                        placeholder={t("Choose Category")}
                                        disabled={id != 'new' ? true : false}
                                        
                                    /> */}
                                    
                                    <CInput disabled={true} label={t("Category Name")} placeholder={t("Enter Category Name")} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Description")}</label>
                                <Form.Item
                                    name="description"
                                    rules={[{ required: true, message: t("Please enter the description") }]}
                                >
                                    <TextArea
                                        disabled={true}
                                        showCount
                                        autoSize={({ minRows: 4, maxRows: 8 })}
                                        maxLength={600}
                                        placeholder="Write some description"
                                        style={{ height: 200 }}
                                    />
                                </Form.Item>
                            </div>
                            {selectedvendorService?.serviceImages?.length > 0 && (
                                <div className='col-lg-12'>
                                    <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Service Images")}</label>
                                    <div className="row">
                                        {selectedvendorService.serviceImages.map((file: any, index: number) => (
                                            <div className="col-lg-3" key={index + 1} >
                                                <div className="d-flex align-items-center p-3 preview-image-view-container mt-3" style={{ border: '1px solid #c7c6c6' }}>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <img
                                                            src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                                                            alt={'Service image' + index}
                                                            className="category-icon mb-0"
                                                            style={{ width: '50px', height: '50px' }}
                                                        />
                                                        {'Service image ' + (index + 1)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                        </div>

                        { id == 'new' && <Row gutter={8} style={{ marginTop: '15px' }}>
                            <Col span={4}>
                                <CButton className='cancelBtn' onClick={() => navigate(-1)}>
                                    Cancel
                                </CButton>
                            </Col>
                            <Col span={4}>
                                <CButton loading={submitLoading} style={{ width: '100%' }} htmlType="submit">
                                    Save
                                </CButton>
                            </Col>
                        </Row>
}
                    </>
                    {/* )} */}


                </Form>
                <SuccessModal
                    visible={isSuccess}
                    message={'Custom order created successfully.'}
                    onOk={() => navigate(`/jive/customEvent/view/${requestId}`)}
                />
                <CustomAlert
                    message={alert.message}
                    type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                    visible={alert.visible}
                    onClose={() => setAlert({ ...alert, visible: false })}
                    duration={3000}
                />
            </div>
        </>
    )
}

export default AddBooking
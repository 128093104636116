import React, { useEffect, useState } from 'react'
import { Layout, Tag } from 'antd'
import { Theme } from '../../Theme'
import Header from '../../components/common/Header'
import MenuWrapper from '../../components/ui/MenuWrapper'
import { Outlet, useNavigate } from 'react-router-dom'

import permissionService from '../../services/permissions'

const { Sider, Content } = Layout

const getImage = (
  imgPath: string,
  selectedImgPath?: string,
  isSelected?: boolean
) => (
  <img
    src={isSelected && selectedImgPath ? selectedImgPath : imgPath}
    style={{ height: '20px' }}
    className='cursor-pointer'
  />
)

const PortalScreen: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('internalUser')
  const [highlightStyle, setHighlightStyle] = useState({ top: 0, height: 0 })
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const navigate = useNavigate()
  const [filteredMenus, setFilteredMenus] = useState<any[]>([])
  let currentRoute=''
  const items2 = [
    {
      key: 'dashboard',
      icon: getImage(
        Theme.icons.icn_dashboard,
        Theme.icons.icn_dashboard_white,
        selectedKey == 'dashboard'
      ),
      label: 'Dashboard',
      path: '/dashboard',
      visibility: true
    },
    {
      key: 'manageUsers',
      icon: getImage(Theme.icons.icn_manage_user),
      label: 'Manage Users',
      visibility: true,
      children: [
        {
          key: 'manageInternalUser',
          label: 'Manage Internal User',
          path: '/manageInternalUser',
          visibility: true
        },
        {
          key: 'manageCustomer',
          label: 'Manage Customer',
          path: '/manageCustomer',
          visibility: true
        },
        {
          key: 'manageVendor',
          label: 'Manage Vendor',
          path: '/manageVendor',
          visibility: true
        },
        {
          key: 'manageFreelancer',
          label: 'Manage Freelancer',
          path: '/manageFreelancer',
          visibility: true
        }
      ]
    },
    {
      key: 'manageBooking',
      icon: getImage(Theme.icons.icn_manage_booking),
      label: 'Manage Booking',
      visibility: true,
      children: [
        {
          key: 'serviceBooking',
          label: 'Service Booking',
          path: '/serviceBooking',
          visibility: true
        },
        {
          key: 'customEvent',
          label: 'Custom Event Booking',
          path: '/customEvent',
          visibility: true
        },
        {
          key: 'adminBooking',
          label: 'Admin Bookings',
          path: '/adminBooking',
          visibility: true
        }
      ]
    },
    {
      key: 'servicesAndPackagesList',
      icon: getImage(
        Theme.icons.icn_services_packages,
        Theme.icons.icn_white_services_packages,
        selectedKey === 'servicesPackages'
      ),
      label: 'Services & Packages',
      path: '/servicesAndPackagesList',
      visibility: true
    },
    {
      key: 'paymentAndPromotion',
      icon: getImage(Theme.icons.icn_payment_logs),
      label: 'Payment & Promotion',
      visibility: true,
      children: [
        {
          key: 'paymentLogs',
          label: 'Payment Logs',
          path: '/paymentLogs',
          visibility: true
        },
        {
          key: 'promoCodesList',
          label: 'Promo Code',
          path: '/promoCodesList',
          visibility: true
        }
      ]
    },
    {
      key: 'onboarding',
      icon: getImage(Theme.icons.icn_onboarding),
      label: 'Onboarding',
      visibility: true,
      children: [
        {
          key: 'vendorList',
          label: 'Vendor Onboarding',
          path: '/vendorList',
          visibility: true
        },
        {
          key: 'freelancerList',
          label: 'Freelancer Onboarding',
          path: '/freelancerList',
          visibility: true
        }
      ]
    },
    {
      key: 'orderTracking',
      icon: getImage(
        Theme.icons.icn_order_track,
        Theme.icons.icn_order_track_white,
        selectedKey === 'order_track'
      ),
      label: 'Order Tracking',
      path: '/orderTracking',
      visibility: true
    },
    {
      key: 'reports',
      icon: getImage(
        Theme.icons.icn_reports,
        Theme.icons.icn_white_reports,
        selectedKey === 'reports'
      ),
      label: 'Reports',
      path: '/reports',
      visibility: true
    },
    {
      key: 'chat',
      icon: getImage(
        Theme.icons.icon_chat,
        Theme.icons.icon_chat,
        selectedKey === 'chat'
      ),
      label: 'Chats',
      path: '/chat',
      visibility: true
    },
    {
      key: 'feedback',
      icon: getImage(
        Theme.icons.icn_user_star,
        Theme.icons.icn_user_star_white,
        selectedKey === 'feedback'
      ),
      label: 'User Feedback',
      path: '/feedback',
      visibility: true
    },
    {
      key: 'auditLogs',
      icon: getImage(
        Theme.icons.icn_manage_booking,
        Theme.icons.icn_manage_booking,
        selectedKey === 'auditLogs'
      ),
      label: 'Audit Logs',
      path: '/auditLogs',
      visibility: true
    },
    {
      key: 'settings',
      icon: getImage(Theme.icons.icn_settings),
      label: 'Settings',
      visibility: true,
      children: [
        {
          key: 'profile',
          label: 'Manage Profile',
          path: '/profile',
          visibility: true
        },
        {
          key: 'permissions',
          label: 'Roles & Privileges',
          path: '/permissions',
          visibility: true
        },
        {
          key: 'EventPolicyManagementList',
          label: 'Manage List of values',
          path: '/EventPolicyManagementList',
          visibility: true
        }
      ]
    }
  ]
  useEffect(()=>{
    currentRoute=window.location.pathname.split('/')[2]
    setSelectedKey(currentRoute)
    console.log(currentRoute)

  },[window.location.pathname])
  
  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key)
    localStorage.removeItem('active')
    console.log('After Click::',items2);
    
    const menuItem = items2
      .flatMap(item => (item.children ? item.children : [item]))
      .find(item => item.key === e.key)

    if (menuItem?.path) {
      navigate(`/jive${menuItem.path}`)
    }

    const isSubmenuItem = items2.some(
      item => item.children && item.children.some(child => child.key === e.key)
    )
    if (!isSubmenuItem) {
      setOpenKeys([])
    }

    const submenu = items2.find((item: any) => item.key === 'manageUsers')
    if (submenu && 'children' in submenu && Array.isArray(submenu.children)) {
      const index = submenu.children.findIndex(
        (item: any) => item.key === e.key
      )
      if (index !== -1) {
        const menuItemHeight = 45
        const top = index * menuItemHeight + 8
        setHighlightStyle({ top, height: menuItemHeight })
      }
    }
  }

  const onLogOut = () => {
    localStorage.removeItem('authToken')
    navigate('/')
  }

  const updateMenuVisibility = () => {
    items2.forEach(item => {
      if (item.children) {
        item.children.forEach(child => {
          child.visibility =
            permissionService.getRoleMenuPermissions(child.label)?.read ?? false
        })
      }
      item.visibility =
        permissionService.getRoleMenuPermissions(
          item.label == 'Dashboard' ? 'Dashboard' : item.label
        )?.read ?? false
    })

    //* ******************************************* *//

    const filteredMenus: any[] = []

    for (let i = 0; i < items2.length; i++) {
      const item = items2[i]

      // For Parent Item

      if (item.visibility && !item.children) {
        filteredMenus.push(item)
      }

      //   for Children Menus

      if (item && item.children){
        const filteredChildren = item.children
          ? item.children.filter(child => child.visibility)
          : []

        if (filteredChildren.length > 0) {
          filteredMenus.push({
            ...item,
            children: filteredChildren
          })
        }
      }
    }

    setFilteredMenus(filteredMenus)

    console.log('Final updated Menus ', filteredMenus)
  }

  useEffect(() => {
    const loadProfile = async () => {
      try {
        let details = await permissionService.getProfile()
        console.log('details--', details)
        console.log('Items2--', items2)
        updateMenuVisibility()
      } catch (error) {
        console.error('Error loading profile:', error)
      }
    }

    loadProfile()
  }, [])

  return (
    <Layout style={{ height: '100vh', overflowY: 'hidden' }}>
      <Sider
        width={260}
        style={{
          background: Theme.colors.white,
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: '#cdcdcd #f1f1f1'
        }}
      >
        <div
          className='mt-4'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img alt='logo' src={Theme.icons.icn_main_logo} />
        </div>
        <div
          className='d-flex flex-column justify-content-between'
          style={{ height: '90%' }}
        >
          <MenuWrapper
          // items={items2}
            items={filteredMenus}
            selectedKey={selectedKey}
            highlightStyle={highlightStyle}
            onClick={handleMenuClick}
            openKeys={openKeys}
            setOpenKeys={setOpenKeys}
          />
          <div className='d-flex align-items-center justify-content-center w-100 mb-3 cursor-pointer'>
            <Tag
              className='status-listing w-75'
              style={{ borderColor: '#F55666' }}
              onClick={onLogOut}
            >
              <img src={Theme.icons.icn_logout} style={{ height: '20px' }} />
              <p className='m-0 logout mx-2'>Logout</p>
            </Tag>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header />
        <Content
          style={{
            padding: '24px',
            margin: 0,
            backgroundColor: Theme.colors.contentColor,
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '#cdcdcd #f1f1f1'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default PortalScreen
